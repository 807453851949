import { Drawer, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import RenderRouteItem from "../../components/RenderRouteItem";
import { AuthContext } from "../../context/AuthContext";
import { ERole } from "../../generated/graphql";
import { routes } from "../../routes";
import { onNavMenuRoutes } from "../../utils/filterRoutes";

const useStyles = makeStyles(({ palette: { primary, common } }: Theme) =>
  createStyles({
    drawerPaper: {
      width: 250,
      zIndex: 1000,
      paddingTop: 60,
    },
    menuContainer: {
      padding: "0 19px",
    },
  })
);

export default () => {
  const classes = useStyles({});
  const { userProfile } = useContext(AuthContext);
  const myRoutes = onNavMenuRoutes(routes, userProfile?.role ?? ERole.Public);
  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="permanent"
      open
    >
      <div className={classes.menuContainer}>
        <List>
          {myRoutes.map((route) => (
            <React.Fragment key={route.path}>
              {<RenderRouteItem route={route} />}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Drawer>
  );
};
