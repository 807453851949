import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import { NavLink } from "react-router-dom";
import { IRoute } from "../routes";

const styles = ({ palette: { primary, common } }: Theme) =>
  createStyles({
    iconRoot: {
      minWidth: 45,
    },
    listItemRoot: ({ isCurrentPath }: any) => ({
      backgroundColor: isCurrentPath ? primary.light : undefined,
      "&:hover": {
        backgroundColor: primary.main,
      },
      "&:focus": {
        backgroundColor: primary.main,
        color: common.white,
      },
    }),
  });
interface Props extends WithStyles<typeof styles> {
  route: IRoute;
}

const RenderRouteItem = (props: Props) => {
  const {
    classes,
    route: { path, title, icon },
  } = props;
  return (
    <ListItem
      key={path}
      component={
        React.forwardRef((props, ref) => (
          <NavLink to={path} innerRef={ref as any} {...props} />
        )) as any
      }
      button
      classes={{
        root: classes.listItemRoot,
      }}
    >
      {icon && (
        <ListItemIcon
          classes={{
            root: classes.iconRoot,
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default withStyles(styles)(RenderRouteItem);
