import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  addPaymentMethod,
  createPaymentIntent,
  getPaymentMethods,
} from "../api/payment.service";
import {
  GetPaymentMethodsQueryResult,
  GetPaymentMethodsResp,
} from "../generated/graphql";
import PaymentMethodList from "./PaymentMethodList";
import { Button, Icon, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { amountToDollar, applyPaymentAmount } from "../utils/paymentAmount";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

const PaymentForm = ({
  amount,
  totalAmount,
  onResult,
  submitForm,
  classes,
}: {
  amount: number;
  totalAmount: number;
  submitForm: () => Promise<any>;
  onResult: (status: {
    succeed: boolean;
    paymentId?: string;
    paymentMethod?: string;
  }) => void;
} & Props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<GetPaymentMethodsResp>();

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showSuccessNotification } = useContext(NotificationContext);
  const [methods, setMethods] = useState<GetPaymentMethodsResp[]>([]);

  const retrievePaymentMethods = async () => {
    const paymentMethods = await getPaymentMethods();
    setMethods(paymentMethods);
  };

  useEffect(() => {
    retrievePaymentMethods();
  }, []);

  const submitPayment = async () => {
    try {
      showLoading();
      const resp = await submitForm();
      const paymentResp = await createPaymentIntent({
        amount: totalAmount, // in cents
        paymentMethodId: selectedPaymentMethod?.id,
        paymentCode: resp.payment?.reference,
      });
      showSuccessNotification("Payment succeed");
      hideLoading();
      onResult({
        succeed: true,
        paymentMethod: paymentResp?.paymentMethod,
        paymentId: paymentResp?.paymentId,
      });
    } catch (err) {
      hideLoading();
      onResult({ succeed: false });
    }
  };
  return (
    <div className={classes.container}>
      <Typography variant="h3">
        Payment includes base fee ${amount}, plus transaction fee (2% and
        A$0.30), total ${amountToDollar(totalAmount)}.
      </Typography>
      <PaymentMethodList
        reload={() => {
          retrievePaymentMethods();
        }}
        methods={methods}
        onChange={(method: GetPaymentMethodsResp) => {
          setSelectedPaymentMethod(method);
        }}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={methods.length === 0 || !selectedPaymentMethod}
        onClick={submitPayment}
        fullWidth
      >
        Confirm to pay
      </Button>
      {/* {paymentIntentSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: paymentIntentSecret }}
        >
          <CheckoutForm onProgress={onProgress} />
        </Elements>
      )} */}
    </div>
  );
};
export interface Props extends WithStyles<typeof styles> {}

export default withStyles(styles)(PaymentForm);
