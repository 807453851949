import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import React from "react";

const EmailTextInput = (props: TextFieldProps) => (
  <TextField
    id={props.id ?? ""}
    label={props.label ?? "Email"}
    name={props.name ?? "email"}
    type="email"
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    helperText={props.helperText}
    error={props.error}
    margin={props.margin ?? "dense"}
    variant={props.variant ?? "outlined"}
    fullWidth
    disabled={props.disabled}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton aria-label="Toggle password visibility">
            <EmailIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
export default EmailTextInput;
