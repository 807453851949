import validator from "validator";
import * as yup from "yup";

export const isMobilePhone = validator.isMobilePhone;

export const firstNameValidator = yup.string().required();

export const lastNameValidator = yup.string().required();

export const emailValidator = yup
  .string()
  .required("Email required")
  .test({
    name: "email",
    message: "Email is invalid",
    test: (value: string = ""): boolean => validator.isEmail(value),
  });

export const mobilePhoneValidator = yup.string().test({
  name: "phone",
  message: "Invalid mobile phone number",
  test: (value: string | undefined): boolean =>
    isMobilePhone(value ?? "", ["en-AU"]),
});
