import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { DataGrid, RowSelectedParams } from "@material-ui/data-grid";
import React, { useState } from "react";
import {
  Course,
  EEnrolmentType,
  EGender,
  EnrolmentResult,
  HolidayCourse,
} from "../generated/graphql";
import formatDate from "../utils/formatDate";
import getPlayerName from "../utils/getPlayerName";
import DialogTitle from "./DialogTitle";
import { IEnrolmentData } from "./EnrolmentDetail";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "40vh",
      overflowY: "auto",
      width: "95%",
    },
  });

export interface IEnrolmentRow {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: EGender;
  school: string;
  disability: boolean;
  parentId: string;
}

interface Props extends WithStyles<typeof styles> {
  enrolmentData: IEnrolmentData;
}

const getRowsColumns = (enrolmentData: IEnrolmentData) => {
  const { enrolments, players, courses, selectedDays, holidayCourses } =
    enrolmentData;
  const rows = enrolments.map((enrolment: EnrolmentResult) => {
    const { id, playerIds, courseIds, enrolDate } = enrolment.enrolment;
    const { status, reference } = enrolment.payment;
    const playersName = players
      .filter((p) => playerIds.indexOf(p.id) >= 0)
      .map(getPlayerName)
      .join(",");

    const courseDays = enrolment.enrolment.courseIds.map((id) => {
      // find the course id from courses;
      for (const c of courses) {
        const found = c.courses.find((c) => c.id === id);
        if (found) {
          return found.courseDay;
        }
      }
      return "";
    });
    const holidayDays = enrolment.enrolment.courseIds
      .filter((id) => holidayCourses?.find((hc) => hc.id === id))
      .map((hc) => "Holiday Camp");
    return {
      id,
      playersName,
      enrolDate: formatDate(enrolDate, "yyyy/MM/dd"),
      courses: [...courseDays, ...holidayDays].filter(
        (s) => s.trim().length > 0
      ),
      status,
    };
  });
  const columns = [
    { field: "playersName", headerName: "Players", width: 300 },
    { field: "enrolDate", headerName: "Enrol date", width: 140 },
    { field: "status", headerName: "Payment", width: 120 },
    { field: "courses", headerName: "Courses", width: 120 },
  ];
  return {
    rows,
    columns,
  };
};

const EnrolmentList = ({ classes, enrolmentData }: Props) => {
  const { enrolments } = enrolmentData;
  const { rows, columns } = getRowsColumns(enrolmentData);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedEnrolment, setSelectedEnrolment] = useState<
    EnrolmentResult | undefined
  >(undefined);
  const handleRowSelected = (rowData: RowSelectedParams) => {
    const foundElement = enrolments.find(
      (x) => x.enrolment.id === rowData.data.id
    );
    if (foundElement) {
      setSelectedEnrolment(foundElement);
      setShowDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const getSelectedPlayersName = () => {
    return enrolmentData.players
      .filter(
        (p) =>
          selectedEnrolment &&
          selectedEnrolment?.enrolment?.playerIds?.indexOf(p.id) >= 0
      )
      .map(getPlayerName)
      .join(",");
  };

  return (
    <Paper className={classes.container}>
      {enrolments.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={80}
          autoHeight
          onRowSelected={handleRowSelected}
        />
      )}
      <Dialog
        open={showDialog}
        fullWidth
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle onClose={handleCloseDialog}>
          <Typography variant="h5">Enrolment detail</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {" "}
                Players: {getSelectedPlayersName()}
              </Typography>
            </Grid>
            <Grid container item xs={12} direction="column">
              {selectedEnrolment?.enrolment.courseIds.map((id) => {
                const isHolidayType =
                  selectedEnrolment.enrolment.type == EEnrolmentType.Holiday;
                let foundEnrolmentData;
                let detailCourse;
                if (isHolidayType) {
                  detailCourse = enrolmentData?.holidayCourses?.find(
                    (c) => c.id === id
                  );
                } else {
                  foundEnrolmentData = enrolmentData.courses.find(
                    (c) => c.courses.findIndex((cc) => cc.id === id) >= 0
                  );
                  detailCourse = foundEnrolmentData?.courses.find(
                    (c) => c.id === id
                  );
                }
                return (
                  <Grid item container direction="row" spacing={2}>
                    {!isHolidayType && (
                      <Grid item>
                        <Typography variant="h5">
                          {foundEnrolmentData?.term.startDate} to{" "}
                          {foundEnrolmentData?.term.endDate}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      {isHolidayType && (
                        <Typography variant="h5">
                          Holiday course: From{" "}
                          {(detailCourse as HolidayCourse)?.startDate} to{" "}
                          {(detailCourse as HolidayCourse)?.endDate}
                        </Typography>
                      )}

                      {!isHolidayType && (
                        <Typography variant="h5">
                          {(detailCourse as Course)?.courseDay}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        From {detailCourse?.startTime} to{" "}
                        {detailCourse?.endTime}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">
                        {foundEnrolmentData?.term.level}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {" "}
                Fee: ${selectedEnrolment?.payment.amount}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Status: {selectedEnrolment?.payment.status}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Phone: {selectedEnrolment?.user?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Email: {selectedEnrolment?.user?.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleCloseDialog}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(EnrolmentList);
