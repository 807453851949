import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Theme,
  Typography
} from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

interface IDialogTitleProps extends WithStyles<typeof styles> {
  onClose: () => void;
  children: JSX.Element;
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dialog: {},
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.primary.main,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...rest } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...rest}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default DialogTitle;
