import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { getPlayers } from "../api/player.service";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import EmptyInfo from "../EmptyInfo";
import {
  EPaymentStatus,
  ERole,
  GetTournamentEnrolmentsInput,
  GetTournamentEnrolmentsResp,
  Player,
  Tournament,
} from "../generated/graphql";
import getPlayerName from "../utils/getPlayerName";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import HolidayEnrolmentList from "./HolidayEnrolmentList";
import {
  addTournamentEnrolment,
  getTournamentEnrolments,
  getTournaments,
} from "../api/tournament.service";
import TournamentList from "./TournamentList";
import TournamentEnrolmentList from "./TournamentEnrolmentList";
import PaymentForm from "./PaymentForm";
import { applyPaymentAmount } from "../utils/paymentAmount";
const styles = (theme: Theme) =>
  createStyles({
    container: {},
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

const steps = [
  "Please select player",
  "Please select a tournament",
  "Enrol now",
];

function TournamentEnrolment(props: Props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { userProfile } = useContext(AuthContext);
  const [openEnrolmentDlg, setOpenEnrolmentDlg] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);
  const [loadedData, setLoadedData] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [selectedEnrolment, setSelectedEnrolment] =
    useState<GetTournamentEnrolmentsResp | null>(null);
  const handleClose = () => {
    setEnrolmentData((draft) => {
      draft.selectedPlayers = [];
      draft.selectedTournaments = [];
    });
    setOpenEnrolmentDlg(false);
  };

  const handleChange = (event: any) => {
    setConfirmed(!confirmed);
  };

  const [enrolmentData, setEnrolmentData] = useImmer<{
    tournaments: Tournament[];
    players: any[];
    enrolments: any[];
    selectedPlayers: any[];
    selectedTournaments: Tournament[];
    amount: number;
  }>({
    tournaments: [],
    players: [],
    enrolments: [],
    selectedPlayers: [],
    selectedTournaments: [],
    amount: 0,
  });

  const [selectedPlayerList, setSelectedPlayerList] = useImmer<
    {
      id: string;
      selected: boolean;
    }[]
  >([]);

  const retrieveData = async () => {
    showLoading();
    setLoadedData(false);
    const param: GetTournamentEnrolmentsInput =
      userProfile?.role === ERole.Admin ? {} : { userId: userProfile?.id };
    const parentId = userProfile?.role === ERole.Admin ? "" : userProfile?.id;
    const [tournaments, players, enrolments] = await Promise.all([
      getTournaments(),
      getPlayers({ parentId }),
      getTournamentEnrolments(param),
    ]);

    setEnrolmentData((draft) => {
      if (players) {
        draft.players = players;
      }
      if (enrolments) {
        draft.enrolments = enrolments;
      }
      if (tournaments) {
        draft.tournaments = tournaments;
      }

      hideLoading();
      setLoadedData(false);
    });
  };

  useEffect(() => {
    retrieveData();
  }, []);

  const handleSelectedTournament = (
    tournament: Tournament,
    selected: boolean
  ) => {
    setEnrolmentData((draft) => {
      if (selected) {
        draft.selectedTournaments.push(tournament);
      } else {
        const foundTournamentIndex = draft.selectedTournaments.findIndex(
          (t) => t.id === tournament.id
        );
        if (foundTournamentIndex >= 0) {
          draft.selectedTournaments.splice(foundTournamentIndex, 1);
        }
      }

      const totalPrice = draft.selectedTournaments.reduce(
        (a: number, c: Tournament): number => {
          return a + c?.price ?? 0;
        },
        0
      );
      draft.amount = enrolmentData.selectedPlayers.length * totalPrice;
    });
  };

  const handleSelectedTournamentEnrolment = (
    enrolment: GetTournamentEnrolmentsResp
  ) => {
    setSelectedEnrolment(enrolment);
  };

  const selectTournamentStep = () => (
    <>
      <TournamentList
        tournaments={enrolmentData.tournaments.filter((tournament) => {
          return !enrolmentData.enrolments
            .filter((en) => en.status === "Paid")
            .find((en) => en.tournamentIds.includes(tournament.id));
        })}
        onSelectedTournament={handleSelectedTournament}
      />
    </>
  );

  const handleSelectdPlayer = (player: Player) => (evt: any) => {
    setSelectedPlayerList((draft) => {
      const foundPlayer = draft.find((p) => p.id === player.id);
      if (foundPlayer) {
        foundPlayer.selected = !foundPlayer.selected;
      } else {
        draft.push({
          id: player.id,
          selected: true,
        });
      }
      setEnrolmentData((x) => {
        x.selectedPlayers = x.players.filter((xx) => {
          const f = draft.find((yy) => yy.id === xx.id && yy.selected);
          return !!f;
        });

        const totalPrice = x.selectedTournaments.reduce(
          (a: number, c: Tournament): number => {
            return a + c?.price ?? 0;
          },
          0
        );
        x.amount = x.selectedPlayers.length * totalPrice;
      });
    });
  };

  const isPlayerSelected = (player: Player) =>
    // !!selectedPlayerList.find((p) => p.id === player.id && p.selected);
    !!enrolmentData.selectedPlayers.find((p) => p.id === player.id);

  const selectPlayerStep = () => (
    <List>
      {enrolmentData.players.map((player: Player) => (
        <ListItem
          key={player.id}
          role={undefined}
          dense
          button
          onClick={handleSelectdPlayer(player)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={isPlayerSelected(player)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText id={player.id} primary={getPlayerName(player)} />
        </ListItem>
      ))}
    </List>
  );

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return selectPlayerStep();
      case 2:
        return <Fragment />;
      default:
        return "Unknown step";
    }
  }

  const handleStepClick = (stepIndex: number) => () => {
    setActiveStep(stepIndex);
  };
  const onResult = async ({
    succeed,
    paymentId,
    paymentMethod,
  }: {
    succeed: boolean;
    paymentId?: string;
    paymentMethod?: string;
  }) => {
    if (succeed && paymentId && paymentMethod) {
      showSuccessNotification("Payment finished");
      setOpenEnrolmentDlg(false);
    } else {
      showErrorNotification("Payment failure");
    }
    setOpenEnrolmentDlg(false);
  };

  const submitForm = async () => {
    return await addTournamentEnrolment({
      userId: userProfile?.id,
      // paymentCode: enrolmentData.paymentCode,
      playerIds: enrolmentData.selectedPlayers.map((p) => p.id),
      // tournamentId: enrolmentData.selectedTournament?.id,
      tournamentIds: enrolmentData.selectedTournaments.map((t) => t.id),
      amount: enrolmentData.amount,
      totalAmount: applyPaymentAmount(enrolmentData.amount),
    });
  };

  const isPlayersSelected = enrolmentData.selectedPlayers.length > 0;

  const canBeEnroled =
    enrolmentData.selectedTournaments.length > 0 && isPlayersSelected;

  return (
    <FormContainer>
      <FormTitle title="Tournament enrolments" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {enrolmentData.enrolments?.length > 0 && (
              <TournamentEnrolmentList
                tournamentEnrolments={enrolmentData.enrolments}
                onSelectedEnrolment={handleSelectedTournamentEnrolment}
              />
            )}
            {enrolmentData?.enrolments?.length === 0 && (
              <EmptyInfo title="There is no enrolments" />
            )}
          </Grid>
          {userProfile?.role !== ERole.Admin && (
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  setEnrolmentData((draft) => {});
                  setOpenEnrolmentDlg(true);
                }}
              >
                Enrol a tournament
              </Button>
            </Grid>
          )}
          {/* {userProfile?.role === ERole.Admin && (
            <Grid item xs={12}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                fullWidth
                disabled={selectedEnrolment === null}
                onClick={() => {}}
              >
                Enrolment detail
              </Button>
            </Grid>
          )} */}
        </FormRow>
      </Grid>
      {
        <Dialog
          open={openEnrolmentDlg}
          onClose={handleClose}
          fullWidth
          aria-labelledby="max-width-dialog-title"
          classes={{ paperWidthSm: classes.paperWidthSm }}
        >
          <DialogTitle onClose={handleClose}>
            <Typography variant="h6">Enrol</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant={"h3"}> Please select players</Typography>
                {selectPlayerStep()}
              </Grid>
              <Grid item>
                <Typography variant={"h3"}>
                  Please select tournaments you want to enrol
                </Typography>
                {selectTournamentStep()}
              </Grid>
              <Grid item>
                {canBeEnroled && (
                  <PaymentForm
                    amount={enrolmentData.amount}
                    totalAmount={applyPaymentAmount(enrolmentData.amount)}
                    // referenceCode={enrolmentData.paymentCode}
                    submitForm={submitForm}
                    onResult={onResult}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </FormContainer>
  );
}

export default withStyles(styles)(TournamentEnrolment);
