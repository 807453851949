import {
  AddActiveKidsVouchersInput,
  AddHolidayCourseDocument,
  AddHolidayCourseInput,
  AddHolidayCourseMutation,
  DeleteHolidayCourseDocument,
  DeleteHolidayCourseInput,
  DeleteHolidayCourseMutation,
  GetHolidayCourseDocument,
  GetHolidayCourseQuery,
  UpdateHolidayCourseDocument,
  UpdateHolidayCourseInput,
  UpdateHolidayCourseMutation,
  GetVouchersQuery,
  GetVouchersDocument,
  DeleteActiveKidsVouchersInput,
  DeleteVouchersMutation,
  DeleteVouchersDocument,
  AddVoucherMutation,
  AddVoucherDocument,
  UpdateVoucherDocument,
  UpdateVoucherMutation,
  UpdateActiveKidsVouchersInput,
  ChangeVoucherStatusInput,
  ChangeVoucherStatusMutation,
  ChangeVoucherStatusDocument,
} from "../generated/graphql";
import { client } from "./client";

export const addActiveKidsVoucher = async (
  inputData: AddActiveKidsVouchersInput
) =>
  (
    await client.mutate<AddVoucherMutation>({
      mutation: AddVoucherDocument,
      variables: {
        param: {
          ...inputData,
        },
      },
    })
  ).data?.addVoucher;

export const getActiveKidsVouchers = async (ownerId: string) =>
  (
    await client.query<GetVouchersQuery>({
      query: GetVouchersDocument,
      variables: {
        param: {
          ownerId,
        },
      },
    })
  ).data?.getVouchers;

export const deleteActiveKidsVouchers = async ({
  voucherIds,
}: DeleteActiveKidsVouchersInput): Promise<boolean> => {
  const result =
    (
      await client.mutate<DeleteVouchersMutation>({
        mutation: DeleteVouchersDocument,
        variables: {
          param: {
            voucherIds,
          },
        },
      })
    ).data?.deleteVouchers ?? false;
  return result;
};

export const updateActiveKidsVoucher = async (
  param: UpdateActiveKidsVouchersInput
) => {
  const result = (
    await client.mutate<UpdateVoucherMutation>({
      mutation: UpdateVoucherDocument,
      variables: {
        param,
      },
    })
  ).data?.updateVoucher;
  return result;
};

export const changeVoucherStatus = async (param: ChangeVoucherStatusInput) => {
  const result = (
    await client.mutate<ChangeVoucherStatusMutation>({
      mutation: ChangeVoucherStatusDocument,
      variables: {
        param,
      },
    })
  ).data?.changeVoucherStatus;
  return result;
};
