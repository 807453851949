import { Hidden } from "@material-ui/core";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import { AuthContext } from "../../context/AuthContext";
import NavMenu from "./NavMenu";

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateAreas: `
            "header header"
            "nav section"`,
      gridTemplateColumns: "240px 1fr",
      gridTemplateRows: "64px 1fr",
      minHeight: "100vh",
      [breakpoints.down("sm")]: {
        gridTemplateAreas: `
        "header"
        "section"`,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "64px 1fr",
      },
    },
    header: { gridArea: "header" },
    nav: {
      gridArea: "nav",
    },
    section: {
      overflowY: "scroll",
      height: "90vh",
      gridArea: "section",
      [breakpoints.down("sm")]: {
        padding: `${spacing(1)}px ${spacing(1)}px`,
      },
      padding: `${spacing(1)}px ${spacing(3)}px`,
    },
  });

interface Props extends WithStyles<typeof styles> {
  children: JSX.Element;
}

const LayoutComponent = ({ classes, children }: Props) => {
  const { isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  return (
    <React.Fragment>
      <main className={classes.root}>
        <header className={classes.header}>
          <Header />
        </header>
        <Hidden smDown>
          <nav className={classes.nav}>
            <NavMenu />
          </nav>
        </Hidden>
        <section className={classes.section}>{children}</section>
      </main>
    </React.Fragment>
  );
};

export default withStyles(styles)(LayoutComponent);
