import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DeleteIcon from "@material-ui/icons/Delete";
import { addPaymentMethod, deletePaymentMethod } from "../api/payment.service";
import { GetPaymentMethodsResp } from "../generated/graphql";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import SetupForm from "./SetupForm";
import { NotificationContext } from "../context/NotificationContext";
const stripePromise = loadStripe(
  // "pk_test_51NBHD7ILmiXWY2zVIgEWetQXixqHQnRvanCHiW4jIIjj8uYdE0RzX3QuC0zwTvtROtfpWT1CJJfWnnjo3Wg0T9B700VM4HnXLY"
  "pk_live_51NOGRMBhd1nZaB4CFfLL8uh3zN6b9NLhxJmaQ6ujNm7Vm1BFpoX6bzZ0TTE4yENWBzuPkgThjtfv162qjrXFD7ay00noWiUxxl"
);

const styles = (theme: Theme) =>
  createStyles({
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    container: {
      padding: theme.spacing(3),
    },
  });

const PaymentMethodList = ({ methods, onChange, reload, classes }: Props) => {
  const loadSetupIntent = async () => {
    if (methods.length === 0) {
      const addPaymentMethodResp = await addPaymentMethod();
      setClientSetupIntent(addPaymentMethodResp?.clientSecret);
    }
  };
  const [selectedMethod, setSelectedMethod] = useState<GetPaymentMethodsResp>();

  const handleToggle = (method: GetPaymentMethodsResp) => () => {
    onChange(method);
    setSelectedMethod(method);
  };
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);
  const [clientSetupIntent, setClientSetupIntent] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    loadSetupIntent();
  }, [methods.length]);

  const [toAddNewCard, setToAddNewCard] = useState(false);
  const addNewCard = () => {
    setToAddNewCard(true);
  };

  const removeCard = (cardToBeRemoved: any) => async () => {
    await deletePaymentMethod(cardToBeRemoved.id);
    showSuccessNotification(
      `Payment with last 4 digits ${cardToBeRemoved.last4} expiration ${cardToBeRemoved.exp_year} has been removed`
    );
    reload();
    return;
  };
  const options = {
    clientSecret: clientSetupIntent,
  };

  return (
    <div className={classes.container}>
      {methods.length > 0 && (
        <>
          <Typography variant="h2">
            Please choose a card below to pay
          </Typography>
          <List>
            {methods.map((method) => (
              <ListItem
                key={`${method.last4}${method.id}`}
                dense
                button
                onClick={handleToggle(method)}
                selected={selectedMethod?.id === method.id}
                style={{ marginTop: 24 }}
              >
                <Typography variant="h3">
                  {`${method.last4}  ${method.exp_month} / ${method.exp_year}`}
                </Typography>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={removeCard(method)}
                  >
                    <DeleteIcon
                      color="primary"
                      style={{ width: 48, height: 48 }}
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {methods.length === 0 && clientSetupIntent && (
        <Button
          variant="contained"
          color="primary"
          onClick={addNewCard}
          style={{ marginTop: 12 }}
        >
          Add a new card
        </Button>
      )}

      {toAddNewCard && clientSetupIntent && (
        <>
          <Typography variant="h2"> Please add a card to pay.</Typography>{" "}
          <Elements stripe={stripePromise} options={options}>
            <SetupForm
              onResult={(succeed: boolean) => {
                if (succeed) {
                  showSuccessNotification("Payment method added");
                  setToAddNewCard(false);
                  reload();
                } else {
                  showErrorNotification("Failed to add payment method");
                }
              }}
            />
          </Elements>
        </>
      )}
    </div>
  );
};
export interface Props extends WithStyles<typeof styles> {
  methods: GetPaymentMethodsResp[];
  onChange: (method: GetPaymentMethodsResp) => void;
  reload: () => void;
}

export default withStyles(styles)(PaymentMethodList);
