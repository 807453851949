import {
  Box,
  Button,
  Grid,
  PropTypes,
  TextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { Console } from "console";
import { Form } from "formik";
import React, { useContext } from "react";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import { useFormik } from "formik";
import { sendMessage } from "../api/utils.service";
import * as yup from "yup";
import { emailValidator, mobilePhoneValidator } from "../utils/validator";
import { NotificationContext } from "../context/NotificationContext";
import { LoadingContext } from "../context/LoadingContext";
const styles = (theme: Theme) =>
  createStyles({
    paper: {},
  });

export interface Props extends WithStyles<typeof styles> {}

const SendQuestion = (props: Props) => {
  const { classes } = props;
  // const handleSubmit = (evt: Event, data: any) => {
  //   evt.preventDefault();
  //   console.log("hello, data", data);
  // };

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showSuccessNotification } = useContext(NotificationContext);

  const initialValues = {
    firstName: "",
    email: "",
    message: "",
    phone: "",
  };

  const validationSchema = yup.object().shape({
    email: emailValidator,
    phone: mobilePhoneValidator,
    message: yup.string().required("Message is required").nullable(false),
    firstName: yup.string().required("Name is required").nullable(false),
  });
  const { handleSubmit, values, handleChange, touched, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ message, email, firstName, phone }: any) => {
      showLoading();
      setTimeout(() => {
        hideLoading();
        showSuccessNotification("Successfully sent your question by email.");
      }, 1000);
      await sendMessage({
        email,
        firstName,
        message,
        phone,
      });
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
        >
          <Typography variant="h3" style={{ margin: "2rem" }}>
            <Box fontWeight="fontWeightMedium" display="inline">
              Got a question for us?
            </Box>
          </Typography>
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                label="Your first name"
                helperText={errors.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="phone"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                label="Your phone number"
                helperText={errors.phone}
                error={touched.phone && Boolean(errors.phone)}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </FormRow>

          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                label="Your email"
                helperText={errors.email}
                error={touched.email && Boolean(errors.email)}
                type="text"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                multiline
                rows={5}
                label="Write your message here"
                type="text"
                fullWidth
                helperText={errors.message}
                error={touched.message && Boolean(errors.message)}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                SEND MESSAGE
              </Button>
            </Grid>
          </FormRow>
        </Grid>
      </form>
    </>
  );
};

export default withStyles(styles)(SendQuestion);
