import { IconButton } from "@material-ui/core";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Cancel";
import { Omit } from "@material-ui/types";
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { ERole } from "../generated/graphql";
import { routes } from "../routes";
import { onNavMenuRoutes } from "../utils/filterRoutes";
import RenderRouteItem from "./RenderRouteItem";

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "rgba(255, 255, 255, 0.7)",
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    itemCategory: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps
  extends Omit<DrawerProps, "classes">,
    WithStyles<typeof styles> {
  onClose: () => void;
}

function Navigator(props: NavigatorProps) {
  const { classes, onClose, ...other } = props;

  const { userProfile } = useContext(AuthContext);
  const myRoutes = onNavMenuRoutes(routes, userProfile?.role ?? ERole.Public);
  return (
    <Drawer variant="permanent" {...other}>
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={onClose}
        edge="start"
      >
        <CloseIcon />
      </IconButton>
      <List disablePadding>
        {myRoutes.map((myRoute, n) => (
          <RenderRouteItem key={n} route={myRoute} />
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
