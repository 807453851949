import { CognitoUser } from "amazon-cognito-identity-js";
import React, { createContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { getUserProfile } from "../api/user.service";
import { ERole, User } from "../generated/graphql";
import { IUser } from "../model/IUser";
import AuthService, {
  IChangePasswordRequest,
  IResetPasswordRequest,
  ISignInReq,
  ISignUpReq,
} from "../services/AuthService";

export interface IAuthResult {
  success: boolean;
  error?: string;
  hasUserProfile?: boolean;
}
export interface IAuthContext {
  isAuthenticated: boolean;
  signin: (value: ISignInReq) => Promise<IAuthResult>;
  signup: (value: ISignUpReq) => Promise<IAuthResult>;
  signout: () => Promise<IAuthResult>;
  changePassword: (value: IChangePasswordRequest) => Promise<IAuthResult>;
  forgotPassword: (email: string) => Promise<IAuthResult>;
  user: IUser | null;
  userProfile: User | null | undefined;
  setUserProfile: (profile: User) => void;
  isLoading: boolean;
  isUserRole: (userRole: ERole) => () => boolean;
  isAdminRole: () => boolean;
  isCoachRole: () => boolean;
  isParentRole: () => boolean;
  forgotPasswordSubmit: (value: IResetPasswordRequest) => Promise<IAuthResult>;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

interface IProps {
  children: any;
}

interface IAuthData {
  isAuthenticated: boolean;
  user: IUser | null;
  userProfile?: User | null | undefined;
  isLoading: boolean;
}

const AuthContextProvider = (props: IProps) => {
  const [authData, setAuthData] = useImmer<IAuthData>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const authenticate = async () => {
    let isAuthenticated = false;
    let userProfile: User | null | undefined = null;
    let cogUser: CognitoUser;
    try {
      cogUser = await AuthService.loggedInCogUser();
      if (cogUser) {
        isAuthenticated = true;
        userProfile = await getUserProfile();
      }
    } catch (e) {}
    setAuthData((draft: IAuthData) => {
      if (isAuthenticated) {
        draft.user = {
          userName: cogUser?.getUsername(),
          email: (cogUser as any)?.attributes["email"],
        };
      }
      draft.isAuthenticated = isAuthenticated;
      draft.userProfile = userProfile;
      draft.isLoading = false;
    });
  };

  useEffect(() => {
    authenticate();
  }, []);

  const signup = async ({
    email,
    password,
  }: ISignUpReq): Promise<IAuthResult> => {
    setAuthData((draft: IAuthData) => {
      draft.isAuthenticated = false;
      draft.user = null;
      draft.userProfile = null;
    });
    const result: IAuthResult = { success: true };
    try {
      await AuthService.signUp({ email, password });
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const signin = async (value: ISignInReq): Promise<IAuthResult> => {
    const result: IAuthResult = { success: true, hasUserProfile: false };
    try {
      const signInUser = (await AuthService.signIn(value)) as any;
      const userProfile = await getUserProfile();
      result.hasUserProfile = !!userProfile;
      setAuthData((draft: IAuthData) => {
        draft.isAuthenticated = true;
        draft.user = {
          userName: signInUser.getUsername(),
          email: signInUser.attributes["email"],
        };
        draft.userProfile = userProfile;
      });
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const signout = async (): Promise<IAuthResult> => {
    const result: IAuthResult = { success: true };
    try {
      await AuthService.signOut();
      setAuthData((draft) => {
        draft.isAuthenticated = false;
        draft.user = null;
      });
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const changePassword = async ({
    oldPassword,
    newPassword,
  }: IChangePasswordRequest): Promise<IAuthResult> => {
    const result: IAuthResult = { success: true };
    try {
      await AuthService.changePassword({ oldPassword, newPassword });
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const forgotPasswordSubmit = async (param: IResetPasswordRequest) => {
    const result: IAuthResult = { success: true };
    try {
      await AuthService.forgotPasswordSubmit(param);
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const setUserProfile = (profile: User) => {
    setAuthData((draft) => {
      draft.userProfile = profile;
    });
  };

  const forgotPassword = async (email: string) => {
    const result: IAuthResult = { success: true };
    try {
      await AuthService.forgotPassword(email);
    } catch (e) {
      result.success = false;
      result.error = e.message;
    }
    return result;
  };

  const isUserRole = (userRole: ERole) => () =>
    authData.userProfile?.role === userRole;

  const isAdminRole = isUserRole(ERole.Admin);
  const isParentRole = isUserRole(ERole.Parent);
  const isCoachRole = isUserRole(ERole.Coach);

  const { isAuthenticated, user, userProfile, isLoading } = authData;
  const authValue: IAuthContext = {
    isAuthenticated,
    signin,
    signup,
    signout,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    user,
    userProfile,
    setUserProfile,
    isLoading,
    isUserRole,
    isAdminRole,
    isParentRole,
    isCoachRole,
  };
  return (
    <AuthContext.Provider value={authValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
