import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { DEFAULT_EMAIL, DEFAULT_PASSWORD } from "../const";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import ERoutePath from "../routes/ERoutePath";
import { emailValidator } from "../utils/validator";
import DialogTitle from "./DialogTitle";
import EmailTextInput from "./EmailTextInput";
import FormRow from "./FormRow";
import PasswordTextInput from "./PasswordTextInput";
const validationsForm = yup.object().shape({
  email: emailValidator,
  password: yup
    .string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
});

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dlgAction: {
      justifyContent: "center",
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {
  open: boolean;
}
interface ISignUpValue {
  email: string;
  password: string;
  repeatPassword: string;
}

const SignUp = (props: Props) => {
  const history = useHistory();
  const { isAuthenticated, signup, signin } = useContext(AuthContext);
  const { showErrorNotification, showWarningNotification } =
    useContext(NotificationContext);
  const initialValues: ISignUpValue = {
    email: DEFAULT_EMAIL,
    password: DEFAULT_PASSWORD,
    repeatPassword: DEFAULT_PASSWORD,
  };
  const { values, handleSubmit, handleChange, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: validationsForm,
      onSubmit: async ({ email, password, repeatPassword }) => {
        const result = await signup({ email, password, repeatPassword });
        if (result.success) {
          await signin({
            email,
            password,
          });
          showWarningNotification("Please update your profile");
          history.push(ERoutePath.profile);
        } else {
          showErrorNotification(
            result.error ?? "Unable to signup, please try again later."
          );
        }
      },
    });
  const { classes } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const signUpWelcomeMessage = ``;
  if (isAuthenticated) {
    return null;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: 400,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={handleClose}>
          <Typography> Sign Up </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ minHeight: 300 }}
          >
            <Grid item xs={12}>
              <Typography align="center">{signUpWelcomeMessage}</Typography>
            </Grid>
            <FormRow>
              <Grid item xs={12}>
                <EmailTextInput
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextInput
                  id="password"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextInput
                  id="repeatPassword"
                  label="Repeat password"
                  type="password"
                  value={values.repeatPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.repeatPassword ? errors.repeatPassword : ""
                  }
                  error={touched.password && Boolean(errors.password)}
                  fullWidth
                />
              </Grid>
            </FormRow>
            <FormRow>
              <Link to="signin"> Already registered</Link>
            </FormRow>
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dlgAction }}>
          <Button type="submit" color="primary" variant="contained">
            Sign Up
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(SignUp);
