import { Typography } from "@material-ui/core";
import PersonOutline from "@material-ui/icons/PersonOutline";
import React, { memo } from "react";
import { User } from "../generated/graphql";
import getUserFullName from "../utils/getUserFullName";

interface IProps {
  user: User | null | undefined;
}

const getUserNameInitials = (name: string) => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() ?? "") + (initials.pop() ?? "")).toUpperCase();
};

const NameInitial = ({ user }: IProps) => {
  const name = user ? getUserFullName(user) : "Unknown";
  const initial = getUserNameInitials(name);
  return initial ? (
    <Typography>{initial}</Typography>
  ) : (
    <PersonOutline color={"primary"} />
  );
};
export default memo(NameInitial);
