export const headerHtml = `

<style>
  .menu-item a {
		color: black !important
	}
  .menu-item:hover a{
		color: #40AAD5 !important
	}
</style>
<header class="site-header fixed"><div class="wrap">
<div class="title-area">
<button class="menu-toggle" aria-expanded="false" aria-pressed="false" role="button" id="genesis-mobile-nav-primary">Menu<span>
</span></button><nav class="nav-primary genesis-responsive-menu" aria-label="Main" id="genesis-nav-primary" style="">
<div class="wrap"><ul id="menu-main-menu" class="menu genesis-nav-menu menu-primary js-superfish sf-js-enabled sf-arrows" style="touch-action: pan-y;"><li id="menu-item-1358" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1358">
<a href="https://www.viviantabletennis.com/#about"><span>About</span></a></li>
<li id="menu-item-3304" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3304"><a href="https://www.viviantabletennis.com/classes-timetable/"><span>Timetable</span></a></li>
<li id="menu-item-3176" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3176"><a href="https://www.viviantabletennis.com/sydney-holiday-camps/"><span>Holiday Camps</span></a></li>
<li id="menu-item-3177" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-3177"><a href="https://www.viviantabletennis.com/blog/"><span>Blogs</span></a></li>
<li id="menu-item-2003" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-37 current_page_item menu-item-2003"><a href="https://www.viviantabletennis.com/contact/" aria-current="page"><span>Contact</span></a></li>
<li id="menu-item-1362" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1362"><a target="_blank" rel="noopener" href="https://www.viviantabletennis.com/signin"><span>Sign in/Sign up</span></a></li>
<li id="menu-item-2334" class="main-menu-cta menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-37 current_page_item menu-item-2334"><a href="https://www.viviantabletennis.com/contact/" aria-current="page"><span>Book Assessment</span></a></li>
<li id="menu-item-84" class="mobile-social-heading menu-item menu-item-type-custom menu-item-object-custom menu-item-84"><a><span>Follow Us</span></a></li>

</ul></div></nav></div></header>`;
