import { Button, Grid, TextField } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import {
  addHolidayCourse,
  deleteHolidayCourse,
  setHolidayCourseStatus,
  updateHolidayCourse,
} from "../api/holidayCourse.service";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import {
  AddHolidayCourseInput,
  AddHolidayCourseResp,
  ECourseStatus,
  HolidayCourse,
  UpdateHolidayCourseInput,
  UpdateHolidayCourseResp,
} from "../generated/graphql";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";

const styles = (theme: Theme) => createStyles({});

export interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  onNewCourse: (newCourse: AddHolidayCourseResp) => void;
  onCourseUpdated: (courseUpdated: UpdateHolidayCourseResp) => void;
  onCourseDeleted: (courseId: string) => void;
  onCourseStatusUpdated: (course: HolidayCourse | null | undefined) => void;
  course: HolidayCourse | null;
}

function HolidayCourseDetail({
  classes,
  onClose,
  onNewCourse,
  course,
  onCourseUpdated,
  onCourseDeleted,
  onCourseStatusUpdated,
}: Props) {
  const isNewCourse = !!!course;

  const initialValues = {
    description: course?.description ?? "Spring school holiday",
    startDate: course?.startDate ?? "2022-02-11",
    endDate: course?.endDate ?? "2022-03-11",
    startTime: course?.startTime ?? "09:30:00",
    endTime: course?.endTime ?? "15:30:00",
    price: course?.price ?? 300,
    capacity: course?.capacity ?? 20,
  };
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotificationContext);

  const validationSchema = yup.object().shape({
    description: yup.string().required("Enter your description"),
    capacity: yup.number().required("Enter capacity"),
    price: yup.number().required("Enter price"),
  });
  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        description,
        price: priceText,
        capacity: capacityText,
      } = v;

      // convert course days from objects into array
      const price = Number(priceText);
      const capacity = Number(capacityText);
      const courseData = [];
      try {
        showLoading();
        if (isNewCourse) {
          const addCourseParam: AddHolidayCourseInput = {
            courseData: [
              {
                description,
                startDate,
                endDate,
                startTime,
                endTime,
                price,
                capacity,
              },
            ],
          };
          const newCourse = await addHolidayCourse(addCourseParam);
          showSuccessNotification("Course saved");
          if (newCourse) {
            onNewCourse(newCourse);
          }
        } else {
          const updateCourseParam: UpdateHolidayCourseInput = {
            courseId: course?.id,
            courseData: {
              description,
              startDate,
              endDate,
              startTime,
              endTime,
              price,
              capacity,
            },
          };
          const updatedCourse = await updateHolidayCourse(updateCourseParam);
          showSuccessNotification("Course updated");
          if (updatedCourse && updatedCourse.courses?.length > 0) {
            onCourseUpdated(updatedCourse);
          }
        }
        onClose();
      } catch (e) {
        showErrorNotification("please try again later");
      } finally {
        hideLoading();
      }
    },
  });

  const handleStartDateChange = (e: any) => {
    const startDate = e.target.value;
    setFieldValue(`startDate`, startDate);
    handleChange(e);
  };

  const handleDeleteCourse = async () => {
    await deleteHolidayCourse({
      courseId: course?.id,
    });
    onCourseDeleted(course?.id);
    onClose();
  };

  const handleSetCourseStatus = (currentStatus: ECourseStatus) => async () => {
    const updatedCourse = await setHolidayCourseStatus({
      courseId: course?.id,
      status:
        currentStatus == ECourseStatus.Active
          ? ECourseStatus.Inactive
          : ECourseStatus.Active,
    });
    onCourseStatusUpdated(updatedCourse);
    onClose();
  };

  const handleEndDateChange = (e: any) => {
    const endDate = e.target.value;
    setFieldValue("endDate", endDate);
    handleChange(e);
  };

  const handleTimeChange = (fieldName: string) => (e: any) => {
    const tm = e.target.value;
    setFieldValue(fieldName, tm);
    handleChange(e);
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
        >
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="courseDescriptionId"
                name="description"
                label="Course description"
                type="text"
                fullWidth
                value={values.description}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="courseStartDate"
                name="courseStartDate"
                label="Course start Date"
                type="date"
                value={values.startDate}
                onChange={handleStartDateChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="courseEndDate"
                name="courseEndDate"
                label="Course end date"
                type="date"
                value={values.endDate}
                onChange={handleEndDateChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="courseStartTime"
                name="courseStartTime"
                label="Course start time"
                type="time"
                value={values.startTime}
                onChange={handleTimeChange("startTime")}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="courseEndTime"
                name="courseEndTime"
                label="Course end time"
                type="time"
                value={values.endTime}
                onChange={handleTimeChange("endTime")}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="capacity"
                name="capacity"
                label="Capacity"
                type="number"
                value={values.capacity}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="price"
                name="price"
                label="Price"
                type=""
                value={values.price}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {isNewCourse ? "Save a new course" : "Update course"}
              </Button>
            </Grid>
            {course && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleDeleteCourse}
                  fullWidth
                >
                  {"Delete course"}
                </Button>
              </Grid>
            )}
            {course && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleSetCourseStatus(course.status)}
                  fullWidth
                >
                  {course.status === ECourseStatus.Active
                    ? "Disable this course"
                    : "Enable this course"}
                </Button>
              </Grid>
            )}
          </FormRow>
        </Grid>
      </form>
    </FormContainer>
  );
}

export default withStyles(styles)(HolidayCourseDetail);
