import nodeFetch from "node-fetch";

export const fetch =
  typeof window !== "undefined" && window.fetch
    ? window.fetch.bind(window)
    : (nodeFetch as any);

enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

interface IException {
  httpCode: number;
  message: string;
}

const isNoContent = (response: Response) => {
  return response.status === 204;
};

const get = <T>(
  path: string,
  data?: any,
  headers = {}
): Promise<T | undefined> => {
  const tmpPath = data ? `${path}?${getQueryString(data)}` : path;
  return doFetch(tmpPath, null, HttpMethods.GET, headers);
};

const getQueryString = (params: any) =>
  Object.keys(params)
    .map((k) => {
      if (Array.isArray(params[k])) {
        return params[k]
          .map(
            (val: any) =>
              `${encodeURIComponent(k)}[]=${encodeURIComponent(val)}`
          )
          .join("&");
      }

      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`;
    })
    .join("&");

const post = <T>(
  path: string,
  data: any,
  headers = {}
): Promise<T | undefined> => doFetch(path, data, HttpMethods.POST, headers);

const put = <T>(
  path: string,
  data: any,
  headers = {}
): Promise<T | undefined> => doFetch(path, data, HttpMethods.PUT, headers);

const del = <T>(
  path: string,
  data: any,
  headers = {}
): Promise<T | undefined> => doFetch(path, data, HttpMethods.DELETE, headers);

const doFetch = async (
  path: string,
  data: any,
  method: HttpMethods,
  headers: HeadersInit = {}
) => {
  const request: RequestInit = {
    method,
    headers: {
      ...headers,
    },
  };
  request.body = data;
  const response: Response = await fetch(path, request);
  if (!response.ok) {
    const error: IException = {
      httpCode: response.status,
      message: await getResult(response),
    };
    throw error;
  }
  return getResult(response);
};

const getResult = (response: Response) => {
  if (isNoContent(response)) {
    return undefined;
  }
  if (/application\/json/.test(response.headers.get("Content-Type")!)) {
    return response.json();
  }
  return response.text();
};

const rest = {
  get,
  post,
  put,
  delete: del,
};

export { rest };
