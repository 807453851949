enum ERoutePath {
  home = "/",
  home2 = "/home",
  signup = "/signup",
  signin = "/signin",
  enrolments = "/enrolments",
  payments = "/payments",
  changePassword = "/change-password",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
  signout = "/signout",
  profile = "/profile",
  players = "/players",
  courses = "/courses",
  activeKidsVoucheres = "/active-kids-vouchers",
  holidayCourses = "/holiday-courses",
  schoolEnrolment = "/school-enrolment",
  holidayCoursesEnrolment = "/holiday-courses-enrolment",
  tournamentEnrolment = "/tournament-enrolment",
  tournaments = "/tournaments",
  makeupRules = "/makeup-rules",
  timeTable = "/classes-timetable",
  holidayCampsTimeTable = "/holiday-camps-timetable",
  contactUs = "/contact",
  holidayCamp = "/sydney-holiday-camps",
  blog = "/blog",
  blogTechniques = "/blog/table-tennis-backhand-techniques",
  blogForBeginners = "/blog/table-tennis-for-beginners-5-tips-to-improve-your-bat-skills",
  blogWhy = "/blog/why-to-send-your-kid-to-holiday-programs",
  blogActivities = "/blog/extracurricular-activities-in-schools",
  blogThings = "/blog/things-to-do-on-holidays",
}

export default ERoutePath;
