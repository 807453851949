import { Box, Grid, PropTypes, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React from "react";
import SendQuestion from "./SendQuestion";
import { headerHtml } from "../htmls/headerHtml";
import { footerHtml } from "../htmls/footerHtml";
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: "100px",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

export interface PropOfSimpleText {
  variant?: any;
  title: string;
  text: string;
  isBold?: boolean;
}

const SimpleLineOfText = ({
  variant = "h4",
  title = "",
  text = "",
}: PropOfSimpleText) => {
  return (
    <>
      <Typography variant="h4" style={{ margin: "2rem" }}>
        <Box fontWeight="fontWeightMedium" display="inline">
          {title}
        </Box>
      </Typography>
      <Typography variant={"h5"} style={{ margin: "2rem" }}>
        <Box display="inline">{text}</Box>
      </Typography>
    </>
  );
};

const ContactUs = (props: Props) => {
  const { classes } = props;
  const htmlDoc = { __html: headerHtml };
  const footerDoc = { __html: footerHtml };
  return (
    <>
      <Paper
        className={classes.paper}
        dangerouslySetInnerHTML={htmlDoc}
      ></Paper>
      <Grid container direction="row" spacing={4} alignItems="center">
        <Grid item xs={6} spacing={4}>
          <Typography variant="h3" style={{ margin: "2rem" }}>
            <Box fontWeight="fontWeightMedium" display="inline">
              How to reach us
            </Box>
          </Typography>

          <SimpleLineOfText title="Phone:" text="0430 127 108" />
          <SimpleLineOfText
            title="Classes are scheduled between:"
            text="5:30am – 8:00pm"
          />
          <SimpleLineOfText title="Email:" text="vivtabletennis@gmail.com" />
          <SimpleLineOfText
            title="Address:"
            text="443 Willoughby Rd, Willoughby Rd, Willoughby NSW 2068"
          />
        </Grid>
        <Grid item xs={6}>
          <SendQuestion />
        </Grid>
      </Grid>
      <Paper
        className={classes.paper}
        dangerouslySetInnerHTML={footerDoc}
      ></Paper>
    </>
  );
};

export default withStyles(styles)(ContactUs);
