import { Button, Grid, TextField } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useImmer } from "use-immer";
import * as yup from "yup";
import {
  addCourse,
  deleteCourse,
  disableTerm,
  updateCourse,
} from "../api/course.service";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import {
  AddCourseInput,
  AddCourseResp,
  Course,
  CoursesResp,
  ECourseLevel,
  EWeekDay,
  UpdateCourseInput,
  UpdateCourseResp,
} from "../generated/graphql";
import termLessonCalculation, {
  ICourseLessonDay,
  isWeekDayInDate,
  numOfDayInDate,
  weekDayToNumber,
} from "../utils/termLessonCalculation";
import CourseDayDetail, {
  ICourseDayData,
  ICourseDaysData,
} from "./CourseDayDetail";
import CourseLevelSelect from "./CourseLevelSelect";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";

const styles = (theme: Theme) => createStyles({});

export interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  onNewCourse: (newCourse: AddCourseResp) => void;
  onCourseUpdated: (courseUpdated: UpdateCourseResp) => void;
  onCourseDeleted: (termId: string) => void;
  onTermDisabled: (termId: string) => void;
  course: CoursesResp | null;
  startDate: string;
  endDate: string;
  termDescription: string;
}

interface ITermLessonData {
  termDescription: string;
  startDate: string;
  endDate: string;
  courseLevel: ECourseLevel;
  termDays: ICourseLessonDay[];
}

const convertCoursesToCourseDays = (
  courses: Course[] | undefined,
  startDate: string,
  endDate: string
): ICourseDaysData => {
  const newCourseDays = () => {
    // new course
    const defaultCapacity = 20;
    const defaultUnitPrice = 30;
    const defaultCourseDay = (
      courseDay: EWeekDay,
      available: boolean = true,
      numOfLessons: number = 0
    ): ICourseDayData => ({
      lessonStartTime: "17:30",
      lessonEndTime: "19:00",
      capacity: defaultCapacity,
      unitPrice: defaultUnitPrice,
      numOfLessons,
      available,
      courseDay,
      numOfPublicHolidays: 0,
    });
    const checkDay = isWeekDayInDate(startDate, endDate);
    const numOfWeekDay = numOfDayInDate(startDate, endDate);

    return {
      [EWeekDay.Sun]: defaultCourseDay(
        EWeekDay.Sun,
        checkDay(EWeekDay.Sun),
        numOfWeekDay(EWeekDay.Sun)
      ),
      [EWeekDay.Mon]: defaultCourseDay(
        EWeekDay.Mon,
        checkDay(EWeekDay.Mon),
        numOfWeekDay(EWeekDay.Mon)
      ),
      [EWeekDay.Tues]: defaultCourseDay(
        EWeekDay.Tues,
        checkDay(EWeekDay.Tues),
        numOfWeekDay(EWeekDay.Tues)
      ),
      [EWeekDay.Wed]: defaultCourseDay(
        EWeekDay.Wed,
        checkDay(EWeekDay.Wed),
        numOfWeekDay(EWeekDay.Wed)
      ),
      [EWeekDay.Thu]: defaultCourseDay(
        EWeekDay.Thu,
        checkDay(EWeekDay.Thu),
        numOfWeekDay(EWeekDay.Thu)
      ),
      [EWeekDay.Fri]: defaultCourseDay(
        EWeekDay.Fri,
        checkDay(EWeekDay.Fri),
        numOfWeekDay(EWeekDay.Fri)
      ),
      [EWeekDay.Sat]: defaultCourseDay(
        EWeekDay.Sat,
        checkDay(EWeekDay.Sat),
        numOfWeekDay(EWeekDay.Sat)
      ),
    };
  };

  const existingCourseDays = (courses: Course[]) => {
    const result: ICourseDaysData = {};
    courses.forEach(
      ({
        courseDay,
        startTime: lessonStartTime,
        endTime: lessonEndTime,
        capacity,
        numOfLessons,
        unitPrice,
        available,
        numOfPublicHolidays = 0,
      }: Course) => {
        result[`${courseDay}`] = {
          courseDay: courseDay as EWeekDay,
          lessonStartTime,
          lessonEndTime,
          capacity,
          numOfLessons,
          unitPrice,
          available,
          numOfPublicHolidays,
        };
      }
    );
    return result;
  };
  if (!courses) {
    return newCourseDays();
  }
  return existingCourseDays(courses);
};

const getTermDays = (course: CoursesResp) => {
  return course.courses
    .map(
      (c: Course): ICourseLessonDay => ({
        day: c.courseDay,
        num: c.numOfLessons,
        dates: [],
        available: c.available,
        numOfPublicHolidays: c.numOfPublicHolidays,
      })
    )
    .sort((aCourse, bCourse): number =>
      weekDayToNumber(aCourse.day) > weekDayToNumber(bCourse.day) ? 1 : -1
    );
};

function CourseDetail({
  classes,
  startDate,
  endDate,
  termDescription,
  onClose,
  onNewCourse,
  course,
  onCourseUpdated,
  onCourseDeleted,
  onTermDisabled,
}: Props) {
  const isNewCourse = !!!course;

  const termDays = course
    ? getTermDays(course)
    : termLessonCalculation(startDate, endDate);

  const [termLessonData, setTermLessonData] = useImmer({
    startDate,
    endDate,
    termDescription,
    courseLevel: course?.term?.level ?? ECourseLevel.Beginner,
    termDays,
  });

  const defaultCourseDayData = convertCoursesToCourseDays(
    course?.courses,
    termLessonData.startDate,
    termLessonData.endDate
  );

  const initialValues = {
    termDescription: termLessonData.termDescription,
    termStartDate: termLessonData.startDate,
    termEndDate: termLessonData.endDate,
    courseLevel: (course?.term.level ?? ECourseLevel.Beginner) as ECourseLevel,
    courseDaysData: defaultCourseDayData,
  };
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotificationContext);

  const validationSchema = yup.object().shape({
    termDescription: yup.string().required("Enter your term description"),
  });
  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      const {
        termDescription,
        termStartDate,
        termEndDate,
        courseLevel,
        courseDaysData,
      } = v;

      // convert course days from objects into array
      const courseData = [];
      for (const [_, weekDayData] of Object.entries(courseDaysData)) {
        courseData.push({
          ...weekDayData,
        });
      }
      try {
        showLoading();
        if (isNewCourse) {
          const addCourseParam: AddCourseInput = {
            termDescription,
            termStartDate,
            termEndDate,
            courseLevel,
            courseData,
          };
          const newCourse = await addCourse(addCourseParam);
          showSuccessNotification("Course saved");
          if (newCourse) {
            onNewCourse(newCourse);
          }
        } else {
          const updateCourseParam: UpdateCourseInput = {
            termId: course?.term.id,
            termDescription,
            termStartDate,
            termEndDate,
            courseLevel,
            courseData,
          };
          const updatedCourse = await updateCourse(updateCourseParam);
          showSuccessNotification("Course updated");
          if (updatedCourse) {
            onCourseUpdated(updatedCourse);
          }
        }
        onClose();
      } catch (e) {
        showErrorNotification("please try again later");
      } finally {
        hideLoading();
      }
    },
  });

  const handleStartDateChange = (e: any) => {
    const startDate = e.target.value;
    const termDays = termLessonCalculation(startDate, termLessonData.endDate);
    setTermLessonData((draft) => {
      draft.termDays = termDays;
      draft.startDate = startDate;
    });
    const defaultCourseDayData = convertCoursesToCourseDays(
      course?.courses,
      termLessonData.startDate,
      termLessonData.endDate
    );
    setFieldValue(`courseDaysData`, defaultCourseDayData);
    termDays.forEach((d) => {
      setFieldValue(`courseDaysData[${d.day}].numOfLessons`, d.num);
      setFieldValue(`courseDaysData[${d.day}].available`, d.num > 0);
    });
    handleChange(e);
  };

  const handleDeleteCourse = async () => {
    await deleteCourse({
      termId: course?.term.id,
    });
    onCourseDeleted(course?.term.id);
    onClose();
  };

  const handleDisableTerm = async () => {
    await disableTerm({
      termId: course?.term.id,
    });
    onTermDisabled(course?.term.id);
    onClose();
  };

  const handleEndDateChange = (e: any) => {
    const endDate = e.target.value;
    const termDays = termLessonCalculation(termLessonData.startDate, endDate);
    setTermLessonData((draft) => {
      draft.termDays = termDays;
      draft.endDate = endDate;
    });
    termDays.forEach((d) => {
      setFieldValue(`courseDaysData[${d.day}].numOfLessons`, d.num);
      setFieldValue(`courseDaysData[${d.day}].available`, d.num > 0);
    });
    handleChange(e);
  };

  const handleCourseLevelChange = (e: any) => {
    handleChange(e);
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
        >
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="termDescriptionId"
                name="termDescription"
                label="Term description"
                type="text"
                fullWidth
                value={values.termDescription}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="termStartDate"
                name="termStartDate"
                label="Term start date"
                type="date"
                value={values.termStartDate}
                onChange={handleStartDateChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="termEndDate"
                name="termEndDate"
                label="Term end date"
                type="date"
                value={values.termEndDate}
                onChange={handleEndDateChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow justify="flex-start">
            <Grid item xs={6}>
              <CourseLevelSelect
                value={values.courseLevel}
                onChange={handleCourseLevelChange}
              />
            </Grid>
          </FormRow>
          <FormRow>
            <CourseDayDetail
              termDays={termLessonData.termDays}
              courseDaysData={values.courseDaysData}
              startDate={termLessonData.startDate}
              onChange={handleChange}
              updateCourseDaysAvailable={(
                day: EWeekDay,
                available: boolean
              ) => {
                setFieldValue(`courseDaysData[${day}].available`, available);
              }}
            />
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {isNewCourse ? "Save a new course" : "Update course"}
              </Button>
            </Grid>
            {course && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleDeleteCourse}
                  fullWidth
                >
                  {"Delete course"}
                </Button>
              </Grid>
            )}
            {course && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleDisableTerm}
                  fullWidth
                >
                  {"Disable term"}
                </Button>
              </Grid>
            )}
          </FormRow>
        </Grid>
      </form>
    </FormContainer>
  );
}

export default withStyles(styles)(CourseDetail);
