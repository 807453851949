import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";

enum EPasswordType {
  password = "password",
  text = "text",
}
const PasswordTextInput = (props: TextFieldProps) => {
  const [passwordType, setPasswordType] = useState<EPasswordType>(
    EPasswordType.password
  );
  const isPasswordType = (type: EPasswordType) =>
    type === EPasswordType.password;
  return (
    <TextField
      name={props.name}
      id={props.id ?? "password"}
      label={props.label ?? "Password"}
      type={EPasswordType.password}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      helperText={props.helperText}
      error={props.error}
      margin={props.margin ?? "dense"}
      variant={props.variant ?? "outlined"}
      fullWidth
      InputProps={{
        type: `${passwordType}`,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => {
                setPasswordType(
                  isPasswordType(passwordType)
                    ? EPasswordType.text
                    : EPasswordType.password
                );
              }}
            >
              {isPasswordType(passwordType) ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PasswordTextInput;
