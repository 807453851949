import { Paper, withStyles } from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { DataGrid, RowSelectedParams } from "@material-ui/data-grid";
import React from "react";
import { EGender, Player } from "../generated/graphql";
import formatDate from "../utils/formatDate";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "65vh",
      overflowY: "auto",
      width: "95%",
    },
  });

export interface IPlayerRow {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: EGender;
  school: string;
  disability: boolean;
  parentId: string;
}

interface Props extends WithStyles<typeof styles> {
  players: Player[];
  onSelectedPlayer?: (selectedPlayer: RowSelectedParams) => void;
  multipleRowsSelection?: boolean;
}

const getRowsColumns = (players: Player[]) => {
  const rows = players.map(
    ({
      id,
      firstName,
      lastName,
      dob,
      gender,
      school,
      disability,
      disabilityClass,
      disabilityDescription,
    }: Player) => {
      return {
        id,
        firstName,
        lastName,
        dob: formatDate(dob),
        gender,
        school,
        disability: disability ? "Yes" : "No",
        disabilityDescription,
        disabilityClass,
      };
    }
  );
  const columns = [
    { field: "firstName", headerName: "First name", width: 120 },
    { field: "lastName", headerName: "Last name", width: 100 },
    { field: "dob", headerName: "Date of birth", width: 120 },
    { field: "gender", headerName: "Gender", width: 90 },
    { field: "disability", headerName: "Disability", width: 120 },
    // { field: "disabilityClass", headerName: "Class", width: 120 },
    // { field: "disabilityDescription", headerName: "Description", width: 120 },
  ];
  return {
    rows,
    columns,
  };
};

const PlayerList = ({
  classes,
  players,
  onSelectedPlayer,
  multipleRowsSelection = false,
}: Props) => {
  const { rows, columns } = getRowsColumns(players);
  const handleRowSelected = (rowSelectedParams: RowSelectedParams) => {
    if (onSelectedPlayer) {
      onSelectedPlayer(rowSelectedParams);
    }
  };
  return (
    <Paper className={classes.container}>
      {players.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={80}
          autoHeight
          onRowSelected={handleRowSelected}
          checkboxSelection={multipleRowsSelection}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(PlayerList);
