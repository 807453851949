import { Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RowParams } from "@material-ui/data-grid";
import React from "react";
import { VouchersResp } from "../generated/graphql";
import CustomizedDataGrid from "./CustomizedDataGrid";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "40vh",
      overflowY: "auto",
      width: "95%",
    },
    root: {
      backgroundColor: "yellow",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  vouchers: VouchersResp[];
  onSelectedVoucher?: (selectedVoucher: VouchersResp) => void;
}

export interface IVouchersRow {
  id: string;
  voucherNumber: string;
  name: string;
  status: string;
  expiryDate: string;
}

function VoucherList(props: Props) {
  const { classes, vouchers, onSelectedVoucher } = props;
  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "voucherNumber", headerName: "Voucher number", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "expiryDate", headerName: "Expiry date", width: 130 },
  ];

  const rows: IVouchersRow[] = vouchers.map((voucher: VouchersResp) => ({
    id: voucher.id,
    voucherNumber: voucher.voucherNumber,
    status: voucher.status,
    name: `${voucher.firstName}${
      voucher.middleName ? " " + voucher.middleName : ""
    } ${voucher.lastName}`,
    expiryDate: voucher.expiryDate,
  }));

  const handleRowClick = ({ rowIndex }: RowParams) => {
    if (onSelectedVoucher) {
      onSelectedVoucher(vouchers[rowIndex]);
    }
  };

  return (
    <Paper className={classes.container}>
      <CustomizedDataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onRowClick={handleRowClick}
        autoHeight
        classes={{
          root: classes.root,
        }}
      />
    </Paper>
  );
}

export default withStyles(styles)(VoucherList);
