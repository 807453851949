import { CircularProgress, Paper, Theme, Typography } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React from "react";

const styles = ({ palette }: Theme) => {
  return createStyles({
    loaderContainer: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      background: "rgba(0,0,0,0.4)",
      zIndex: 2000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    circleRoot: {
      color: palette.primary.main,
    },
    loaderText: {
      marginTop: 40,
      fontSize: 23,
      fontWeight: 600,
      color: "#fff",
    },
  });
};

interface Props extends WithStyles<typeof styles> {
  width?: number;
  height?: number;
  text?: string;
  isLoading?: boolean;
}

const LargeLoading = ({
  classes,
  width = 80,
  height = 80,
  isLoading = true,
  text,
}: Props) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Paper className={classes.loaderContainer}>
      <CircularProgress
        style={{ width, height }}
        classes={{ root: classes.circleRoot }}
      />
      {text && (
        <Typography variant="h4" className={classes.loaderText}>
          {text}
        </Typography>
      )}
    </Paper>
  );
};

export default withStyles(styles)(LargeLoading);
