import { Button, Grid, TextField } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { saveUserProfile, updateUserProfile } from "../api/user.service";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import { ERole } from "../generated/graphql";
import ERoutePath from "../routes/ERoutePath";
import {
  firstNameValidator,
  lastNameValidator,
  mobilePhoneValidator,
} from "../utils/validator";
import EmailTextInput from "./EmailTextInput";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import InformationBox from "./InformationBox";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

interface IProfileInput {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  cityTown: string;
  address: string;
}

const validationsForm = yup.object().shape({
  firstName: firstNameValidator,
  lastName: lastNameValidator,
  phone: mobilePhoneValidator,
});

function Profile(props: Props) {
  const { user, userProfile, setUserProfile } = useContext(AuthContext);
  const history = useHistory();

  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);

  const initialValues: IProfileInput = {
    firstName: userProfile?.firstName ?? "",
    lastName: userProfile?.lastName ?? "",
    phone: userProfile?.phone ?? "",
    email: user?.email ?? "",
    address: userProfile?.address ?? "",
    cityTown: userProfile?.cityTown ?? "",
  };
  const { values, handleSubmit, handleChange, errors, touched, handleBlur } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: validationsForm,
      onSubmit: async (values) => {
        try {
          let msg =
            "Saved user file successfully, please add a new player first.";
          if (userProfile) {
            msg = "User profile updated";
            const updatedUserProfile = await updateUserProfile({
              id: userProfile.id,
              userProfile: {
                uid: user?.userName as string,
                firstName: values.firstName,
                lastName: values.lastName,
                role: ERole.Parent, // by default set to parent
                address: values.address,
                cityTown: values.cityTown,
                phone: values.phone,
                email: user?.email as string,
              },
            });
            if (updatedUserProfile) {
              setUserProfile(updatedUserProfile);
            }
          } else {
            const savedUserProfile = await saveUserProfile({
              uid: user?.userName as string,
              firstName: values.firstName,
              lastName: values.lastName,
              role: ERole.Parent, // by default set to parent
              address: values.address,
              cityTown: values.cityTown,
              phone: values.phone,
              email: user?.email as string,
            });
            if (savedUserProfile) {
              setUserProfile(savedUserProfile);
            }
            // go to players page
            history.push(ERoutePath.players);
          }
          showSuccessNotification(msg);
        } catch (e) {
          showErrorNotification(
            "Failed to save user profile, please try again later"
          );
        }
      },
    });

  return (
    <FormContainer>
      <FormTitle title="Profile" />
      {!userProfile && <InformationBox text="Please update your profile" />}
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} alignItems="center">
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="lastName"
                fullWidth
                label="Family Name"
                value={values.lastName}
                onChange={handleChange}
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="firstName"
                fullWidth
                label="Given Name"
                value={values.firstName}
                onChange={handleChange}
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
              ></TextField>
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="address"
                fullWidth
                label="Address"
                value={values.address}
                onChange={handleChange}
                helperText={touched.address ? errors.address : ""}
                error={touched.address && Boolean(errors.address)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="cityTown"
                fullWidth
                label="City/Town"
                value={values.cityTown}
                onChange={handleChange}
                helperText={touched.cityTown ? errors.cityTown : ""}
                error={touched.cityTown && Boolean(errors.cityTown)}
              ></TextField>
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="phone"
                fullWidth
                label="Phone number"
                value={values.phone}
                onChange={handleChange}
                helperText={touched.phone ? errors.phone : ""}
                error={touched.phone && Boolean(errors.phone)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <EmailTextInput
                value={values.email}
                onChange={handleChange}
                disabled
                onBlur={handleBlur}
                helperText={touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email)}
                fullWidth
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {userProfile ? "Update profile" : "Save profile"}
              </Button>
            </Grid>
          </FormRow>
        </Grid>
      </form>
    </FormContainer>
  );
}

export default withStyles(styles)(Profile);
