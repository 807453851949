import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import { ECourseLevel } from "../generated/graphql";
import React from "react";
const courseLevels = [
  { label: "Private", value: ECourseLevel.Private },
  { label: "Elite", value: ECourseLevel.ELite },
  { label: "Beginner", value: ECourseLevel.Beginner },
  { label: "Intermediate 1", value: ECourseLevel.Intermediate1 },
  { label: "Intermediate 2", value: ECourseLevel.Intermediate2 },
  { label: "Intermediate 3", value: ECourseLevel.Intermediate3 },
  { label: "Advanced 1", value: ECourseLevel.Advanced1 },
  { label: "Advanced 2", value: ECourseLevel.Advanced2 },
  { label: "Advanced 3", value: ECourseLevel.Advanced3 },
  { label: "NSGH", value: ECourseLevel.Nsgh },
  { label: "RosevilleCollege", value: ECourseLevel.RosevilleCollege },
  { label: "NSBH", value: ECourseLevel.Nsbh },
];

const CourseLevelSelect = (props: TextFieldProps) => (
  <TextField
    id={props.id ?? ""}
    label={props.label ?? "Course level"}
    name={props.name ?? "courseLevel"}
    select
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    helperText={props.helperText}
    error={props.error}
    margin={props.margin ?? "dense"}
    variant={props.variant ?? "outlined"}
    fullWidth
  >
    {courseLevels.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);
export default CourseLevelSelect;
