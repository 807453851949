import { Paper, Theme, Typography } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    container: {
      margin: spacing(2),
    },
    title: {
      fontSize: "3em",
      [breakpoints.down("sm")]: {
        fontSize: "2em",
      },
    },
  });

interface Props extends WithStyles<typeof styles> {
  title: string;
}
const FormTitle = ({ title, classes }: Props) => (
  <Paper className={classes.container} elevation={0}>
    <Typography className={classes.title}>{title}</Typography>
  </Paper>
);
export default withStyles(styles)(FormTitle);
