import Auth from "@aws-amplify/auth";
import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./components/ContextProvider";
import Routes from "./components/Routes";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthConfig } from "./services/AuthConfig";
import theme from "./Theme";

Auth.configure(AuthConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ContextProvider>
            <Routes></Routes>
          </ContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
