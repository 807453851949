import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import queryString from "query-string";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import ERoutePath from "../routes/ERoutePath";
import { emailValidator } from "../utils/validator";
import DialogTitle from "./DialogTitle";
import EmailTextInput from "./EmailTextInput";
import FormRow from "./FormRow";
import PasswordTextInput from "./PasswordTextInput";

const validationsForm = yup.object().shape({
  email: emailValidator,
});

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dlgAction: {
      justifyContent: "center",
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface IForgotPasswordValue {
  email: string;
}

const ResetPassword = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  //   const [passwordState, setPasswordState] = useImmer({
  //     email: "",
  //     code: "",
  //     password: "",
  //   });
  const data = queryString.parse(location.search);
  //   setPasswordState(() => ({
  //     email: data?.["email"]?.toString() ?? "",
  //     code: data?.["code"]?.toString() ?? "",
  //     password: "",
  //   }));
  const passwordState = {
    email: data?.["email"]?.toString() ?? "",
    code: data?.["code"]?.toString() ?? "",
    password: "",
  };
  const { showSuccessNotification, showErrorNotification } = useContext(
    NotificationContext
  );
  const { isAuthenticated, forgotPasswordSubmit } = useContext(AuthContext);
  const initialValues = {
    email: passwordState.email,
    code: passwordState.code,
    password: passwordState.password,
  };
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema: validationsForm,
    onSubmit: async ({ email, code, password }) => {
      const result = await forgotPasswordSubmit({ code, email, password });
      if (result.success) {
        showSuccessNotification("Password has been reset, please sign in");
        history.push(ERoutePath.signin);
      } else {
        showErrorNotification(
          result.error ?? "Unable to sign in, please try again later."
        );
      }
    },
  });
  const { classes } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const welcomeMessage = `Reset password message will be sent to below email`;
  if (isAuthenticated) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={handleClose}>
          <Typography> Reset password </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ minHeight: 300 }}
          >
            <Grid item>
              <Typography align="center">{welcomeMessage}</Typography>
            </Grid>
            <FormRow>
              <Grid item xs={12}>
                <EmailTextInput
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextInput
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  fullWidth
                />
              </Grid>
            </FormRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Reset password
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(ResetPassword);
