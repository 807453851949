import React, { createContext } from "react";
import { useImmer } from "use-immer";
import LargeLoading from "../components/LargeLoading";

export interface ILoadingContext {
  showLoading: (text?: string) => void;
  hideLoading: () => void;
}

export const LoadingContext = createContext<ILoadingContext>({
  showLoading: (_text?: string) => {},
  hideLoading: () => {},
});

interface IProps {
  children: any;
}
interface ILoadingData {
  isLoading: boolean;
  text: string;
}

export const withLoadingContextProvider = (Component: React.ComponentType) => (
  props: IProps
) => {
  const [loading, setLoading] = useImmer<ILoadingData>({
    isLoading: false,
    text: "",
  });

  const showLoading = (text?: string) => {
    setLoading((draft: ILoadingData) => {
      draft.isLoading = true;
      draft.text = text || "";
    });
  };

  const hideLoading = () => {
    setLoading((draft: ILoadingData) => {
      draft.isLoading = false;
    });
  };

  const { isLoading, text } = loading;
  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        hideLoading,
      }}
    >
      <LargeLoading isLoading={isLoading} text={text} />
      <Component>{props.children}</Component>
    </LoadingContext.Provider>
  );
};
