import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useImmer } from "use-immer";
import { getActiveKidsVouchers } from "../api/activeKidsVouchers.service";
import { getHolidayCourses } from "../api/holidayCourse.service";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import EmptyInfo from "../EmptyInfo";
import {
  AddHolidayCourseResp,
  ERole,
  HolidayCourse,
  UpdateHolidayCourseResp,
  VouchersResp,
} from "../generated/graphql";
import ERoutePath from "../routes/ERoutePath";
import ActiveKidsVoucherDetail from "./ActiveKidsVoucherDetail";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import HolidayCourseDetail from "./HolidayCourseDetail";
import HolidayCourseList from "./HolidayCourseList";
import VoucherList from "./VoucherList";

const styles = (theme: Theme) =>
  createStyles({
    paperWidthSm: {
      maxWidth: "80em",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

function ActiveKidsVouchers(props: Props) {
  const { classes } = props;
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { userProfile } = useContext(AuthContext);

  const [addNewVoucher, setAddNewVoucher] = useState(false);
  const [vouchers, setVouchers] = useImmer<VouchersResp[]>([]);
  const history = useHistory();
  const [selectedVoucher, setSelectedVoucher] = useState<VouchersResp | null>(
    null
  );

  // const handleVoucherUpdated = (updatedCourse: UpdateHolidayCourseResp) => {
  const handleVoucherUpdated = (updatedCourse: VouchersResp) => {
    setVouchers((draft) => {
      const idx = draft.findIndex((dc) => dc.id === updatedCourse.id);
      draft[idx] = updatedCourse;
    });
  };

  const handleNewVoucher = (newVoucher: VouchersResp) => {
    setVouchers((draft) => {
      draft.push(newVoucher);
    });
  };

  const handleVouchersDeleted = (voucherId: string) => {
    setVouchers((draft) => {
      const foundIdx = draft.findIndex((c) => c.id === voucherId);
      if (foundIdx !== -1) {
        draft.splice(foundIdx, 1);
      }
    });
  };

  const handleClose = () => {
    setAddNewVoucher(false);
    setSelectedVoucher(null);
  };

  const handleAddNewVoucher = () => {
    setAddNewVoucher(true);
  };

  const retrieveActiveKidsVouchers = async () => {
    showLoading();

    try {
      if (!userProfile?.uid) {
        history.push(ERoutePath.signin);
        return;
      }
      let vouchers: VouchersResp[] = [];
      if (userProfile.role === ERole.Admin) {
        vouchers = await getActiveKidsVouchers(userProfile?.uid);
      } else {
        vouchers = await getActiveKidsVouchers(userProfile?.uid);
      }
      setVouchers((draft) => {
        draft.length = 0;
        if (vouchers) {
          draft.push(...vouchers);
        }
      });
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    retrieveActiveKidsVouchers();
  }, []);

  const handleSelectedVoucher = (voucher: VouchersResp) => {
    setSelectedVoucher(voucher);
  };

  const getDialogTitle = () => (
    <Typography>
      {selectedVoucher ? "Update voucher" : "Add a new voucher"}
    </Typography>
  );

  return (
    <FormContainer>
      <FormTitle title="Active kids voucher" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {vouchers.length > 0 && (
              <VoucherList
                vouchers={vouchers}
                onSelectedVoucher={handleSelectedVoucher}
              />
            )}
            {vouchers.length === 0 && (
              <EmptyInfo title="Please add a new active kids voucher" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleAddNewVoucher}
            >
              Add a new voucher
            </Button>
          </Grid>
        </FormRow>
      </Grid>
      <Dialog
        fullWidth
        open={addNewVoucher || selectedVoucher !== null}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle onClose={handleClose}>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <ActiveKidsVoucherDetail
            onClose={handleClose}
            onNewVoucher={handleNewVoucher}
            onVoucherUpdated={handleVoucherUpdated}
            onVoucherDeleted={handleVouchersDeleted}
            voucher={selectedVoucher}
          />
        </DialogContent>
      </Dialog>
    </FormContainer>
  );
}

export default withStyles(styles)(ActiveKidsVouchers);
