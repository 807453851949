import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { getCourses } from "../api/course.service";
import { getEnrolments } from "../api/enrolment.service";
import { getHolidayCourses } from "../api/holidayCourse.service";
import { getPlayers } from "../api/player.service";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import EmptyInfo from "../EmptyInfo";
import {
  Course,
  CoursesResp,
  ECourseLevel,
  EnrolmentResult,
  ERole,
  Player,
  Term,
} from "../generated/graphql";
import getPlayerName from "../utils/getPlayerName";
import CourseListInWeekView from "./CourseListInWeekView";
import DialogTitle from "./DialogTitle";
import EnrolmentDetail, { IEnrolmentData } from "./EnrolmentDetail";
import EnrolmentList from "./EnrolmentList";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
const styles = (theme: Theme) =>
  createStyles({
    container: {},
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

const steps = ["Please select player", "Please select a course", "Enrol now"];

function SchoolEnrolment(props: Props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { userProfile } = useContext(AuthContext);
  const [openEnrolmentDlg, setOpenEnrolmentDlg] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [loadedData, setLoadedData] = useState(false);
  const [filterLevel, setFilterLevel] = useState<{ [name: string]: boolean }>({
    nsbh: true,
    nsgh: true,
    rosevillecollege: true,
  });

  const handleClose = () => {
    setOpenEnrolmentDlg(false);
  };
  const [enrolmentData, setEnrolmentData] = useImmer<IEnrolmentData>({
    courses: [],
    filteredCourses: [],
    holidayCourses: [],
    players: [],
    enrolments: [],
    selectedPlayers: [],
    selectedDays: {},
    selectedCourses: [],
  });

  const [selectedPlayerList, setSelectedPlayerList] = useImmer<
    {
      id: string;
      selected: boolean;
    }[]
  >([]);

  const handleSelectedCourse = (
    course: { course: Course; term: Term },
    bSelectCourse: boolean
  ) => {
    setEnrolmentData((draft) => {
      if (bSelectCourse) {
        draft.selectedCourses?.push(course);
      } else {
        const idx = draft.selectedCourses?.findIndex(
          (c) => course.course.id === c.course.id
        );
        if (idx !== undefined && idx >= 0) {
          draft.selectedCourses?.splice(idx, 1);
        }
      }
    });
  };

  const showFilterCheckboxes = () => {
    return (
      <>
        <Typography variant={"h5"}> Choose your school: </Typography>

        <FormControlLabel
          label="North Sydney Boys High"
          control={
            <Checkbox
              checked={filterLevel.nsbh}
              onChange={updateFilterLevel("nsbh")}
            />
          }
        />
        <FormControlLabel
          label="North Sydney Girls High"
          control={
            <Checkbox
              checked={filterLevel.nsgh}
              onChange={updateFilterLevel("nsgh")}
            />
          }
        />
        <FormControlLabel
          label="Roseville College"
          control={
            <Checkbox
              checked={filterLevel.rosevillecollege}
              onChange={updateFilterLevel("rosevillecollege")}
            />
          }
        />
      </>
    );
  };

  const updateFilterLevel = (level: string) => (evt: any) => {
    const newFilterLevels = {
      ...filterLevel,
      [level]: filterLevel[level] ? false : true,
    };
    setFilterLevel(newFilterLevels);
    setEnrolmentData((draft) => {
      draft.filteredCourses = enrolmentData.courses.filter(({ term }) => {
        return newFilterLevels[(term.level as string).toLowerCase()];
      });
    });
  };
  const retrieveData = async () => {
    showLoading();
    setLoadedData(false);
    const param =
      userProfile?.role === ERole.Admin ? {} : { userId: userProfile?.id };
    const parentId = userProfile?.role === ERole.Admin ? "" : userProfile?.id;
    const [courses, holidayCourses, players, enrolments] = await Promise.all([
      getCourses(),
      getHolidayCourses(),
      getPlayers({ parentId }),
      getEnrolments(param),
    ]);

    setEnrolmentData((draft) => {
      const schoolList = [
        `${ECourseLevel.Nsbh}`,
        `${ECourseLevel.Nsgh}`,
        `${ECourseLevel.RosevilleCollege}`,
      ];
      if (courses) {
        draft.courses = courses.filter((c) => {
          const a = DateTime.now().startOf("day");
          const b = DateTime.fromISO(c.term.endDate).startOf("day");
          return (
            DateTime.now().startOf("day") <=
            DateTime.fromISO(c.term.endDate).startOf("day")
          );
        }) as CoursesResp[];

        draft.courses = draft.courses.filter((c) => {
          return schoolList.indexOf(c.term.level) >= 0;
        });
        draft.filteredCourses = draft.courses;
      }
      if (players) {
        draft.players = players;
      }
      if (holidayCourses) {
        draft.holidayCourses = holidayCourses.courses;
      }
      if (enrolments) {
        draft.enrolments = enrolments;
      }
      hideLoading();
      setLoadedData(false);
    });
  };

  useEffect(() => {
    retrieveData();
  }, []);

  const selectCourseStep = () => (
    <>
      <CourseListInWeekView
        courses={enrolmentData.filteredCourses}
        onSelectedCourse={handleSelectedCourse}
      />
    </>
  );

  const handleSelectdPlayer = (player: Player) => (evt: any) => {
    setSelectedPlayerList((draft) => {
      const foundPlayer = draft.find((p) => p.id === player.id);
      if (foundPlayer) {
        foundPlayer.selected = !foundPlayer.selected;
      } else {
        draft.push({
          id: player.id,
          selected: true,
        });
      }
      setEnrolmentData((x) => {
        x.selectedPlayers = x.players.filter((xx) => {
          const f = draft.find((yy) => yy.id === xx.id && yy.selected);
          return !!f;
        });
      });
    });
  };

  const isPlayerSelected = (player: Player) =>
    // !!selectedPlayerList.find((p) => p.id === player.id && p.selected);
    !!enrolmentData.selectedPlayers.find((p) => p.id === player.id);

  const selectPlayerStep = () => (
    <List>
      {enrolmentData.players.map((player: Player) => (
        <ListItem
          key={player.id}
          role={undefined}
          dense
          button
          onClick={handleSelectdPlayer(player)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={isPlayerSelected(player)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText id={player.id} primary={getPlayerName(player)} />
        </ListItem>
      ))}
    </List>
  );

  const enrolmentDetailStep = () => (
    <EnrolmentDetail
      enrolmentData={enrolmentData}
      onEnrolmentAdded={(newEnrolment: EnrolmentResult) => {
        setEnrolmentData((draft) => {
          draft.enrolments.push(newEnrolment);
        });
      }}
      onClose={() => {
        handleClose();
      }}
    />
  );

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return selectPlayerStep();
      case 1:
        return selectCourseStep();
      case 2:
        return enrolmentDetailStep();
      default:
        return "Unknown step";
    }
  }

  const handleStepClick = (stepIndex: number) => () => {
    setActiveStep(stepIndex);
  };

  return (
    <FormContainer>
      <FormTitle title="Enrolment" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {enrolmentData.enrolments?.length > 0 && (
              <EnrolmentList enrolmentData={enrolmentData} />
            )}
            {loadedData && enrolmentData.enrolments?.length === 0 && (
              <EmptyInfo title="There is no enrolments" />
            )}
          </Grid>
          {userProfile?.role === ERole.Parent && (
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  // clear all the enrolmentData
                  setEnrolmentData((draft) => {
                    draft.selectedCourses = [];
                    draft.selectedPlayers = [];
                  });
                  setOpenEnrolmentDlg(true);
                }}
              >
                Enrol a course
              </Button>
            </Grid>
          )}
        </FormRow>
      </Grid>
      {
        <Dialog
          open={openEnrolmentDlg}
          onClose={handleClose}
          fullWidth
          aria-labelledby="max-width-dialog-title"
          classes={{ paperWidthSm: classes.paperWidthSm }}
        >
          <DialogTitle onClose={handleClose}>
            <Typography variant="h6">Enrol</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant={"h4"}> Please select players</Typography>
                {selectPlayerStep()}
              </Grid>
              <Grid item>
                <Typography variant={"h4"}>
                  Please select lesson you want to enrol
                </Typography>
                {showFilterCheckboxes()}
                {selectCourseStep()}
              </Grid>
              <Grid item>{enrolmentDetailStep()}</Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </FormContainer>
  );
}

export default withStyles(styles)(SchoolEnrolment);
