// 1.75 % + A$0.30
// 3.5% + A$0.30
const applyGst = (amount: number) => amount * 1.1;
// amout should be in cents
// const applyStripeDomestic = (amount: number) => amount * 1.0175 + 30;
const applyStripeDomestic = (amount: number) => amount * 1.02 + 30; // 2 percent;
const applyStripeInternational = (amount: number) => amount * 1.03 + 30;

export const applyPaymentAmount = (
  amount: number,
  doemestic: boolean = true,
  applyGST: boolean = false
) => {
  const amountInCents = amount * 100;
  return doemestic
    ? Math.round(
        applyStripeDomestic(applyGST ? applyGst(amountInCents) : amountInCents)
      )
    : Math.round(
        applyStripeInternational(
          applyGST ? applyGst(amountInCents) : amountInCents
        )
      );
};

export const amountToDollar = (amount: number) => (amount / 100).toFixed(2);
