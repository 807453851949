import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Mongo object id scalar type */
  ObjectId: any;
};

/** Active kis Vouchers */
export type ActiveKidsVouchers = {
  __typename?: 'ActiveKidsVouchers';
  id: Scalars['ObjectId'];
  ownerId: Scalars['ObjectId'];
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dob: Scalars['String'];
  expiryDate: Scalars['String'];
  voucherNumber: Scalars['String'];
  createdTime: Scalars['String'];
  claimedTime: Scalars['String'];
  status: EVoucherStatus;
};

export type AddActiveKidsVouchersInput = {
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  voucherNumber: Scalars['String'];
  dob: Scalars['String'];
  expiryDate: Scalars['String'];
};

export type AddCourseInput = {
  termDescription: Scalars['String'];
  termStartDate: Scalars['String'];
  termEndDate: Scalars['String'];
  courseLevel: ECourseLevel;
  courseData: Array<CourseDataInput>;
};

/** Add course response type */
export type AddCourseResp = {
  __typename?: 'AddCourseResp';
  term: Term;
  courses: Array<Course>;
};

export type AddEnrolmentInput = {
  type: Scalars['String'];
  userId: Scalars['String'];
  courseIds: Array<Scalars['String']>;
  playerIds: Array<Scalars['String']>;
  paymentAmount: Scalars['String'];
};

export type AddHolidayCourseInput = {
  courseData: Array<HolidayCourseDataInput>;
};

/** Add Holiday course response type */
export type AddHolidayCourseResp = {
  __typename?: 'AddHolidayCourseResp';
  courses: Array<HolidayCourse>;
};

/** Create payment customerresponse type */
export type AddPaymentMethodResp = {
  __typename?: 'AddPaymentMethodResp';
  clientSecret: Scalars['String'];
};

export type AddPlayerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  school: Scalars['String'];
  gender: EGender;
  dob: Scalars['String'];
  parentId: Scalars['String'];
  disability: Scalars['Boolean'];
  disabilityClass?: Maybe<Scalars['String']>;
  disabilityDescription?: Maybe<Scalars['String']>;
};

export type AddTournamentEnrolmentInput = {
  userId: Scalars['String'];
  tournamentIds?: Maybe<Array<Scalars['String']>>;
  tournamentId?: Maybe<Scalars['String']>;
  playerIds: Array<Scalars['String']>;
  amount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

/** Get tournament enrolments response type */
export type AddTournamentEnrolmentResp = {
  __typename?: 'AddTournamentEnrolmentResp';
  enrolment: TournamentEnrolment;
  payment: Payment;
};

export type AddTournamentInput = {
  name: Scalars['String'];
  startDate: Scalars['String'];
  price: Scalars['Float'];
};

export type ChangeTournamentInput = {
  id: Scalars['String'];
  status: EStatus;
};

export type ChangeVoucherStatusInput = {
  voucherId: Scalars['String'];
  status: EVoucherStatus;
};

/** Course */
export type Course = {
  __typename?: 'Course';
  id: Scalars['ObjectId'];
  termId: Scalars['String'];
  courseDay: EWeekDay;
  numOfLessons: Scalars['Float'];
  unitPrice: Scalars['Float'];
  capacity: Scalars['Float'];
  availableCapacity: Scalars['Float'];
  available: Scalars['Boolean'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  numOfPublicHolidays: Scalars['Float'];
  status?: Maybe<ECourseStatus>;
};

export type CourseDataInput = {
  courseDay: EWeekDay;
  lessonStartTime: Scalars['String'];
  lessonEndTime: Scalars['String'];
  capacity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  numOfLessons: Scalars['Float'];
  available: Scalars['Boolean'];
  numOfPublicHolidays?: Maybe<Scalars['Float']>;
};

/** Courses response type */
export type CoursesResp = {
  __typename?: 'CoursesResp';
  term: Term;
  courses: Array<Course>;
};

/** Add payment method response type */
export type CreatePaymendIntentResp = {
  __typename?: 'CreatePaymendIntentResp';
  paymentMethod: Scalars['String'];
  paymentId: Scalars['String'];
};

/** Create payment customerresponse type */
export type CreatePaymentCustomerResp = {
  __typename?: 'CreatePaymentCustomerResp';
  customerId: Scalars['String'];
};

export type CreatePaymentIntentInput = {
  amount: Scalars['Float'];
  paymentMethodId?: Maybe<Scalars['String']>;
  paymentCode?: Maybe<Scalars['String']>;
};

export type DeclineEnrolmentInput = {
  id: Scalars['String'];
};

export type DeleteActiveKidsVouchersInput = {
  voucherIds: Array<Scalars['String']>;
};

export type DeleteCourseInput = {
  termId: Scalars['String'];
};

export type DeleteHolidayCourseInput = {
  courseId: Scalars['String'];
};

export type DeletePaymentMethodInput = {
  id: Scalars['String'];
};

export type DeleteTournamentInput = {
  id: Scalars['String'];
};

export type DisableTermInput = {
  termId: Scalars['String'];
};

export enum ECourseLevel {
  Private = 'Private',
  ELite = 'ELite',
  Beginner = 'Beginner',
  Intermediate1 = 'Intermediate1',
  Intermediate2 = 'Intermediate2',
  Intermediate3 = 'Intermediate3',
  Advanced1 = 'Advanced1',
  Advanced2 = 'Advanced2',
  Advanced3 = 'Advanced3',
  Nsgh = 'NSGH',
  Nsbh = 'NSBH',
  RosevilleCollege = 'RosevilleCollege'
}

export enum ECourseStatus {
  Inactive = 'inactive',
  Active = 'active'
}

export enum EEnrolmentType {
  Term = 'Term',
  Holiday = 'Holiday',
  Tournament = 'Tournament'
}

export enum EGender {
  Male = 'Male',
  Female = 'Female'
}

export enum EPaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Declined = 'Declined',
  Confirmed = 'Confirmed'
}

export enum ERole {
  Admin = 'Admin',
  Coach = 'Coach',
  Parent = 'Parent',
  Public = 'Public'
}

export enum EStatus {
  Inactive = 'inactive',
  Active = 'active'
}

export enum EVoucherStatus {
  Submitted = 'submitted',
  Claimed = 'claimed'
}

export enum EWeekDay {
  Sun = 'Sun',
  Mon = 'Mon',
  Tues = 'Tues',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat'
}

/** Enrolment */
export type Enrolment = {
  __typename?: 'Enrolment';
  id: Scalars['ObjectId'];
  type?: Maybe<Scalars['String']>;
  userId: Scalars['ObjectId'];
  courseIds: Array<Scalars['ObjectId']>;
  playerIds: Array<Scalars['ObjectId']>;
  enrolDate: Scalars['String'];
};

export type EnrolmentResult = {
  __typename?: 'EnrolmentResult';
  enrolment: Enrolment;
  payment: Payment;
  user: User;
};

export type GetActiveKidsVouchersInput = {
  ownerId: Scalars['String'];
};

/** Get courses response type */
export type GetCoursesResp = {
  __typename?: 'GetCoursesResp';
  term: Term;
  courses: Array<Course>;
};

export type GetEnrolmentsInput = {
  type?: Maybe<EEnrolmentType>;
  userId?: Maybe<Scalars['String']>;
  playerId?: Maybe<Scalars['String']>;
};

/** Get holiday courses response type */
export type GetHolidayCoursesResp = {
  __typename?: 'GetHolidayCoursesResp';
  courses: Array<HolidayCourse>;
};

export type GetPaymentInput = {
  enrolmentId: Scalars['String'];
};

/** get payment methods response type */
export type GetPaymentMethodsResp = {
  __typename?: 'GetPaymentMethodsResp';
  id: Scalars['String'];
  last4: Scalars['String'];
  exp_year: Scalars['Float'];
  exp_month: Scalars['Float'];
};

export type GetPaymentsInput = {
  userId?: Maybe<Scalars['String']>;
};

export type GetPlayersInput = {
  parentId?: Maybe<Scalars['String']>;
};

export type GetTournamentEnrolmentsInput = {
  userId?: Maybe<Scalars['String']>;
};

/** Get tournament enrolments response type */
export type GetTournamentEnrolmentsResp = {
  __typename?: 'GetTournamentEnrolmentsResp';
  id: Scalars['String'];
  userId: Scalars['String'];
  enrolDate: Scalars['String'];
  paymentCode: Scalars['String'];
  status: EPaymentStatus;
  tournaments: Array<Tournament>;
  tournamentIds?: Maybe<Array<Scalars['String']>>;
  players: Array<Player>;
};

/** Holiday Course */
export type HolidayCourse = {
  __typename?: 'HolidayCourse';
  id: Scalars['ObjectId'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  capacity: Scalars['Float'];
  availableCapacity: Scalars['Float'];
  price: Scalars['Float'];
  available: Scalars['Boolean'];
  status: ECourseStatus;
};

export type HolidayCourseDataInput = {
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  price: Scalars['Float'];
  capacity: Scalars['Float'];
};

/** Courses response type */
export type HolidayCoursesResp = {
  __typename?: 'HolidayCoursesResp';
  courses: Array<HolidayCourse>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addHolidayCourse: AddHolidayCourseResp;
  updateHolidayCourse: UpdateHolidayCourseResp;
  deleteHolidayCourse: Scalars['Boolean'];
  setHolidayCourseStatus?: Maybe<SetHolidayCourseStatusResp>;
  updatePaymentStatus: Payment;
  createPaymentCustomer: CreatePaymentCustomerResp;
  addPaymentMethod: AddPaymentMethodResp;
  deletePaymentMethod: Scalars['Boolean'];
  createPaymentIntent: CreatePaymendIntentResp;
  addPlayer: Player;
  savePlayer: Player;
  addTournament: Tournament;
  deleteTournament: Scalars['Boolean'];
  updateTournament: Tournament;
  addTournamentEnrolment: AddTournamentEnrolmentResp;
  changeTournamentStatus: Tournament;
  sendMessage: Scalars['Boolean'];
  addVoucher: VouchersResp;
  deleteVouchers: Scalars['Boolean'];
  updateVoucher: VouchersResp;
  changeVoucherStatus: VouchersResp;
  addCourse: AddCourseResp;
  updateCourse: UpdateCourseResp;
  deleteCourse: Scalars['Boolean'];
  disableTerm: Scalars['Boolean'];
  addEnrolment: EnrolmentResult;
  declineEnrolment: EnrolmentResult;
  saveUserProfile: User;
  updateUserProfile: User;
};


export type MutationAddHolidayCourseArgs = {
  param: AddHolidayCourseInput;
};


export type MutationUpdateHolidayCourseArgs = {
  param: UpdateHolidayCourseInput;
};


export type MutationDeleteHolidayCourseArgs = {
  param: DeleteHolidayCourseInput;
};


export type MutationSetHolidayCourseStatusArgs = {
  param: SetCourseStatusInput;
};


export type MutationUpdatePaymentStatusArgs = {
  param: UpdatePaymentStatusInput;
};


export type MutationDeletePaymentMethodArgs = {
  param: DeletePaymentMethodInput;
};


export type MutationCreatePaymentIntentArgs = {
  param: CreatePaymentIntentInput;
};


export type MutationAddPlayerArgs = {
  param: AddPlayerInput;
};


export type MutationSavePlayerArgs = {
  param: SavePlayerInput;
};


export type MutationAddTournamentArgs = {
  param: AddTournamentInput;
};


export type MutationDeleteTournamentArgs = {
  param: DeleteTournamentInput;
};


export type MutationUpdateTournamentArgs = {
  param: UpdateTournamentInput;
};


export type MutationAddTournamentEnrolmentArgs = {
  param: AddTournamentEnrolmentInput;
};


export type MutationChangeTournamentStatusArgs = {
  param: ChangeTournamentInput;
};


export type MutationSendMessageArgs = {
  param: SendMessageInput;
};


export type MutationAddVoucherArgs = {
  param: AddActiveKidsVouchersInput;
};


export type MutationDeleteVouchersArgs = {
  param: DeleteActiveKidsVouchersInput;
};


export type MutationUpdateVoucherArgs = {
  param: UpdateActiveKidsVouchersInput;
};


export type MutationChangeVoucherStatusArgs = {
  param: ChangeVoucherStatusInput;
};


export type MutationAddCourseArgs = {
  param: AddCourseInput;
};


export type MutationUpdateCourseArgs = {
  param: UpdateCourseInput;
};


export type MutationDeleteCourseArgs = {
  param: DeleteCourseInput;
};


export type MutationDisableTermArgs = {
  param: DisableTermInput;
};


export type MutationAddEnrolmentArgs = {
  param: AddEnrolmentInput;
};


export type MutationDeclineEnrolmentArgs = {
  param: DeclineEnrolmentInput;
};


export type MutationSaveUserProfileArgs = {
  param: SaveUserProfileInput;
};


export type MutationUpdateUserProfileArgs = {
  param: UpdateUserProfileInput;
};


/** Payment */
export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ObjectId'];
  status: EPaymentStatus;
  reference: Scalars['String'];
  amount: Scalars['String'];
  enrolmentId: Scalars['String'];
  createdDate: Scalars['String'];
  paidDate?: Maybe<Scalars['String']>;
  confirmedDate?: Maybe<Scalars['String']>;
  enrolmentType?: Maybe<Scalars['String']>;
};

/** PaymentInfo response type */
export type PaymentInfoResp = {
  __typename?: 'PaymentInfoResp';
  id: Scalars['ObjectId'];
  status: EPaymentStatus;
  reference: Scalars['String'];
  amount: Scalars['String'];
  enrolmentId: Scalars['String'];
  createdDate: Scalars['String'];
  paidDate?: Maybe<Scalars['String']>;
  confirmedDate?: Maybe<Scalars['String']>;
  enrolmentType?: Maybe<Scalars['String']>;
  enrolmentInfo: Enrolment;
  payerInfo: User;
  coursesInfo: Array<Course>;
  playersInfo: Array<Player>;
  termsInfo: Array<Term>;
};

/** Player Type */
export type Player = {
  __typename?: 'Player';
  id: Scalars['ObjectId'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  school: Scalars['String'];
  gender: EGender;
  dob: Scalars['String'];
  parentId: Scalars['String'];
  disability: Scalars['Boolean'];
  disabilityClass?: Maybe<Scalars['String']>;
  disabilityDescription?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getHolidayCourses: GetHolidayCoursesResp;
  getPayment: PaymentInfoResp;
  getPayments: Array<PaymentInfoResp>;
  getPaymentMethods: Array<GetPaymentMethodsResp>;
  getPlayers?: Maybe<Array<Player>>;
  getTournaments?: Maybe<Array<Tournament>>;
  getTournamentEnrolments?: Maybe<Array<GetTournamentEnrolmentsResp>>;
  getVouchers: Array<VouchersResp>;
  getCourses: Array<GetCoursesResp>;
  getEnrolments: Array<EnrolmentResult>;
  getUserProfile?: Maybe<User>;
};


export type QueryGetPaymentArgs = {
  param: GetPaymentInput;
};


export type QueryGetPaymentsArgs = {
  param: GetPaymentsInput;
};


export type QueryGetPlayersArgs = {
  param: GetPlayersInput;
};


export type QueryGetTournamentEnrolmentsArgs = {
  param: GetTournamentEnrolmentsInput;
};


export type QueryGetVouchersArgs = {
  param: GetActiveKidsVouchersInput;
};


export type QueryGetEnrolmentsArgs = {
  param: GetEnrolmentsInput;
};

export type SavePlayerInput = {
  playerId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  school: Scalars['String'];
  gender: EGender;
  dob: Scalars['String'];
  parentId: Scalars['String'];
  disability: Scalars['Boolean'];
  disabilityClass?: Maybe<Scalars['String']>;
  disabilityDescription?: Maybe<Scalars['String']>;
};

export type SaveUserProfileInput = {
  uid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: ERole;
  address: Scalars['String'];
  cityTown: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
};

export type SendMessageInput = {
  firstName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
};

export type SetCourseStatusInput = {
  courseId: Scalars['String'];
  status: Scalars['String'];
};

/** Update holiday course response type */
export type SetHolidayCourseStatusResp = {
  __typename?: 'SetHolidayCourseStatusResp';
  course: HolidayCourse;
};

/** Term */
export type Term = {
  __typename?: 'Term';
  id: Scalars['ObjectId'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  level: Scalars['String'];
};

/** Touraments */
export type Tournament = {
  __typename?: 'Tournament';
  id: Scalars['ObjectId'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  price: Scalars['Float'];
  status: EStatus;
};

/** Tournament Enrolment */
export type TournamentEnrolment = {
  __typename?: 'TournamentEnrolment';
  id: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
  tournamentId?: Maybe<Scalars['ObjectId']>;
  tournamentIds: Array<Scalars['ObjectId']>;
  playerIds: Array<Scalars['ObjectId']>;
  enrolDate: Scalars['String'];
  paymentCode: Scalars['String'];
  status: EPaymentStatus;
  paymentId: Scalars['String'];
  paymentMethod: Scalars['String'];
  paymentStatus: Scalars['String'];
  amount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type UpdateActiveKidsVouchersInput = {
  voucherId: Scalars['String'];
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  voucherNumber: Scalars['String'];
  dob: Scalars['String'];
  expiryDate: Scalars['String'];
};

export type UpdateCourseInput = {
  termId: Scalars['String'];
  termDescription: Scalars['String'];
  termStartDate: Scalars['String'];
  termEndDate: Scalars['String'];
  courseLevel: ECourseLevel;
  courseData: Array<CourseDataInput>;
};

/** Update course response type */
export type UpdateCourseResp = {
  __typename?: 'UpdateCourseResp';
  term: Term;
  courses: Array<Course>;
};

export type UpdateHolidayCourseInput = {
  courseId: Scalars['String'];
  courseData: HolidayCourseDataInput;
};

/** Update holiday course response type */
export type UpdateHolidayCourseResp = {
  __typename?: 'UpdateHolidayCourseResp';
  courses: Array<HolidayCourse>;
};

export type UpdatePaymentStatusInput = {
  paymentId: Scalars['String'];
  status: EPaymentStatus;
};

export type UpdateTournamentInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  price: Scalars['Float'];
};

export type UpdateUserProfileInput = {
  id: Scalars['String'];
  userProfile: SaveUserProfileInput;
};

/** User Type */
export type User = {
  __typename?: 'User';
  id: Scalars['ObjectId'];
  uid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: ERole;
  address: Scalars['String'];
  cityTown: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  paymentCustomerId?: Maybe<Scalars['String']>;
};

/** Vouchers response type */
export type VouchersResp = {
  __typename?: 'VouchersResp';
  id: Scalars['String'];
  status: EVoucherStatus;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  ownerId: Scalars['String'];
  createdTime: Scalars['String'];
  claimedTime?: Maybe<Scalars['String']>;
  voucherNumber: Scalars['String'];
  dob: Scalars['String'];
  expiryDate: Scalars['String'];
};

export type GetVouchersQueryVariables = Exact<{
  param: GetActiveKidsVouchersInput;
}>;


export type GetVouchersQuery = (
  { __typename?: 'Query' }
  & { getVouchers: Array<(
    { __typename?: 'VouchersResp' }
    & ActiveKidsVouchersFragmentFragment
  )> }
);

export type AddVoucherMutationVariables = Exact<{
  param: AddActiveKidsVouchersInput;
}>;


export type AddVoucherMutation = (
  { __typename?: 'Mutation' }
  & { addVoucher: (
    { __typename?: 'VouchersResp' }
    & ActiveKidsVouchersFragmentFragment
  ) }
);

export type DeleteVouchersMutationVariables = Exact<{
  param: DeleteActiveKidsVouchersInput;
}>;


export type DeleteVouchersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteVouchers'>
);

export type UpdateVoucherMutationVariables = Exact<{
  param: UpdateActiveKidsVouchersInput;
}>;


export type UpdateVoucherMutation = (
  { __typename?: 'Mutation' }
  & { updateVoucher: (
    { __typename?: 'VouchersResp' }
    & ActiveKidsVouchersFragmentFragment
  ) }
);

export type ChangeVoucherStatusMutationVariables = Exact<{
  param: ChangeVoucherStatusInput;
}>;


export type ChangeVoucherStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeVoucherStatus: (
    { __typename?: 'VouchersResp' }
    & ActiveKidsVouchersFragmentFragment
  ) }
);

export type GetCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: Array<(
    { __typename?: 'GetCoursesResp' }
    & { term: (
      { __typename?: 'Term' }
      & TermFragmentFragment
    ), courses: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )> }
  )> }
);

export type AddCourseMutationVariables = Exact<{
  param: AddCourseInput;
}>;


export type AddCourseMutation = (
  { __typename?: 'Mutation' }
  & { addCourse: (
    { __typename?: 'AddCourseResp' }
    & { term: (
      { __typename?: 'Term' }
      & TermFragmentFragment
    ), courses: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )> }
  ) }
);

export type UpdateCourseMutationVariables = Exact<{
  param: UpdateCourseInput;
}>;


export type UpdateCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateCourse: (
    { __typename?: 'UpdateCourseResp' }
    & { term: (
      { __typename?: 'Term' }
      & TermFragmentFragment
    ), courses: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )> }
  ) }
);

export type DeleteCourseMutationVariables = Exact<{
  param: DeleteCourseInput;
}>;


export type DeleteCourseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCourse'>
);

export type DisableTermMutationVariables = Exact<{
  param: DisableTermInput;
}>;


export type DisableTermMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableTerm'>
);

export type AddEnrolmentMutationVariables = Exact<{
  param: AddEnrolmentInput;
}>;


export type AddEnrolmentMutation = (
  { __typename?: 'Mutation' }
  & { addEnrolment: (
    { __typename?: 'EnrolmentResult' }
    & { enrolment: (
      { __typename?: 'Enrolment' }
      & EnrolmentFragmentFragment
    ), payment: (
      { __typename?: 'Payment' }
      & PaymentFragmentFragment
    ), user: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ) }
  ) }
);

export type DeclineEnrolmentMutationVariables = Exact<{
  param: DeclineEnrolmentInput;
}>;


export type DeclineEnrolmentMutation = (
  { __typename?: 'Mutation' }
  & { declineEnrolment: (
    { __typename?: 'EnrolmentResult' }
    & { enrolment: (
      { __typename?: 'Enrolment' }
      & EnrolmentFragmentFragment
    ) }
  ) }
);

export type GetEnrolmentsQueryVariables = Exact<{
  param: GetEnrolmentsInput;
}>;


export type GetEnrolmentsQuery = (
  { __typename?: 'Query' }
  & { getEnrolments: Array<(
    { __typename?: 'EnrolmentResult' }
    & { enrolment: (
      { __typename?: 'Enrolment' }
      & EnrolmentFragmentFragment
    ), payment: (
      { __typename?: 'Payment' }
      & PaymentFragmentFragment
    ), user: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ) }
  )> }
);

export type PlayerFragmentFragment = (
  { __typename?: 'Player' }
  & Pick<Player, 'id' | 'firstName' | 'lastName' | 'gender' | 'dob' | 'school' | 'disability' | 'disabilityClass' | 'disabilityDescription' | 'parentId'>
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'uid' | 'firstName' | 'lastName' | 'role' | 'address' | 'cityTown' | 'phone' | 'email' | 'paymentCustomerId'>
);

export type TermFragmentFragment = (
  { __typename?: 'Term' }
  & Pick<Term, 'id' | 'description' | 'startDate' | 'endDate' | 'level'>
);

export type CourseFragmentFragment = (
  { __typename?: 'Course' }
  & Pick<Course, 'id' | 'termId' | 'courseDay' | 'numOfLessons' | 'unitPrice' | 'capacity' | 'availableCapacity' | 'startTime' | 'endTime' | 'available' | 'status' | 'numOfPublicHolidays'>
);

export type EnrolmentFragmentFragment = (
  { __typename?: 'Enrolment' }
  & Pick<Enrolment, 'id' | 'type' | 'userId' | 'playerIds' | 'courseIds' | 'enrolDate'>
);

export type PaymentFragmentFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'status' | 'enrolmentId' | 'reference' | 'createdDate' | 'paidDate' | 'amount'>
);

export type PaymentInfoFragmentFragment = (
  { __typename?: 'PaymentInfoResp' }
  & Pick<PaymentInfoResp, 'id' | 'status' | 'enrolmentId' | 'reference' | 'createdDate' | 'paidDate' | 'amount'>
);

export type HolidayCourseFragmentFragment = (
  { __typename?: 'HolidayCourse' }
  & Pick<HolidayCourse, 'id' | 'description' | 'startDate' | 'endDate' | 'startTime' | 'endTime' | 'capacity' | 'availableCapacity' | 'price' | 'available' | 'status'>
);

export type ActiveKidsVouchersFragmentFragment = (
  { __typename?: 'VouchersResp' }
  & Pick<VouchersResp, 'id' | 'ownerId' | 'firstName' | 'middleName' | 'lastName' | 'dob' | 'expiryDate' | 'voucherNumber' | 'createdTime' | 'claimedTime' | 'status'>
);

export type TournamentFragmentFragment = (
  { __typename?: 'Tournament' }
  & Pick<Tournament, 'id' | 'name' | 'startDate' | 'price' | 'status'>
);

export type TournamentEnrolmentFragmentFragment = (
  { __typename?: 'TournamentEnrolment' }
  & Pick<TournamentEnrolment, 'enrolDate' | 'tournamentIds'>
);

export type GetTournamentEnrolmentFragmentFragment = (
  { __typename?: 'GetTournamentEnrolmentsResp' }
  & Pick<GetTournamentEnrolmentsResp, 'enrolDate' | 'status' | 'tournamentIds'>
  & { tournaments: Array<(
    { __typename?: 'Tournament' }
    & Pick<Tournament, 'name' | 'startDate' | 'price'>
  )>, players: Array<(
    { __typename?: 'Player' }
    & Pick<Player, 'firstName' | 'lastName'>
  )> }
);

export type AddHolidayCourseMutationVariables = Exact<{
  param: AddHolidayCourseInput;
}>;


export type AddHolidayCourseMutation = (
  { __typename?: 'Mutation' }
  & { addHolidayCourse: (
    { __typename?: 'AddHolidayCourseResp' }
    & { courses: Array<(
      { __typename?: 'HolidayCourse' }
      & HolidayCourseFragmentFragment
    )> }
  ) }
);

export type GetHolidayCourseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHolidayCourseQuery = (
  { __typename?: 'Query' }
  & { getHolidayCourses: (
    { __typename?: 'GetHolidayCoursesResp' }
    & { courses: Array<(
      { __typename?: 'HolidayCourse' }
      & HolidayCourseFragmentFragment
    )> }
  ) }
);

export type DeleteHolidayCourseMutationVariables = Exact<{
  param: DeleteHolidayCourseInput;
}>;


export type DeleteHolidayCourseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteHolidayCourse'>
);

export type UpdateHolidayCourseMutationVariables = Exact<{
  param: UpdateHolidayCourseInput;
}>;


export type UpdateHolidayCourseMutation = (
  { __typename?: 'Mutation' }
  & { updateHolidayCourse: (
    { __typename?: 'UpdateHolidayCourseResp' }
    & { courses: Array<(
      { __typename?: 'HolidayCourse' }
      & HolidayCourseFragmentFragment
    )> }
  ) }
);

export type SetHolidayCourseStatusMutationVariables = Exact<{
  param: SetCourseStatusInput;
}>;


export type SetHolidayCourseStatusMutation = (
  { __typename?: 'Mutation' }
  & { setHolidayCourseStatus?: Maybe<(
    { __typename?: 'SetHolidayCourseStatusResp' }
    & { course: (
      { __typename?: 'HolidayCourse' }
      & HolidayCourseFragmentFragment
    ) }
  )> }
);

export type GetPaymentsQueryVariables = Exact<{
  param: GetPaymentsInput;
}>;


export type GetPaymentsQuery = (
  { __typename?: 'Query' }
  & { getPayments: Array<(
    { __typename?: 'PaymentInfoResp' }
    & { enrolmentInfo: (
      { __typename?: 'Enrolment' }
      & EnrolmentFragmentFragment
    ), payerInfo: (
      { __typename?: 'User' }
      & UserFragmentFragment
    ), coursesInfo: Array<(
      { __typename?: 'Course' }
      & CourseFragmentFragment
    )>, playersInfo: Array<(
      { __typename?: 'Player' }
      & PlayerFragmentFragment
    )>, termsInfo: Array<(
      { __typename?: 'Term' }
      & TermFragmentFragment
    )> }
    & PaymentInfoFragmentFragment
  )> }
);

export type UpdatePaymentStatusMutationVariables = Exact<{
  param: UpdatePaymentStatusInput;
}>;


export type UpdatePaymentStatusMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentStatus: (
    { __typename?: 'Payment' }
    & PaymentFragmentFragment
  ) }
);

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { getPaymentMethods: Array<(
    { __typename?: 'GetPaymentMethodsResp' }
    & Pick<GetPaymentMethodsResp, 'id' | 'last4' | 'exp_year' | 'exp_month'>
  )> }
);

export type AddPaymentMethodMutationVariables = Exact<{ [key: string]: never; }>;


export type AddPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { addPaymentMethod: (
    { __typename?: 'AddPaymentMethodResp' }
    & Pick<AddPaymentMethodResp, 'clientSecret'>
  ) }
);

export type DeletePaymentMethodMutationVariables = Exact<{
  param: DeletePaymentMethodInput;
}>;


export type DeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePaymentMethod'>
);

export type CreatePaymentIntentMutationVariables = Exact<{
  param: CreatePaymentIntentInput;
}>;


export type CreatePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentIntent: (
    { __typename?: 'CreatePaymendIntentResp' }
    & Pick<CreatePaymendIntentResp, 'paymentMethod' | 'paymentId'>
  ) }
);

export type GetPlayersQueryVariables = Exact<{
  param: GetPlayersInput;
}>;


export type GetPlayersQuery = (
  { __typename?: 'Query' }
  & { getPlayers?: Maybe<Array<(
    { __typename?: 'Player' }
    & PlayerFragmentFragment
  )>> }
);

export type AddPlayerMutationVariables = Exact<{
  param: AddPlayerInput;
}>;


export type AddPlayerMutation = (
  { __typename?: 'Mutation' }
  & { addPlayer: (
    { __typename?: 'Player' }
    & PlayerFragmentFragment
  ) }
);

export type SavePlayerMutationVariables = Exact<{
  param: SavePlayerInput;
}>;


export type SavePlayerMutation = (
  { __typename?: 'Mutation' }
  & { savePlayer: (
    { __typename?: 'Player' }
    & PlayerFragmentFragment
  ) }
);

export type GetTournamentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTournamentsQuery = (
  { __typename?: 'Query' }
  & { getTournaments?: Maybe<Array<(
    { __typename?: 'Tournament' }
    & TournamentFragmentFragment
  )>> }
);

export type AddTournamentMutationVariables = Exact<{
  param: AddTournamentInput;
}>;


export type AddTournamentMutation = (
  { __typename?: 'Mutation' }
  & { addTournament: (
    { __typename?: 'Tournament' }
    & TournamentFragmentFragment
  ) }
);

export type UpdateTournamentMutationVariables = Exact<{
  param: UpdateTournamentInput;
}>;


export type UpdateTournamentMutation = (
  { __typename?: 'Mutation' }
  & { updateTournament: (
    { __typename?: 'Tournament' }
    & TournamentFragmentFragment
  ) }
);

export type DeleteTournamentMutationVariables = Exact<{
  param: DeleteTournamentInput;
}>;


export type DeleteTournamentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTournament'>
);

export type ChangeTournamentStatusMutationVariables = Exact<{
  param: ChangeTournamentInput;
}>;


export type ChangeTournamentStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeTournamentStatus: (
    { __typename?: 'Tournament' }
    & TournamentFragmentFragment
  ) }
);

export type AddTournamentEnrolmentMutationVariables = Exact<{
  param: AddTournamentEnrolmentInput;
}>;


export type AddTournamentEnrolmentMutation = (
  { __typename?: 'Mutation' }
  & { addTournamentEnrolment: (
    { __typename?: 'AddTournamentEnrolmentResp' }
    & { enrolment: (
      { __typename?: 'TournamentEnrolment' }
      & TournamentEnrolmentFragmentFragment
    ), payment: (
      { __typename?: 'Payment' }
      & PaymentFragmentFragment
    ) }
  ) }
);

export type GetTournamentEnrolmentsQueryVariables = Exact<{
  param: GetTournamentEnrolmentsInput;
}>;


export type GetTournamentEnrolmentsQuery = (
  { __typename?: 'Query' }
  & { getTournamentEnrolments?: Maybe<Array<(
    { __typename?: 'GetTournamentEnrolmentsResp' }
    & GetTournamentEnrolmentFragmentFragment
  )>> }
);

export type SaveUserProfileMutationVariables = Exact<{
  param: SaveUserProfileInput;
}>;


export type SaveUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { saveUserProfile: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

export type UpdateUserProfileMutationVariables = Exact<{
  param: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'User' }
    & UserFragmentFragment
  ) }
);

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { getUserProfile?: Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> }
);

export type SendMessageMutationVariables = Exact<{
  param: SendMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMessage'>
);

export const PlayerFragmentFragmentDoc = gql`
    fragment PlayerFragment on Player {
  id
  firstName
  lastName
  gender
  dob
  school
  disability
  disabilityClass
  disabilityDescription
  parentId
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  uid
  firstName
  lastName
  role
  address
  cityTown
  phone
  email
  paymentCustomerId
}
    `;
export const TermFragmentFragmentDoc = gql`
    fragment TermFragment on Term {
  id
  description
  startDate
  endDate
  level
}
    `;
export const CourseFragmentFragmentDoc = gql`
    fragment CourseFragment on Course {
  id
  termId
  courseDay
  numOfLessons
  unitPrice
  capacity
  availableCapacity
  startTime
  endTime
  available
  status
  numOfPublicHolidays
}
    `;
export const EnrolmentFragmentFragmentDoc = gql`
    fragment EnrolmentFragment on Enrolment {
  id
  type
  userId
  playerIds
  courseIds
  enrolDate
}
    `;
export const PaymentFragmentFragmentDoc = gql`
    fragment PaymentFragment on Payment {
  id
  status
  enrolmentId
  reference
  createdDate
  paidDate
  amount
}
    `;
export const PaymentInfoFragmentFragmentDoc = gql`
    fragment PaymentInfoFragment on PaymentInfoResp {
  id
  status
  enrolmentId
  reference
  createdDate
  paidDate
  amount
}
    `;
export const HolidayCourseFragmentFragmentDoc = gql`
    fragment HolidayCourseFragment on HolidayCourse {
  id
  description
  startDate
  endDate
  startTime
  endTime
  capacity
  availableCapacity
  price
  available
  status
}
    `;
export const ActiveKidsVouchersFragmentFragmentDoc = gql`
    fragment ActiveKidsVouchersFragment on VouchersResp {
  id
  ownerId
  firstName
  middleName
  lastName
  dob
  expiryDate
  voucherNumber
  createdTime
  claimedTime
  status
}
    `;
export const TournamentFragmentFragmentDoc = gql`
    fragment TournamentFragment on Tournament {
  id
  name
  startDate
  price
  status
}
    `;
export const TournamentEnrolmentFragmentFragmentDoc = gql`
    fragment TournamentEnrolmentFragment on TournamentEnrolment {
  enrolDate
  tournamentIds
}
    `;
export const GetTournamentEnrolmentFragmentFragmentDoc = gql`
    fragment GetTournamentEnrolmentFragment on GetTournamentEnrolmentsResp {
  enrolDate
  tournaments {
    name
    startDate
    price
  }
  status
  tournamentIds
  players {
    firstName
    lastName
  }
}
    `;
export const GetVouchersDocument = gql`
    query getVouchers($param: GetActiveKidsVouchersInput!) {
  getVouchers(param: $param) {
    ...ActiveKidsVouchersFragment
  }
}
    ${ActiveKidsVouchersFragmentFragmentDoc}`;

/**
 * __useGetVouchersQuery__
 *
 * To run a query within a React component, call `useGetVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVouchersQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useGetVouchersQuery(baseOptions: Apollo.QueryHookOptions<GetVouchersQuery, GetVouchersQueryVariables>) {
        return Apollo.useQuery<GetVouchersQuery, GetVouchersQueryVariables>(GetVouchersDocument, baseOptions);
      }
export function useGetVouchersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVouchersQuery, GetVouchersQueryVariables>) {
          return Apollo.useLazyQuery<GetVouchersQuery, GetVouchersQueryVariables>(GetVouchersDocument, baseOptions);
        }
export type GetVouchersQueryHookResult = ReturnType<typeof useGetVouchersQuery>;
export type GetVouchersLazyQueryHookResult = ReturnType<typeof useGetVouchersLazyQuery>;
export type GetVouchersQueryResult = Apollo.QueryResult<GetVouchersQuery, GetVouchersQueryVariables>;
export const AddVoucherDocument = gql`
    mutation addVoucher($param: AddActiveKidsVouchersInput!) {
  addVoucher(param: $param) {
    ...ActiveKidsVouchersFragment
  }
}
    ${ActiveKidsVouchersFragmentFragmentDoc}`;
export type AddVoucherMutationFn = Apollo.MutationFunction<AddVoucherMutation, AddVoucherMutationVariables>;

/**
 * __useAddVoucherMutation__
 *
 * To run a mutation, you first call `useAddVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoucherMutation, { data, loading, error }] = useAddVoucherMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddVoucherMutation(baseOptions?: Apollo.MutationHookOptions<AddVoucherMutation, AddVoucherMutationVariables>) {
        return Apollo.useMutation<AddVoucherMutation, AddVoucherMutationVariables>(AddVoucherDocument, baseOptions);
      }
export type AddVoucherMutationHookResult = ReturnType<typeof useAddVoucherMutation>;
export type AddVoucherMutationResult = Apollo.MutationResult<AddVoucherMutation>;
export type AddVoucherMutationOptions = Apollo.BaseMutationOptions<AddVoucherMutation, AddVoucherMutationVariables>;
export const DeleteVouchersDocument = gql`
    mutation deleteVouchers($param: DeleteActiveKidsVouchersInput!) {
  deleteVouchers(param: $param)
}
    `;
export type DeleteVouchersMutationFn = Apollo.MutationFunction<DeleteVouchersMutation, DeleteVouchersMutationVariables>;

/**
 * __useDeleteVouchersMutation__
 *
 * To run a mutation, you first call `useDeleteVouchersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVouchersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVouchersMutation, { data, loading, error }] = useDeleteVouchersMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeleteVouchersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVouchersMutation, DeleteVouchersMutationVariables>) {
        return Apollo.useMutation<DeleteVouchersMutation, DeleteVouchersMutationVariables>(DeleteVouchersDocument, baseOptions);
      }
export type DeleteVouchersMutationHookResult = ReturnType<typeof useDeleteVouchersMutation>;
export type DeleteVouchersMutationResult = Apollo.MutationResult<DeleteVouchersMutation>;
export type DeleteVouchersMutationOptions = Apollo.BaseMutationOptions<DeleteVouchersMutation, DeleteVouchersMutationVariables>;
export const UpdateVoucherDocument = gql`
    mutation updateVoucher($param: UpdateActiveKidsVouchersInput!) {
  updateVoucher(param: $param) {
    ...ActiveKidsVouchersFragment
  }
}
    ${ActiveKidsVouchersFragmentFragmentDoc}`;
export type UpdateVoucherMutationFn = Apollo.MutationFunction<UpdateVoucherMutation, UpdateVoucherMutationVariables>;

/**
 * __useUpdateVoucherMutation__
 *
 * To run a mutation, you first call `useUpdateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoucherMutation, { data, loading, error }] = useUpdateVoucherMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdateVoucherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>) {
        return Apollo.useMutation<UpdateVoucherMutation, UpdateVoucherMutationVariables>(UpdateVoucherDocument, baseOptions);
      }
export type UpdateVoucherMutationHookResult = ReturnType<typeof useUpdateVoucherMutation>;
export type UpdateVoucherMutationResult = Apollo.MutationResult<UpdateVoucherMutation>;
export type UpdateVoucherMutationOptions = Apollo.BaseMutationOptions<UpdateVoucherMutation, UpdateVoucherMutationVariables>;
export const ChangeVoucherStatusDocument = gql`
    mutation changeVoucherStatus($param: ChangeVoucherStatusInput!) {
  changeVoucherStatus(param: $param) {
    ...ActiveKidsVouchersFragment
  }
}
    ${ActiveKidsVouchersFragmentFragmentDoc}`;
export type ChangeVoucherStatusMutationFn = Apollo.MutationFunction<ChangeVoucherStatusMutation, ChangeVoucherStatusMutationVariables>;

/**
 * __useChangeVoucherStatusMutation__
 *
 * To run a mutation, you first call `useChangeVoucherStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVoucherStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVoucherStatusMutation, { data, loading, error }] = useChangeVoucherStatusMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useChangeVoucherStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVoucherStatusMutation, ChangeVoucherStatusMutationVariables>) {
        return Apollo.useMutation<ChangeVoucherStatusMutation, ChangeVoucherStatusMutationVariables>(ChangeVoucherStatusDocument, baseOptions);
      }
export type ChangeVoucherStatusMutationHookResult = ReturnType<typeof useChangeVoucherStatusMutation>;
export type ChangeVoucherStatusMutationResult = Apollo.MutationResult<ChangeVoucherStatusMutation>;
export type ChangeVoucherStatusMutationOptions = Apollo.BaseMutationOptions<ChangeVoucherStatusMutation, ChangeVoucherStatusMutationVariables>;
export const GetCoursesDocument = gql`
    query getCourses {
  getCourses {
    term {
      ...TermFragment
    }
    courses {
      ...CourseFragment
    }
  }
}
    ${TermFragmentFragmentDoc}
${CourseFragmentFragmentDoc}`;

/**
 * __useGetCoursesQuery__
 *
 * To run a query within a React component, call `useGetCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
        return Apollo.useQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
      }
export function useGetCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesQuery, GetCoursesQueryVariables>) {
          return Apollo.useLazyQuery<GetCoursesQuery, GetCoursesQueryVariables>(GetCoursesDocument, baseOptions);
        }
export type GetCoursesQueryHookResult = ReturnType<typeof useGetCoursesQuery>;
export type GetCoursesLazyQueryHookResult = ReturnType<typeof useGetCoursesLazyQuery>;
export type GetCoursesQueryResult = Apollo.QueryResult<GetCoursesQuery, GetCoursesQueryVariables>;
export const AddCourseDocument = gql`
    mutation addCourse($param: AddCourseInput!) {
  addCourse(param: $param) {
    term {
      ...TermFragment
    }
    courses {
      ...CourseFragment
    }
  }
}
    ${TermFragmentFragmentDoc}
${CourseFragmentFragmentDoc}`;
export type AddCourseMutationFn = Apollo.MutationFunction<AddCourseMutation, AddCourseMutationVariables>;

/**
 * __useAddCourseMutation__
 *
 * To run a mutation, you first call `useAddCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCourseMutation, { data, loading, error }] = useAddCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddCourseMutation(baseOptions?: Apollo.MutationHookOptions<AddCourseMutation, AddCourseMutationVariables>) {
        return Apollo.useMutation<AddCourseMutation, AddCourseMutationVariables>(AddCourseDocument, baseOptions);
      }
export type AddCourseMutationHookResult = ReturnType<typeof useAddCourseMutation>;
export type AddCourseMutationResult = Apollo.MutationResult<AddCourseMutation>;
export type AddCourseMutationOptions = Apollo.BaseMutationOptions<AddCourseMutation, AddCourseMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation updateCourse($param: UpdateCourseInput!) {
  updateCourse(param: $param) {
    term {
      ...TermFragment
    }
    courses {
      ...CourseFragment
    }
  }
}
    ${TermFragmentFragmentDoc}
${CourseFragmentFragmentDoc}`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, baseOptions);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const DeleteCourseDocument = gql`
    mutation deleteCourse($param: DeleteCourseInput!) {
  deleteCourse(param: $param)
}
    `;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
        return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, baseOptions);
      }
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>;
export const DisableTermDocument = gql`
    mutation disableTerm($param: DisableTermInput!) {
  disableTerm(param: $param)
}
    `;
export type DisableTermMutationFn = Apollo.MutationFunction<DisableTermMutation, DisableTermMutationVariables>;

/**
 * __useDisableTermMutation__
 *
 * To run a mutation, you first call `useDisableTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTermMutation, { data, loading, error }] = useDisableTermMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDisableTermMutation(baseOptions?: Apollo.MutationHookOptions<DisableTermMutation, DisableTermMutationVariables>) {
        return Apollo.useMutation<DisableTermMutation, DisableTermMutationVariables>(DisableTermDocument, baseOptions);
      }
export type DisableTermMutationHookResult = ReturnType<typeof useDisableTermMutation>;
export type DisableTermMutationResult = Apollo.MutationResult<DisableTermMutation>;
export type DisableTermMutationOptions = Apollo.BaseMutationOptions<DisableTermMutation, DisableTermMutationVariables>;
export const AddEnrolmentDocument = gql`
    mutation addEnrolment($param: AddEnrolmentInput!) {
  addEnrolment(param: $param) {
    enrolment {
      ...EnrolmentFragment
    }
    payment {
      ...PaymentFragment
    }
    user {
      ...UserFragment
    }
  }
}
    ${EnrolmentFragmentFragmentDoc}
${PaymentFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export type AddEnrolmentMutationFn = Apollo.MutationFunction<AddEnrolmentMutation, AddEnrolmentMutationVariables>;

/**
 * __useAddEnrolmentMutation__
 *
 * To run a mutation, you first call `useAddEnrolmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnrolmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnrolmentMutation, { data, loading, error }] = useAddEnrolmentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddEnrolmentMutation(baseOptions?: Apollo.MutationHookOptions<AddEnrolmentMutation, AddEnrolmentMutationVariables>) {
        return Apollo.useMutation<AddEnrolmentMutation, AddEnrolmentMutationVariables>(AddEnrolmentDocument, baseOptions);
      }
export type AddEnrolmentMutationHookResult = ReturnType<typeof useAddEnrolmentMutation>;
export type AddEnrolmentMutationResult = Apollo.MutationResult<AddEnrolmentMutation>;
export type AddEnrolmentMutationOptions = Apollo.BaseMutationOptions<AddEnrolmentMutation, AddEnrolmentMutationVariables>;
export const DeclineEnrolmentDocument = gql`
    mutation declineEnrolment($param: DeclineEnrolmentInput!) {
  declineEnrolment(param: $param) {
    enrolment {
      ...EnrolmentFragment
    }
  }
}
    ${EnrolmentFragmentFragmentDoc}`;
export type DeclineEnrolmentMutationFn = Apollo.MutationFunction<DeclineEnrolmentMutation, DeclineEnrolmentMutationVariables>;

/**
 * __useDeclineEnrolmentMutation__
 *
 * To run a mutation, you first call `useDeclineEnrolmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineEnrolmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineEnrolmentMutation, { data, loading, error }] = useDeclineEnrolmentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeclineEnrolmentMutation(baseOptions?: Apollo.MutationHookOptions<DeclineEnrolmentMutation, DeclineEnrolmentMutationVariables>) {
        return Apollo.useMutation<DeclineEnrolmentMutation, DeclineEnrolmentMutationVariables>(DeclineEnrolmentDocument, baseOptions);
      }
export type DeclineEnrolmentMutationHookResult = ReturnType<typeof useDeclineEnrolmentMutation>;
export type DeclineEnrolmentMutationResult = Apollo.MutationResult<DeclineEnrolmentMutation>;
export type DeclineEnrolmentMutationOptions = Apollo.BaseMutationOptions<DeclineEnrolmentMutation, DeclineEnrolmentMutationVariables>;
export const GetEnrolmentsDocument = gql`
    query getEnrolments($param: GetEnrolmentsInput!) {
  getEnrolments(param: $param) {
    enrolment {
      ...EnrolmentFragment
    }
    payment {
      ...PaymentFragment
    }
    user {
      ...UserFragment
    }
  }
}
    ${EnrolmentFragmentFragmentDoc}
${PaymentFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;

/**
 * __useGetEnrolmentsQuery__
 *
 * To run a query within a React component, call `useGetEnrolmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrolmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrolmentsQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useGetEnrolmentsQuery(baseOptions: Apollo.QueryHookOptions<GetEnrolmentsQuery, GetEnrolmentsQueryVariables>) {
        return Apollo.useQuery<GetEnrolmentsQuery, GetEnrolmentsQueryVariables>(GetEnrolmentsDocument, baseOptions);
      }
export function useGetEnrolmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnrolmentsQuery, GetEnrolmentsQueryVariables>) {
          return Apollo.useLazyQuery<GetEnrolmentsQuery, GetEnrolmentsQueryVariables>(GetEnrolmentsDocument, baseOptions);
        }
export type GetEnrolmentsQueryHookResult = ReturnType<typeof useGetEnrolmentsQuery>;
export type GetEnrolmentsLazyQueryHookResult = ReturnType<typeof useGetEnrolmentsLazyQuery>;
export type GetEnrolmentsQueryResult = Apollo.QueryResult<GetEnrolmentsQuery, GetEnrolmentsQueryVariables>;
export const AddHolidayCourseDocument = gql`
    mutation addHolidayCourse($param: AddHolidayCourseInput!) {
  addHolidayCourse(param: $param) {
    courses {
      ...HolidayCourseFragment
    }
  }
}
    ${HolidayCourseFragmentFragmentDoc}`;
export type AddHolidayCourseMutationFn = Apollo.MutationFunction<AddHolidayCourseMutation, AddHolidayCourseMutationVariables>;

/**
 * __useAddHolidayCourseMutation__
 *
 * To run a mutation, you first call `useAddHolidayCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHolidayCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHolidayCourseMutation, { data, loading, error }] = useAddHolidayCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddHolidayCourseMutation(baseOptions?: Apollo.MutationHookOptions<AddHolidayCourseMutation, AddHolidayCourseMutationVariables>) {
        return Apollo.useMutation<AddHolidayCourseMutation, AddHolidayCourseMutationVariables>(AddHolidayCourseDocument, baseOptions);
      }
export type AddHolidayCourseMutationHookResult = ReturnType<typeof useAddHolidayCourseMutation>;
export type AddHolidayCourseMutationResult = Apollo.MutationResult<AddHolidayCourseMutation>;
export type AddHolidayCourseMutationOptions = Apollo.BaseMutationOptions<AddHolidayCourseMutation, AddHolidayCourseMutationVariables>;
export const GetHolidayCourseDocument = gql`
    query getHolidayCourse {
  getHolidayCourses {
    courses {
      ...HolidayCourseFragment
    }
  }
}
    ${HolidayCourseFragmentFragmentDoc}`;

/**
 * __useGetHolidayCourseQuery__
 *
 * To run a query within a React component, call `useGetHolidayCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidayCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidayCourseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHolidayCourseQuery(baseOptions?: Apollo.QueryHookOptions<GetHolidayCourseQuery, GetHolidayCourseQueryVariables>) {
        return Apollo.useQuery<GetHolidayCourseQuery, GetHolidayCourseQueryVariables>(GetHolidayCourseDocument, baseOptions);
      }
export function useGetHolidayCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHolidayCourseQuery, GetHolidayCourseQueryVariables>) {
          return Apollo.useLazyQuery<GetHolidayCourseQuery, GetHolidayCourseQueryVariables>(GetHolidayCourseDocument, baseOptions);
        }
export type GetHolidayCourseQueryHookResult = ReturnType<typeof useGetHolidayCourseQuery>;
export type GetHolidayCourseLazyQueryHookResult = ReturnType<typeof useGetHolidayCourseLazyQuery>;
export type GetHolidayCourseQueryResult = Apollo.QueryResult<GetHolidayCourseQuery, GetHolidayCourseQueryVariables>;
export const DeleteHolidayCourseDocument = gql`
    mutation deleteHolidayCourse($param: DeleteHolidayCourseInput!) {
  deleteHolidayCourse(param: $param)
}
    `;
export type DeleteHolidayCourseMutationFn = Apollo.MutationFunction<DeleteHolidayCourseMutation, DeleteHolidayCourseMutationVariables>;

/**
 * __useDeleteHolidayCourseMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayCourseMutation, { data, loading, error }] = useDeleteHolidayCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeleteHolidayCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHolidayCourseMutation, DeleteHolidayCourseMutationVariables>) {
        return Apollo.useMutation<DeleteHolidayCourseMutation, DeleteHolidayCourseMutationVariables>(DeleteHolidayCourseDocument, baseOptions);
      }
export type DeleteHolidayCourseMutationHookResult = ReturnType<typeof useDeleteHolidayCourseMutation>;
export type DeleteHolidayCourseMutationResult = Apollo.MutationResult<DeleteHolidayCourseMutation>;
export type DeleteHolidayCourseMutationOptions = Apollo.BaseMutationOptions<DeleteHolidayCourseMutation, DeleteHolidayCourseMutationVariables>;
export const UpdateHolidayCourseDocument = gql`
    mutation updateHolidayCourse($param: UpdateHolidayCourseInput!) {
  updateHolidayCourse(param: $param) {
    courses {
      ...HolidayCourseFragment
    }
  }
}
    ${HolidayCourseFragmentFragmentDoc}`;
export type UpdateHolidayCourseMutationFn = Apollo.MutationFunction<UpdateHolidayCourseMutation, UpdateHolidayCourseMutationVariables>;

/**
 * __useUpdateHolidayCourseMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayCourseMutation, { data, loading, error }] = useUpdateHolidayCourseMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdateHolidayCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHolidayCourseMutation, UpdateHolidayCourseMutationVariables>) {
        return Apollo.useMutation<UpdateHolidayCourseMutation, UpdateHolidayCourseMutationVariables>(UpdateHolidayCourseDocument, baseOptions);
      }
export type UpdateHolidayCourseMutationHookResult = ReturnType<typeof useUpdateHolidayCourseMutation>;
export type UpdateHolidayCourseMutationResult = Apollo.MutationResult<UpdateHolidayCourseMutation>;
export type UpdateHolidayCourseMutationOptions = Apollo.BaseMutationOptions<UpdateHolidayCourseMutation, UpdateHolidayCourseMutationVariables>;
export const SetHolidayCourseStatusDocument = gql`
    mutation setHolidayCourseStatus($param: SetCourseStatusInput!) {
  setHolidayCourseStatus(param: $param) {
    course {
      ...HolidayCourseFragment
    }
  }
}
    ${HolidayCourseFragmentFragmentDoc}`;
export type SetHolidayCourseStatusMutationFn = Apollo.MutationFunction<SetHolidayCourseStatusMutation, SetHolidayCourseStatusMutationVariables>;

/**
 * __useSetHolidayCourseStatusMutation__
 *
 * To run a mutation, you first call `useSetHolidayCourseStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHolidayCourseStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHolidayCourseStatusMutation, { data, loading, error }] = useSetHolidayCourseStatusMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useSetHolidayCourseStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetHolidayCourseStatusMutation, SetHolidayCourseStatusMutationVariables>) {
        return Apollo.useMutation<SetHolidayCourseStatusMutation, SetHolidayCourseStatusMutationVariables>(SetHolidayCourseStatusDocument, baseOptions);
      }
export type SetHolidayCourseStatusMutationHookResult = ReturnType<typeof useSetHolidayCourseStatusMutation>;
export type SetHolidayCourseStatusMutationResult = Apollo.MutationResult<SetHolidayCourseStatusMutation>;
export type SetHolidayCourseStatusMutationOptions = Apollo.BaseMutationOptions<SetHolidayCourseStatusMutation, SetHolidayCourseStatusMutationVariables>;
export const GetPaymentsDocument = gql`
    query getPayments($param: GetPaymentsInput!) {
  getPayments(param: $param) {
    ...PaymentInfoFragment
    enrolmentInfo {
      ...EnrolmentFragment
    }
    payerInfo {
      ...UserFragment
    }
    coursesInfo {
      ...CourseFragment
    }
    playersInfo {
      ...PlayerFragment
    }
    termsInfo {
      ...TermFragment
    }
  }
}
    ${PaymentInfoFragmentFragmentDoc}
${EnrolmentFragmentFragmentDoc}
${UserFragmentFragmentDoc}
${CourseFragmentFragmentDoc}
${PlayerFragmentFragmentDoc}
${TermFragmentFragmentDoc}`;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, baseOptions);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, baseOptions);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const UpdatePaymentStatusDocument = gql`
    mutation updatePaymentStatus($param: UpdatePaymentStatusInput!) {
  updatePaymentStatus(param: $param) {
    ...PaymentFragment
  }
}
    ${PaymentFragmentFragmentDoc}`;
export type UpdatePaymentStatusMutationFn = Apollo.MutationFunction<UpdatePaymentStatusMutation, UpdatePaymentStatusMutationVariables>;

/**
 * __useUpdatePaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentStatusMutation, { data, loading, error }] = useUpdatePaymentStatusMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdatePaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentStatusMutation, UpdatePaymentStatusMutationVariables>) {
        return Apollo.useMutation<UpdatePaymentStatusMutation, UpdatePaymentStatusMutationVariables>(UpdatePaymentStatusDocument, baseOptions);
      }
export type UpdatePaymentStatusMutationHookResult = ReturnType<typeof useUpdatePaymentStatusMutation>;
export type UpdatePaymentStatusMutationResult = Apollo.MutationResult<UpdatePaymentStatusMutation>;
export type UpdatePaymentStatusMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentStatusMutation, UpdatePaymentStatusMutationVariables>;
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  getPaymentMethods {
    id
    last4
    exp_year
    exp_month
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod {
  addPaymentMethod {
    clientSecret
  }
}
    `;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useAddPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>) {
        return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(AddPaymentMethodDocument, baseOptions);
      }
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($param: DeletePaymentMethodInput!) {
  deletePaymentMethod(param: $param)
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, baseOptions);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($param: CreatePaymentIntentInput!) {
  createPaymentIntent(param: $param) {
    paymentMethod
    paymentId
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, baseOptions);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const GetPlayersDocument = gql`
    query getPlayers($param: GetPlayersInput!) {
  getPlayers(param: $param) {
    ...PlayerFragment
  }
}
    ${PlayerFragmentFragmentDoc}`;

/**
 * __useGetPlayersQuery__
 *
 * To run a query within a React component, call `useGetPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useGetPlayersQuery(baseOptions: Apollo.QueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
        return Apollo.useQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
      }
export function useGetPlayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
          return Apollo.useLazyQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
        }
export type GetPlayersQueryHookResult = ReturnType<typeof useGetPlayersQuery>;
export type GetPlayersLazyQueryHookResult = ReturnType<typeof useGetPlayersLazyQuery>;
export type GetPlayersQueryResult = Apollo.QueryResult<GetPlayersQuery, GetPlayersQueryVariables>;
export const AddPlayerDocument = gql`
    mutation addPlayer($param: AddPlayerInput!) {
  addPlayer(param: $param) {
    ...PlayerFragment
  }
}
    ${PlayerFragmentFragmentDoc}`;
export type AddPlayerMutationFn = Apollo.MutationFunction<AddPlayerMutation, AddPlayerMutationVariables>;

/**
 * __useAddPlayerMutation__
 *
 * To run a mutation, you first call `useAddPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayerMutation, { data, loading, error }] = useAddPlayerMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddPlayerMutation(baseOptions?: Apollo.MutationHookOptions<AddPlayerMutation, AddPlayerMutationVariables>) {
        return Apollo.useMutation<AddPlayerMutation, AddPlayerMutationVariables>(AddPlayerDocument, baseOptions);
      }
export type AddPlayerMutationHookResult = ReturnType<typeof useAddPlayerMutation>;
export type AddPlayerMutationResult = Apollo.MutationResult<AddPlayerMutation>;
export type AddPlayerMutationOptions = Apollo.BaseMutationOptions<AddPlayerMutation, AddPlayerMutationVariables>;
export const SavePlayerDocument = gql`
    mutation savePlayer($param: SavePlayerInput!) {
  savePlayer(param: $param) {
    ...PlayerFragment
  }
}
    ${PlayerFragmentFragmentDoc}`;
export type SavePlayerMutationFn = Apollo.MutationFunction<SavePlayerMutation, SavePlayerMutationVariables>;

/**
 * __useSavePlayerMutation__
 *
 * To run a mutation, you first call `useSavePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlayerMutation, { data, loading, error }] = useSavePlayerMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useSavePlayerMutation(baseOptions?: Apollo.MutationHookOptions<SavePlayerMutation, SavePlayerMutationVariables>) {
        return Apollo.useMutation<SavePlayerMutation, SavePlayerMutationVariables>(SavePlayerDocument, baseOptions);
      }
export type SavePlayerMutationHookResult = ReturnType<typeof useSavePlayerMutation>;
export type SavePlayerMutationResult = Apollo.MutationResult<SavePlayerMutation>;
export type SavePlayerMutationOptions = Apollo.BaseMutationOptions<SavePlayerMutation, SavePlayerMutationVariables>;
export const GetTournamentsDocument = gql`
    query getTournaments {
  getTournaments {
    ...TournamentFragment
  }
}
    ${TournamentFragmentFragmentDoc}`;

/**
 * __useGetTournamentsQuery__
 *
 * To run a query within a React component, call `useGetTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<GetTournamentsQuery, GetTournamentsQueryVariables>) {
        return Apollo.useQuery<GetTournamentsQuery, GetTournamentsQueryVariables>(GetTournamentsDocument, baseOptions);
      }
export function useGetTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentsQuery, GetTournamentsQueryVariables>) {
          return Apollo.useLazyQuery<GetTournamentsQuery, GetTournamentsQueryVariables>(GetTournamentsDocument, baseOptions);
        }
export type GetTournamentsQueryHookResult = ReturnType<typeof useGetTournamentsQuery>;
export type GetTournamentsLazyQueryHookResult = ReturnType<typeof useGetTournamentsLazyQuery>;
export type GetTournamentsQueryResult = Apollo.QueryResult<GetTournamentsQuery, GetTournamentsQueryVariables>;
export const AddTournamentDocument = gql`
    mutation addTournament($param: AddTournamentInput!) {
  addTournament(param: $param) {
    ...TournamentFragment
  }
}
    ${TournamentFragmentFragmentDoc}`;
export type AddTournamentMutationFn = Apollo.MutationFunction<AddTournamentMutation, AddTournamentMutationVariables>;

/**
 * __useAddTournamentMutation__
 *
 * To run a mutation, you first call `useAddTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentMutation, { data, loading, error }] = useAddTournamentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddTournamentMutation(baseOptions?: Apollo.MutationHookOptions<AddTournamentMutation, AddTournamentMutationVariables>) {
        return Apollo.useMutation<AddTournamentMutation, AddTournamentMutationVariables>(AddTournamentDocument, baseOptions);
      }
export type AddTournamentMutationHookResult = ReturnType<typeof useAddTournamentMutation>;
export type AddTournamentMutationResult = Apollo.MutationResult<AddTournamentMutation>;
export type AddTournamentMutationOptions = Apollo.BaseMutationOptions<AddTournamentMutation, AddTournamentMutationVariables>;
export const UpdateTournamentDocument = gql`
    mutation updateTournament($param: UpdateTournamentInput!) {
  updateTournament(param: $param) {
    ...TournamentFragment
  }
}
    ${TournamentFragmentFragmentDoc}`;
export type UpdateTournamentMutationFn = Apollo.MutationFunction<UpdateTournamentMutation, UpdateTournamentMutationVariables>;

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdateTournamentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>) {
        return Apollo.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(UpdateTournamentDocument, baseOptions);
      }
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = Apollo.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = Apollo.BaseMutationOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>;
export const DeleteTournamentDocument = gql`
    mutation deleteTournament($param: DeleteTournamentInput!) {
  deleteTournament(param: $param)
}
    `;
export type DeleteTournamentMutationFn = Apollo.MutationFunction<DeleteTournamentMutation, DeleteTournamentMutationVariables>;

/**
 * __useDeleteTournamentMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMutation, { data, loading, error }] = useDeleteTournamentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useDeleteTournamentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTournamentMutation, DeleteTournamentMutationVariables>) {
        return Apollo.useMutation<DeleteTournamentMutation, DeleteTournamentMutationVariables>(DeleteTournamentDocument, baseOptions);
      }
export type DeleteTournamentMutationHookResult = ReturnType<typeof useDeleteTournamentMutation>;
export type DeleteTournamentMutationResult = Apollo.MutationResult<DeleteTournamentMutation>;
export type DeleteTournamentMutationOptions = Apollo.BaseMutationOptions<DeleteTournamentMutation, DeleteTournamentMutationVariables>;
export const ChangeTournamentStatusDocument = gql`
    mutation changeTournamentStatus($param: ChangeTournamentInput!) {
  changeTournamentStatus(param: $param) {
    ...TournamentFragment
  }
}
    ${TournamentFragmentFragmentDoc}`;
export type ChangeTournamentStatusMutationFn = Apollo.MutationFunction<ChangeTournamentStatusMutation, ChangeTournamentStatusMutationVariables>;

/**
 * __useChangeTournamentStatusMutation__
 *
 * To run a mutation, you first call `useChangeTournamentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTournamentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTournamentStatusMutation, { data, loading, error }] = useChangeTournamentStatusMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useChangeTournamentStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTournamentStatusMutation, ChangeTournamentStatusMutationVariables>) {
        return Apollo.useMutation<ChangeTournamentStatusMutation, ChangeTournamentStatusMutationVariables>(ChangeTournamentStatusDocument, baseOptions);
      }
export type ChangeTournamentStatusMutationHookResult = ReturnType<typeof useChangeTournamentStatusMutation>;
export type ChangeTournamentStatusMutationResult = Apollo.MutationResult<ChangeTournamentStatusMutation>;
export type ChangeTournamentStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTournamentStatusMutation, ChangeTournamentStatusMutationVariables>;
export const AddTournamentEnrolmentDocument = gql`
    mutation addTournamentEnrolment($param: AddTournamentEnrolmentInput!) {
  addTournamentEnrolment(param: $param) {
    enrolment {
      ...TournamentEnrolmentFragment
    }
    payment {
      ...PaymentFragment
    }
  }
}
    ${TournamentEnrolmentFragmentFragmentDoc}
${PaymentFragmentFragmentDoc}`;
export type AddTournamentEnrolmentMutationFn = Apollo.MutationFunction<AddTournamentEnrolmentMutation, AddTournamentEnrolmentMutationVariables>;

/**
 * __useAddTournamentEnrolmentMutation__
 *
 * To run a mutation, you first call `useAddTournamentEnrolmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTournamentEnrolmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTournamentEnrolmentMutation, { data, loading, error }] = useAddTournamentEnrolmentMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useAddTournamentEnrolmentMutation(baseOptions?: Apollo.MutationHookOptions<AddTournamentEnrolmentMutation, AddTournamentEnrolmentMutationVariables>) {
        return Apollo.useMutation<AddTournamentEnrolmentMutation, AddTournamentEnrolmentMutationVariables>(AddTournamentEnrolmentDocument, baseOptions);
      }
export type AddTournamentEnrolmentMutationHookResult = ReturnType<typeof useAddTournamentEnrolmentMutation>;
export type AddTournamentEnrolmentMutationResult = Apollo.MutationResult<AddTournamentEnrolmentMutation>;
export type AddTournamentEnrolmentMutationOptions = Apollo.BaseMutationOptions<AddTournamentEnrolmentMutation, AddTournamentEnrolmentMutationVariables>;
export const GetTournamentEnrolmentsDocument = gql`
    query getTournamentEnrolments($param: GetTournamentEnrolmentsInput!) {
  getTournamentEnrolments(param: $param) {
    ...GetTournamentEnrolmentFragment
  }
}
    ${GetTournamentEnrolmentFragmentFragmentDoc}`;

/**
 * __useGetTournamentEnrolmentsQuery__
 *
 * To run a query within a React component, call `useGetTournamentEnrolmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentEnrolmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentEnrolmentsQuery({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useGetTournamentEnrolmentsQuery(baseOptions: Apollo.QueryHookOptions<GetTournamentEnrolmentsQuery, GetTournamentEnrolmentsQueryVariables>) {
        return Apollo.useQuery<GetTournamentEnrolmentsQuery, GetTournamentEnrolmentsQueryVariables>(GetTournamentEnrolmentsDocument, baseOptions);
      }
export function useGetTournamentEnrolmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentEnrolmentsQuery, GetTournamentEnrolmentsQueryVariables>) {
          return Apollo.useLazyQuery<GetTournamentEnrolmentsQuery, GetTournamentEnrolmentsQueryVariables>(GetTournamentEnrolmentsDocument, baseOptions);
        }
export type GetTournamentEnrolmentsQueryHookResult = ReturnType<typeof useGetTournamentEnrolmentsQuery>;
export type GetTournamentEnrolmentsLazyQueryHookResult = ReturnType<typeof useGetTournamentEnrolmentsLazyQuery>;
export type GetTournamentEnrolmentsQueryResult = Apollo.QueryResult<GetTournamentEnrolmentsQuery, GetTournamentEnrolmentsQueryVariables>;
export const SaveUserProfileDocument = gql`
    mutation saveUserProfile($param: SaveUserProfileInput!) {
  saveUserProfile(param: $param) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type SaveUserProfileMutationFn = Apollo.MutationFunction<SaveUserProfileMutation, SaveUserProfileMutationVariables>;

/**
 * __useSaveUserProfileMutation__
 *
 * To run a mutation, you first call `useSaveUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfileMutation, { data, loading, error }] = useSaveUserProfileMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useSaveUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>) {
        return Apollo.useMutation<SaveUserProfileMutation, SaveUserProfileMutationVariables>(SaveUserProfileDocument, baseOptions);
      }
export type SaveUserProfileMutationHookResult = ReturnType<typeof useSaveUserProfileMutation>;
export type SaveUserProfileMutationResult = Apollo.MutationResult<SaveUserProfileMutation>;
export type SaveUserProfileMutationOptions = Apollo.BaseMutationOptions<SaveUserProfileMutation, SaveUserProfileMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($param: UpdateUserProfileInput!) {
  updateUserProfile(param: $param) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile {
  getUserProfile {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, baseOptions);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($param: SendMessageInput!) {
  sendMessage(param: $param)
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      param: // value for 'param'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, baseOptions);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;