import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { useContext } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import ERoutePath from "../routes/ERoutePath";
const styles = ({ palette, spacing, mixins, breakpoints }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateAreas: `"header"
             "section"
             `,
      // gridTemplateRows: `${mixins.toolbar.minHeight}px 1fr 8rem auto`,
      gridTemplateColumns: "1fr",
      // height: "100vh",
    },
    header: { gridArea: "header" },
    section: {
      gridArea: "section",
      display: "grid",
      justifyItems: "center",
      padding: "4.5rem 2rem 0 2rem",
    },
    contentContainer: {
      maxWidth: "122rem",
      width: "100%",
    },
    sectionNoPadding: {
      gridArea: "section",
      width: "100vw"
    },
    footer: {
      gridArea: "footer",
      padding: spacing(2),
      background: palette.primary.main,
      color: "white",
      textAlign: "center",
      height: "20px",
      width: "100%",
      position: "fixed",
      bottom: "10px",
    },
  });

interface Props extends WithStyles<typeof styles> {
  children: JSX.Element;
  padding: boolean;
}

export const HomePageLayout = ({ classes, children, padding }: Props) => {
    // const bannerTitle1 = "Vivian Table Tennis Academy";
    const { isAuthenticated, userProfile } = useContext(AuthContext);
    const history = useHistory();
    if (isAuthenticated) {
      if (!userProfile) {
        history.push(ERoutePath.profile);
      } else {
        history.push(ERoutePath.enrolments);
      }
    }
  return (
    <main className={classes.root}>
      {/* <header className={classes.header}>
        <Header />
      </header> */}
      {padding && (
        <section className={classes.section}>
          <div className={classes.contentContainer}>{children}</div>
        </section>
      )}
      {!padding && (
        <section className={classes.sectionNoPadding}>
          {/* <HomePage /> */}
          {children}
          {/* <footer className={classes.footer}>{<Footer />}</footer> */}
        </section>
      )}
    </main>
  );
};

export const Layout = withStyles(styles)(
  ({ classes, children }: { classes: any; children?: any }) => (
    <HomePageLayout children={children} classes={classes} padding={false} />
  )
);

export default withStyles(styles)(HomePageLayout);
