import {
  AddHolidayCourseDocument,
  AddHolidayCourseInput,
  AddHolidayCourseMutation,
  DeleteHolidayCourseDocument,
  DeleteHolidayCourseInput,
  DeleteHolidayCourseMutation,
  GetHolidayCourseDocument,
  GetHolidayCourseQuery,
  HolidayCourse,
  SetCourseStatusInput,
  SetHolidayCourseStatusDocument,
  SetHolidayCourseStatusMutation,
  UpdateHolidayCourseDocument,
  UpdateHolidayCourseInput,
  UpdateHolidayCourseMutation,
} from "../generated/graphql";
import { client } from "./client";

export const addHolidayCourse = async (inputData: AddHolidayCourseInput) =>
  (
    await client.mutate<AddHolidayCourseMutation>({
      mutation: AddHolidayCourseDocument,
      variables: {
        param: {
          ...inputData,
        },
      },
    })
  ).data?.addHolidayCourse;

export const updateHolidayCourse = async (
  inputData: UpdateHolidayCourseInput
) =>
  (
    await client.mutate<UpdateHolidayCourseMutation>({
      mutation: UpdateHolidayCourseDocument,
      variables: {
        param: {
          ...inputData,
        },
      },
    })
  ).data?.updateHolidayCourse;

export const getHolidayCourses = async () =>
  (
    await client.query<GetHolidayCourseQuery>({
      query: GetHolidayCourseDocument,
    })
  ).data?.getHolidayCourses;

export const deleteHolidayCourse = async ({
  courseId,
}: DeleteHolidayCourseInput): Promise<boolean> => {
  const result =
    (
      await client.mutate<DeleteHolidayCourseMutation>({
        mutation: DeleteHolidayCourseDocument,
        variables: {
          param: {
            courseId,
          },
        },
      })
    ).data?.deleteHolidayCourse ?? false;
  return result;
};

export const setHolidayCourseStatus = async ({
  courseId,
  status,
}: SetCourseStatusInput): Promise<HolidayCourse | undefined | null> => {
  const result = (
    await client.mutate<SetHolidayCourseStatusMutation>({
      mutation: SetHolidayCourseStatusDocument,
      variables: {
        param: {
          courseId,
          status,
        },
      },
    })
  ).data?.setHolidayCourseStatus;
  return result?.course;
};
