import { Paper, Theme, Typography } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React from "react";

const styles = ({ spacing }: Theme) =>
  createStyles({
    container: {
      margin: spacing(2),
    },
  });

interface Props extends WithStyles<typeof styles> {
  title: string;
}
const EmptyInfo = ({ classes, title }: Props) => {
  return (
    <Paper className={classes.container} elevation={0}>
      <Typography variant="h5">{title}</Typography>
    </Paper>
  );
};

export default withStyles(styles)(EmptyInfo);
