import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { DEFAULT_EMAIL, DEFAULT_PASSWORD } from "../const";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import ERoutePath from "../routes/ERoutePath";
import { emailValidator } from "../utils/validator";
import DialogTitle from "./DialogTitle";
import EmailTextInput from "./EmailTextInput";
import FormRow from "./FormRow";
import PasswordTextInput from "./PasswordTextInput";

const validationsForm = yup.object().shape({
  email: emailValidator,
  password: yup
    .string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
});

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dlgAction: {
      justifyContent: "center",
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface ISignInValue {
  email: string;
  password: string;
}

const SignIn = (props: Props) => {
  const history = useHistory();
  const { showErrorNotification, showNotification } =
    useContext(NotificationContext);
  const initialValues: ISignInValue = {
    email: DEFAULT_EMAIL,
    password: DEFAULT_PASSWORD,
  };
  const { isAuthenticated, signin, userProfile } = useContext(AuthContext);
  const { values, handleSubmit, handleChange, errors, touched, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: validationsForm,
      onSubmit: async ({ email, password }) => {
        const result = await signin({
          email,
          password,
        });
        if (result.success) {
          if (result.hasUserProfile) {
            history.push(ERoutePath.enrolments);
          } else {
            history.push(ERoutePath.profile);
          }
        } else {
          showErrorNotification(
            result.error ?? "Unable to sign in, please try again later."
          );
        }
      },
    });
  const [open, setOpen] = React.useState(true);

  const handleClose = (evt: object, reason: string) => {
    if (reason === "backdropClick") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleDlgClose = () => {
    setOpen(false);
  };
  const signInWelcomeMessage = ``;
  if (isAuthenticated) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: 400,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={handleDlgClose}>
          <Typography> Sign In </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ minHeight: 300 }}
          >
            <Grid item xs={12}>
              <Typography align="center">{signInWelcomeMessage}</Typography>
            </Grid>
            <FormRow>
              <Grid item xs={12}>
                <EmailTextInput
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextInput
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  fullWidth
                />
              </Grid>
            </FormRow>
            <FormRow>
              New to Vivian Table Tennis Academy
              <Link to="signup"> Create an account</Link>
            </FormRow>
            <FormRow>
              <Link to="forgot-password"> Forgot password</Link>
            </FormRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(SignIn);
