import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { getCourses } from "../api/course.service";
import { LoadingContext } from "../context/LoadingContext";
import EmptyInfo from "../EmptyInfo";
import {
  AddCourseResp,
  CoursesResp,
  UpdateCourseResp,
} from "../generated/graphql";
import CourseDetail from "./CourseDetail";
import CourseList from "./CourseList";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";

const styles = (theme: Theme) =>
  createStyles({
    paperWidthSm: {
      maxWidth: "80em",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

function Course(props: Props) {
  const { classes } = props;
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [addNewCourse, setAddNewCourse] = useState(false);
  const [courses, setCourses] = useImmer<CoursesResp[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<CoursesResp | null>(
    null
  );

  const handleAddNewCourse = () => {
    setAddNewCourse(true);
  };

  const handlleNewCourse = (newCourse: AddCourseResp) => {
    setCourses((draft) => {
      draft.push(newCourse as CoursesResp);
    });
  };

  const handleCourseUpdated = (updatedCourse: UpdateCourseResp) => {
    setCourses((draft) => {
      const idx = draft.findIndex((c) => c.term.id === updatedCourse.term.id);
      draft[idx] = updatedCourse as CoursesResp;
    });
  };

  const handleCourseDeleted = (termId: string) => {
    setCourses((draft) => {
      const foundIdx = draft.findIndex((c) => c.term.id === termId);
      if (foundIdx !== -1) {
        draft.splice(foundIdx, 1);
      }
    });
  };

  const handleTermDisabled = (termId: string) => {
    setCourses((draft) => {
      const foundIdx = draft.findIndex((c) => c.term.id === termId);
      if (foundIdx !== -1) {
        draft.splice(foundIdx, 1);
      }
    });
  };

  const handleClose = () => {
    setAddNewCourse(false);
    setSelectedCourse(null);
  };

  const retrieveCourses = async () => {
    showLoading();

    const courses = (await getCourses()) as CoursesResp[];
    setCourses((draft) => {
      if (courses) {
        draft.push(...courses);
      }
    });
    hideLoading();
  };

  useEffect(() => {
    retrieveCourses();
  }, []);

  const handleSelectedCourse = (course: CoursesResp) => {
    setSelectedCourse(course);
  };

  const getDialogTitle = () => (
    <Typography>
      {selectedCourse ? "Update course" : "Add a new Course"}
    </Typography>
  );

  const startDate =
    selectedCourse?.term.startDate ?? DateTime.now().toISODate();
  const endDate =
    selectedCourse?.term.endDate ??
    DateTime.now().plus({ days: 6 }).toISODate();
  const termDescription = selectedCourse?.term?.description ?? "";

  return (
    <FormContainer>
      <FormTitle title="Course setting" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {courses.length > 0 && (
              <CourseList
                courses={courses}
                onSelectedCourse={handleSelectedCourse}
              />
            )}
            {courses.length === 0 && (
              <EmptyInfo title="Please add a new course" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleAddNewCourse}
            >
              Add a new course
            </Button>
          </Grid>
        </FormRow>
      </Grid>
      <Dialog
        fullWidth
        open={addNewCourse || selectedCourse !== null}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle onClose={handleClose}>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <CourseDetail
            startDate={startDate}
            endDate={endDate}
            termDescription={termDescription}
            onClose={handleClose}
            onNewCourse={handlleNewCourse}
            onCourseUpdated={handleCourseUpdated}
            onCourseDeleted={handleCourseDeleted}
            onTermDisabled={handleTermDisabled}
            course={selectedCourse}
          />
        </DialogContent>
      </Dialog>
    </FormContainer>
  );
}

export default withStyles(styles)(Course);
