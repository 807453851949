import { Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RowParams } from "@material-ui/data-grid";
import React from "react";
import { CoursesResp, ECourseLevel } from "../generated/graphql";
import CustomizedDataGrid from "./CustomizedDataGrid";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "70vh",
      overflowY: "auto",
      width: "95%",
    },
    root: {
      backgroundColor: "yellow",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  courses: CoursesResp[];
  onSelectedCourse?: (selectedCourse: CoursesResp) => void;
}

export interface ICourseRow {
  id: string;
  termDescription: string;
  termDate: string;
  time: string;
  day: string;
  level: ECourseLevel;
  selectedCourseIndex?: number;
}

function CourseList(props: Props) {
  const { classes, courses, onSelectedCourse } = props;
  const columns = [
    { field: "termDescription", headerName: "Description", width: 270 },
    { field: "termDate", headerName: "Term date", width: 130 },
    // { field: "endDate", headerName: "End date", width: 130 },
    { field: "level", headerName: "Level", width: 130 },
    { field: "time", headerName: "Start time", width: 130 },
    { field: "day", headerName: "Course day", width: 130 },
  ];

  const getTermDate = ({ term: { startDate, endDate } }: CoursesResp) => {
    return `${startDate} - ${endDate}`;
  };
  const getCourseStartTime = (course: CoursesResp) => {
    return course.courses
      .filter((c) => c.available)
      .map((c) => `${c.startTime} - ${c.endTime}`)
      .join(";");
  };
  const getCourseDay = (course: CoursesResp) => {
    return course.courses
      .filter((c) => c.available)
      .map((c) => `${c.courseDay}`)
      .join(";");
  };
  const rows: ICourseRow[] = courses.map((course: CoursesResp) => ({
    id: course.term.id,
    termDescription: course.term.description,
    termDate: getTermDate(course),
    level: course.term.level as ECourseLevel,
    time: getCourseStartTime(course),
    day: getCourseDay(course),
  }));

  const handleRowClick = ({ rowIndex }: RowParams) => {
    if (onSelectedCourse) {
      onSelectedCourse(courses[rowIndex]);
    }
  };

  return (
    <Paper className={classes.container}>
      <CustomizedDataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onRowClick={handleRowClick}
        autoHeight
        classes={{
          root: classes.root,
        }}
      />
    </Paper>
  );
}

export default withStyles(styles)(CourseList);
