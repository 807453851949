import React from "react";
import AuthContextProvider from "../context/AuthContext";
import { withLoadingContextProvider } from "../context/LoadingContext";
import { NotificationProvider } from "../context/NotificationContext";

export default withLoadingContextProvider(({ children }: any) => (
  <AuthContextProvider>
    <NotificationProvider>{children}</NotificationProvider>
  </AuthContextProvider>
));
