import {
  AddPaymentMethodDocument,
  AddPaymentMethodMutation,
  CreatePaymentIntentDocument,
  CreatePaymentIntentInput,
  CreatePaymentIntentMutation,
  DeletePaymentMethodDocument,
  DeletePaymentMethodMutation,
  GetPaymentMethodsDocument,
  GetPaymentMethodsQuery,
  GetPaymentsDocument,
  GetPaymentsInput,
  GetPaymentsQuery,
  UpdatePaymentStatusDocument,
  UpdatePaymentStatusInput,
  UpdatePaymentStatusMutation,
} from "../generated/graphql";
import { client } from "./client";

export const getPayments = async ({ userId }: GetPaymentsInput) => {
  return (
    await client.query<GetPaymentsQuery>({
      query: GetPaymentsDocument,
      variables: {
        param: {
          userId,
        },
      },
    })
  ).data.getPayments;
};

export const updatePaymentStatus = async ({
  paymentId,
  status,
}: UpdatePaymentStatusInput) => {
  return (
    await client.query<UpdatePaymentStatusMutation>({
      query: UpdatePaymentStatusDocument,
      variables: {
        param: {
          paymentId,
          status,
        },
      },
    })
  ).data.updatePaymentStatus;
};

export const getPaymentMethods = async () => {
  return (
    await client.query<GetPaymentMethodsQuery>({
      query: GetPaymentMethodsDocument,
      variables: {},
    })
  ).data.getPaymentMethods;
};

export const addPaymentMethod = async () => {
  return (
    await client.mutate<AddPaymentMethodMutation>({
      mutation: AddPaymentMethodDocument,
    })
  ).data?.addPaymentMethod;
};

export const deletePaymentMethod = async (id: string) => {
  return (
    await client.mutate<DeletePaymentMethodMutation>({
      mutation: DeletePaymentMethodDocument,
      variables: {
        param: {
          id,
        },
      },
    })
  ).data?.deletePaymentMethod;
};

export const createPaymentIntent = async ({
  amount,
  paymentMethodId,
  paymentCode,
}: CreatePaymentIntentInput) => {
  return (
    await client.mutate<CreatePaymentIntentMutation>({
      mutation: CreatePaymentIntentDocument,
      variables: {
        param: {
          amount,
          paymentMethodId,
          paymentCode,
        },
      },
    })
  ).data?.createPaymentIntent;
};
