import { add, getDay } from "date-fns";
import { DateTime } from "luxon";
import { EWeekDay } from "../generated/graphql";
import { differenceInDays } from "../utils/differenceInDays";

export interface ICourseLessonDay {
  day: EWeekDay;
  num: number;
  dates: Date[];
  available: boolean;
  numOfPublicHolidays: number;
}

const termLessonCalculation = (
  startDate: string,
  endDate: string
): ICourseLessonDay[] => {
  const dtStart = new Date(startDate);
  const dtEnd = new Date(endDate);
  const startDay = getDay(dtStart);
  const dayMapping = [
    EWeekDay.Sun,
    EWeekDay.Mon,
    EWeekDay.Tues,
    EWeekDay.Wed,
    EWeekDay.Thu,
    EWeekDay.Fri,
    EWeekDay.Sat,
  ];
  // shift day mapping from dtStart;
  if (startDay !== 0) {
    for (let idx = 0; idx < startDay; idx = idx + 1) {
      dayMapping.push(dayMapping.shift() as EWeekDay);
    }
  }
  const diffInDays = differenceInDays(dtEnd, dtStart) + 1;
  const wks = Math.floor(diffInDays / 7);
  const remainedDays = diffInDays % 7;

  const getDates = (startDate: Date, diffDay: number, weeks: number) => {
    const result = [];
    for (let wks = 0; wks < weeks; wks = wks + 1) {
      result.push(add(startDate, { days: diffDay + 7 * wks }));
    }
    return result;
  };

  const termDays: ICourseLessonDay[] = dayMapping.map(
    (day: EWeekDay, index: number) => ({
      day,
      num: wks,
      dates: getDates(dtStart, index, wks),
      available: isWeekDayInDate(startDate, endDate)(day),
      numOfPublicHolidays: 0,
    })
  );
  for (let idx = 0; idx < remainedDays; idx = idx + 1) {
    termDays[idx].num = termDays[idx].num + 1;
    termDays[idx].dates.push(add(dtStart, { days: idx + 7 * wks }));
  }
  return termDays;
};

export const weekDayToNumber = (day: EWeekDay) => {
  switch (day) {
    case EWeekDay.Sun:
      return 7;
    case EWeekDay.Mon:
      return 1;
    case EWeekDay.Tues:
      return 2;
    case EWeekDay.Wed:
      return 3;
    case EWeekDay.Thu:
      return 4;
    case EWeekDay.Fri:
      return 5;
    case EWeekDay.Sat:
      return 6;
  }
};

export const isWeekDayInDate =
  (startDate: string, endDate: string) => (day: EWeekDay) => {
    const startDay = new Date(startDate);
    const endDay = new Date(endDate);
    const diffInDays = differenceInDays(endDay, startDay) + 1;
    for (let n = 0; n < diffInDays && n <= 7; n = n + 1) {
      const a = add(new Date(startDate), { days: n }).toISOString();
      const b = DateTime.fromISO(a).weekday;
      const c = weekDayToNumber(day);
      if (b === c) {
        return true;
      }
    }
    return false;
  };

export const numOfDayInDate =
  (startDate: string, endDate: string) => (day: EWeekDay) => {
    const diffInDays =
      differenceInDays(new Date(endDate), new Date(startDate)) + 1;
    const remainderDays = diffInDays % 7;
    const wholeWks = Math.floor(diffInDays / 7);
    let extra = 0;
    for (let n = 0; n < remainderDays; n = n + 1) {
      const tmp = DateTime.fromISO(startDate).plus({ days: n }).weekday;
      // add(DateTime.fromISO(startDate),({ days: n }).weekday;
      if (tmp === weekDayToNumber(day)) {
        extra = 1;
        break;
      }
    }
    return wholeWks + extra;
  };

export default termLessonCalculation;
