import { Grid } from "@material-ui/core";
import React from "react";
const FormRow = (props: any) => (
  <Grid
    container
    item
    xs={12}
    spacing={2}
    alignItems={props.alignItems ?? "center"}
    justify={props.justify ?? "center"}
  >
    {props.children}
  </Grid>
);
export default FormRow;
