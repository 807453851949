export const footerHtml = `
<style>
	#menu-junk-drawer >.menu-item:hover a{
		color: #40AAD5 !important
	}
  #menu-footer-menu>.menu-item a {
			color: white !important
	}
	#menu-footer-menu>.menu-item:hover a{
			color: #40AAD5 !important
	}
	#menu-junk-drawer>.menu-item a {
		color: white !important
  }
	#menu-footer-3>.menu-item a {
		color: white !important
  }
  .footer-menu-item {
	  width: 100% !important
	}
</style>
<footer class="site-footer"><div class="wrap"><div class="footer-widgets" id="genesis-footer-widgets"><h2 class="genesis-sidebar-title screen-reader-text">Footer</h2><div class="wrap"><div class="widget-area footer-widgets-1 footer-widget-area"><section id="custom_html-3" class="widget_text widget widget_custom_html"><div class="widget_text widget-wrap"><div class="textwidget custom-html-widget"><img src="https://www.viviantabletennis.com/wp-content/uploads/2022/03/Vivian-Tan-Logo-red-white.png" class="footer-logo">
<div class="social-wrapper">

</div></div></div></section>
</div>
<div class="widget-area footer-widgets-2 footer-widget-area">
<section id="nav_menu-6" class="widget widget_nav_menu"><div class="widget-wrap"><div class="menu-footer-menu-container">
<ul id="menu-footer-menu" class="menu">
  <li id="menu-item-1451" class="footer-menu-item  menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1451">
   <a href="https://www.viviantabletennis.com/#about">About Us</a></li>
  <li id="menu-item-2324" class="footer-menu-item  menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-2324"><a href="https://www.viviantabletennis.com/#classes">Coaching</a></li>
  <li id="menu-item-2005" class="footer-menu-item  menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-37 current_page_item menu-item-2005"><a href="https://www.viviantabletennis.com/contact/" aria-current="page">Contact</a></li>
  </ul></div></div></section>
</div>
<div class="widget-area footer-widgets-3 footer-widget-area"><section id="nav_menu-4" class="widget widget_nav_menu">
<div class="widget-wrap"><div class="menu-junk-drawer-container">
<ul id="menu-junk-drawer" class="menu">
<li id="menu-item-1448" class="menu-item  footer-menu-item  menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1448"><a href="https://www.viviantabletennis.com/#how-it-works">How it works</a></li>
<li id="menu-item-1450" class="menu-item  footer-menu-item  menu-item-type-custom menu-item-object-custom menu-item-home menu-item-1450"><a href="https://www.viviantabletennis.com/#testimonials">Reviews</a></li>
<li id="menu-item-3303" class="menu-item  footer-menu-item  menu-item-type-post_type menu-item-object-page menu-item-3303"><a href="https://www.viviantabletennis.com/table-tennis-coach/">Our Coaches</a></li>
</ul></div></div></section>
</div><div class="widget-area footer-widgets-4 footer-widget-area"><section id="nav_menu-7" class="widget widget_nav_menu">
<div class="widget-wrap"><div class="menu-footer-3-container"><ul id="menu-footer-3" class="menu">
<li id="menu-item-3186" class="menu-item footer-menu-item menu-item-type-custom menu-item-object-custom menu-item-3186">
<a href="https://www.viviantabletennis.com/lower-north-shore/">Table Tennis Lower North Shore</a></li>

<li id="menu-item-3187" class="menu-item footer-menu-item menu-item-type-custom menu-item-object-custom menu-item-3187">
<a href="https://www.viviantabletennis.com/upper-north-shore/">Table Tennis North Shore</a></li>
</ul></div></div></section>
</div></div></div>	<div class="copyright-wrapper"><div class="p copyright">
© <script>document.write(new Date().getFullYear())</script>2023 Vivian Table Tennis. All rights reserved</div>
</div></div>
		<a href="#top" class="back-to-top"></a></div></footer>
	`;
