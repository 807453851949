import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface IAccountRequest {
  email: string;
  password: string;
}

export interface IConfirmRequest {
  email: string;
  code: string;
}
export interface ISignInReq extends IAccountRequest {}

export interface IChangePasswordRequest {
  newPassword: string;
  oldPassword: string;
  repeatPassword?: string;
}
export interface ISignUpReq extends IAccountRequest {
  repeatPassword: string;
}

export interface IResetPasswordRequest extends IAccountRequest {
  code: string;
  repeatPassword?: string;
}

const signUp = async ({ email: username, password }: IAccountRequest) => {
  const param = {
    username,
    password,
  };
  return await Auth.signUp(param);
};

const signIn = async (
  { email, password }: IAccountRequest,
  validationData = {}
): Promise<CognitoUser> =>
  await Auth.signIn({ username: email, password, validationData });

const signOut = async () => await Auth.signOut();

const confirmSignUp = async ({ email, code }: IConfirmRequest) =>
  await Auth.confirmSignUp(email, code);

const forgotPassword = async (email: string) =>
  await Auth.forgotPassword(email);

const changePassword = async ({
  newPassword,
  oldPassword,
}: IChangePasswordRequest) => {
  const user = await Auth.currentAuthenticatedUser();
  const result = Auth.changePassword(user, oldPassword, newPassword);
  return result;
};

export const forgotPasswordSubmit = async ({
  email,
  code,
  password,
}: IResetPasswordRequest) =>
  await Auth.forgotPasswordSubmit(email, code, password);

const isAuthenticated = async () => {
  const u = await Auth.currentAuthenticatedUser();
  return u;
};

const loggedInCogUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

export default {
  signIn,
  signOut,
  signUp,
  confirmSignUp,
  forgotPassword,
  changePassword,
  isAuthenticated,
  loggedInCogUser,
  forgotPasswordSubmit,
};
