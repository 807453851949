import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, WithSnackbarProps } from "notistack";
import React from "react";

export const NotificationContext = React.createContext(
  {} as INotificationContext
);

export interface INotificationContext {
  showSuccessNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
  showWarningNotification: (message: string) => void;
  showNotification: (notificationConfig: INotificationConfig) => void;
}

export interface INotificationConfig {
  level?: "default" | "success" | "warning" | "error";
  message: string;
  duration?: number;
}

interface IProps extends WithSnackbarProps {
  children?: any;
}

const InnerNotificationProvider = ({
  enqueueSnackbar,
  closeSnackbar,
  children,
}: IProps) => {
  const showNotification = ({
    message,
    duration = 5000,
    level = "default",
  }: INotificationConfig) => {
    enqueueSnackbar(message, {
      variant: level,
      autoHideDuration: duration,
    });
  };

  const closeHandler = (key: string) => () => {
    closeSnackbar(key);
  };

  const action = (key: string) => (
    <>
      <IconButton
        aria-label="close"
        color="secondary"
        onClick={closeHandler(key)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
  const showSuccessNotification = (message: string) => {
    enqueueSnackbar(message, {
      variant: "success",
      autoHideDuration: 5000,
    });
  };

  const showErrorNotification = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 10000,
      action,
    });
  };

  const showWarningNotification = (message: string) => {
    enqueueSnackbar(message, {
      variant: "warning",
      autoHideDuration: 10000,
      action,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        showSuccessNotification,
        showErrorNotification,
        showWarningNotification,
        showNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export const NotificationProvider = withSnackbar(InnerNotificationProvider);
