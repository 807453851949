import { Button, Grid } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { RowSelectedParams } from "@material-ui/data-grid";
import { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { getPlayers } from "../api/player.service";
import { AuthContext } from "../context/AuthContext";
import EmptyInfo from "../EmptyInfo";
import { EGender, Player } from "../generated/graphql";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import PlayerComponent from "./PlayerComponent";
import PlayerList from "./PlayerList";

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

interface IProfileInput {
  firstName: string;
  lastName: string;
  dob: string;
  gender: EGender;
  school: string;
  hasDisability: string;
  disabilityClass?: string;
  disabilityDescription?: string;
}

function Players(props: Props) {
  const [players, setPlayers] = useImmer<Player[]>([]);

  const [loadedData, setLoadedData] = useState(false);
  const loadPlayers = async (parentId: string) => {
    setLoadedData(false);
    const p = (await getPlayers({ parentId })) ?? [];
    setLoadedData(true);
    setPlayers((draft) => {
      draft.push(...p);
    });
  };

  const loadAllPlayers = async () => {
    setLoadedData(false);
    const p = (await getPlayers({})) ?? [];
    setLoadedData(true);
    setPlayers((draft) => {
      draft.push(...(p ?? []));
    });
  };

  const { userProfile, isAdminRole, isParentRole } = useContext(AuthContext);

  useEffect(() => {
    if (isAdminRole()) {
      loadAllPlayers();
    } else {
      loadPlayers(userProfile?.id);
    }
  }, [userProfile?.id, isAdminRole]);

  const [toAddNewPlayer, setToAddNewPlayer] = useState(false);
  const [toUpdatePlayer, setToUpdatePlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const handleSelectedPlayer = (selectedRow: RowSelectedParams) => {
    const foundPlayer = players.find((p) => p.id === selectedRow.data.id);
    setSelectedPlayer(foundPlayer ?? null);
    setToUpdatePlayer(true);
  };
  const handleAddNewPlayer = () => {
    setToAddNewPlayer(true);
  };

  const handleExportPlayers = () => {
    const allPlayersCsv = players.map((player) => (
       `${player.firstName},${player.lastName},${player.gender},${player.dob}`
    )).join('\n')

    console.log('players', players)

    var fileName = "allPlayers.csv";
    const blob = new Blob([allPlayersCsv], { type: "text/plain"})
    
    const a = document.createElement('a')
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return (
    <FormContainer>
      <FormTitle title="Players" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {players.length > 0 && (
              <PlayerList
                players={players}
                onSelectedPlayer={handleSelectedPlayer}
              />
            )}
            {loadedData && players.length === 0 && (
              <EmptyInfo title="There is no player, please add player first." />
            )}
          </Grid>
          {isAdminRole() && (
            <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleExportPlayers}
            >
              Export players
            </Button>
          </Grid>
          )}
          {isParentRole() && (
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleAddNewPlayer}
              >
                Add player
              </Button>
            </Grid>
          )}
        </FormRow>
      </Grid>
      {toUpdatePlayer && selectedPlayer && (
        <PlayerComponent
          player={selectedPlayer}
          isAddingNewPlayer={false}
          onClose={(p: Player | undefined) => {
            if (p) {
              setPlayers((draft) => {
                const foundIndex = draft.findIndex((d) => d.id === p.id);
                draft[foundIndex] = p;
              });
            }
            setToUpdatePlayer(false);
          }}
        />
      )}
      {toAddNewPlayer && (
        <PlayerComponent
          isAddingNewPlayer={true}
          onClose={(p: Player | undefined) => {
            if (p) {
              setPlayers((draft) => {
                draft.push(p);
              });
            }
            setToAddNewPlayer(false);
          }}
        />
      )}
    </FormContainer>
  );
}

export default withStyles(styles)(Players);
