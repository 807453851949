import { Paper, Theme } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React from "react";

const styles = ({ spacing }: Theme) =>
  createStyles({
    container: {
      margin: spacing(1),
      minWidth: "80%",
    },
  });

interface Props extends WithStyles<typeof styles> {
  children: any;
}
const FormContainer = ({ classes, children }: Props) => {
  return <Paper className={classes.container}>{children}</Paper>;
};

export default withStyles(styles)(FormContainer);
