import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { getPayments } from "../api/payment.service";
import { AuthContext } from "../context/AuthContext";
import { ERole, GetPaymentsInput, PaymentInfoResp } from "../generated/graphql";
import FormContainer from "./FormContainer";
import FormTitle from "./FormTitle";
import PaymentList from "./PaymentList";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

function Payments(props: Props) {
  const [payments, setPayments] = useState<PaymentInfoResp[]>([]);
  const { userProfile } = useContext(AuthContext);

  const loadPayments = async () => {
    let param: GetPaymentsInput = {};
    if (userProfile?.role === ERole.Parent) {
      param.userId = userProfile.uid;
    }
    setPayments((await getPayments(param)) ?? []);
  };

  useEffect(() => {
    loadPayments();
  }, []);
  return (
    <FormContainer>
      <FormTitle title="Payment" />
      <PaymentList payments={payments} onUpdate={loadPayments} />
    </FormContainer>
  );
}

export default withStyles(styles)(Payments);
