import { Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RowParams, RowSelectedParams } from "@material-ui/data-grid";
import React from "react";
import {
  HolidayCourse,
  Tournament,
  TournamentEnrolment,
} from "../generated/graphql";
import CustomizedDataGrid from "./CustomizedDataGrid";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "30vh",
      overflowY: "auto",
      width: "95%",
      marginTop: "8px",
    },
    root: {
      backgroundColor: "yellow",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  tournaments: Tournament[];
  onSelectedTournament?: (
    selectedTournament: Tournament,
    selected: boolean
  ) => void;
}

export interface ITournamentRow {
  id: string;
  date: string;
  name: string;
  price: string;
}

function TournamentList(props: Props) {
  const { classes, tournaments, onSelectedTournament } = props;
  const columns = [
    { field: "name", headerName: "Name", width: 170 },
    { field: "date", headerName: "Date", width: 170 },
    { field: "price", headerName: "Price", width: 100 },
  ];

  const rows: ITournamentRow[] = tournaments.map(
    ({ id, price, name, startDate }: Tournament) => ({
      id,
      price: `$${price}`,
      name,
      date: startDate,
    })
  );

  const handleRowSelected = (rowParam: RowSelectedParams) => {
    if (onSelectedTournament) {
      const foundTournament = tournaments.find(
        (t) => t.id === rowParam.data.id
      );
      if (foundTournament) {
        onSelectedTournament(foundTournament, rowParam.isSelected);
      }
    }
  };
  return (
    <Paper className={classes.container}>
      <CustomizedDataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        checkboxSelection
        onRowSelected={handleRowSelected}
        autoHeight
        classes={{
          root: classes.root,
        }}
      />
    </Paper>
  );
}

export default withStyles(styles)(TournamentList);
