import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useImmer } from "use-immer";
import {
  Course,
  CoursesResp,
  ECourseLevel,
  EWeekDay,
  Term,
} from "../generated/graphql";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: "500px",
      padding: theme.spacing(1),
    },
    root: {
      backgroundColor: "yellow",
    },
    card: {
      margin: theme.spacing(2),
    },
    selectedGrid: {
      background: theme.palette.info.main,
    },
    disableSelectGrid: {
      background: "red",
    },
    begininerLevel: {
      background: "yellow",
    },
    intermediateLevel: {
      background: "lightblue",
    },
    advancedLevel: {
      background: "purple",
    },
    privateLevel: {
      background: "green",
    },
    eliteLevel: {
      background: "burlywood",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  courses: CoursesResp[];
  onSelectedCourse?: (
    selectedCourse: { course: Course; term: Term },
    bSelectCourse: boolean
  ) => void;
}

export interface ICourseRow {
  id: string;
  termDescription: string;
  startDate: string;
  endDate: string;
  level: ECourseLevel;
  selectedCourseIndex?: number;
}

function CourseListInWeekView(props: Props) {
  const { classes, courses, onSelectedCourse } = props;
  const [selectedCourseIds, setSelectedCourseIds] = useImmer<string[]>([]);
  interface WkCourse {
    [name: string]: { course?: Course; term?: Term }[];
  }
  const coursesInWkDay = courses
    .filter(
      (course: CoursesResp) => course.courses.findIndex((c) => c.available) >= 0
    )
    .reduce(
      (acc: WkCourse, curr: CoursesResp) => {
        const xxx = curr.courses.filter((c: any) => c.available);
        xxx.forEach((course: Course) => {
          acc[course.courseDay].push({
            course: course as Course,
            term: curr.term,
          });
        });
        return acc;
      },
      {
        [EWeekDay.Sun]: [],
        [EWeekDay.Mon]: [],
        [EWeekDay.Tues]: [],
        [EWeekDay.Wed]: [],
        [EWeekDay.Thu]: [],
        [EWeekDay.Fri]: [],
        [EWeekDay.Sat]: [],
      }
    );

  const getCardClassName = (t: Term | undefined, c: Course | undefined) => {
    if (c && c?.availableCapacity <= 0) {
      return classes.disableSelectGrid;
    }
    // if (selectedCourseIds.find((cid) => c?.id === cid)) {
    //   return classes.selectedGrid;
    // }
    if (t?.level === ECourseLevel.Beginner) {
      return classes.begininerLevel;
    }
    if (
      t?.level === ECourseLevel.Intermediate1 ||
      t?.level === ECourseLevel.Intermediate2 ||
      t?.level === ECourseLevel.Intermediate3
    ) {
      return classes.intermediateLevel;
    }
    if (
      t?.level === ECourseLevel.Advanced1 ||
      t?.level === ECourseLevel.Advanced2 ||
      t?.level === ECourseLevel.Advanced3
    ) {
      return classes.advancedLevel;
    }
    if (t?.level === ECourseLevel.Private) {
      return classes.privateLevel;
    }
    if (t?.level.toLowerCase() === ECourseLevel.ELite.toLowerCase()) {
      return classes.eliteLevel;
    }
    return "";
  };

  return (
    <Paper className={classes.container}>
      {Object.keys(coursesInWkDay).map((wkDayName: string) => {
        return (
          <Card className={classes.card}>
            <CardHeader title={wkDayName} />
            <CardContent>
              <Grid container spacing={2} direction="column">
                {coursesInWkDay[wkDayName].length === 0 && (
                  <Typography> No lessons </Typography>
                )}
                {coursesInWkDay[wkDayName].map((c) => {
                  const isCourseSelected =
                    selectedCourseIds.findIndex(
                      (cid) => cid === c.course?.id
                    ) >= 0;

                  const checkboxColor = isCourseSelected
                    ? "secondary"
                    : "primary";

                  return (
                    <Grid
                      item
                      onClick={() => {
                        const foundIdx = selectedCourseIds.findIndex(
                          (cid) => cid == c.course?.id
                        );
                        if (c && c.course && c.course?.availableCapacity <= 0) {
                          return;
                        }
                        const bSelectCourse = foundIdx < 0;
                        setSelectedCourseIds((draft) => {
                          if (foundIdx >= 0) {
                            draft.splice(foundIdx, 1);
                          } else {
                            draft.push(c.course?.id);
                          }
                        });
                        if (onSelectedCourse) {
                          onSelectedCourse(
                            c as { course: Course; term: Term },
                            bSelectCourse
                          );
                        }
                      }}
                    >
                      <Card
                        style={{ padding: "10px" }}
                        className={getCardClassName(c.term, c.course)}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={2}>
                            <Checkbox
                              color={checkboxColor}
                              inputProps={{
                                "aria-label": "checkbox with default color",
                              }}
                              checked={isCourseSelected}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography> Level: {c.term?.level}</Typography>
                            <Typography>
                              {" "}
                              Term date: {c.term?.startDate} to{" "}
                              {c.term?.endDate}
                            </Typography>
                            <Typography>
                              Traing time: {c.course?.startTime} -{" "}
                              {c.course?.endTime}{" "}
                            </Typography>
                            <Typography>
                              Available: {c.course?.availableCapacity}
                            </Typography>
                            <Typography>
                              Total lessons:{" "}
                              {(c.course?.numOfLessons ?? 0) -
                                (c.course?.numOfPublicHolidays ?? 0)}
                            </Typography>
                            {/* <Typography>
                              Price: ${c.course?.unitPrice}
                            </Typography> */}
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Paper>
  );
}

export default withStyles(styles)(CourseListInWeekView);
