import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { IRoute, routes } from "../routes";
import NameInitial from "./NameInitial";

const ITEM_HEIGHT = 48;

const UserMenu = () => {
  const { user, userProfile } = React.useContext(AuthContext);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMaxHeight = (itemCount: number) => ITEM_HEIGHT * (itemCount + 1);

  const menuItems = routes.filter((route: IRoute) => route.onUserMenu);

  return (
    <Fragment>
      <IconButton
        aria-label="More"
        aria-owns={open ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <NameInitial user={userProfile} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            overflow: "hidden",
            maxHeight: getMaxHeight(menuItems.length),
            width: 200,
          },
        }}
      >
        {menuItems.map((route) => (
          <MenuItem
            key={route.path}
            onClick={() => {
              handleClose();
              history.push(route.path);
            }}
          >
            {route.title}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default UserMenu;
