import { withStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

const CustomizedDataGrid = withStyles({
  root: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "yellow",
    borderRadius: 130,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    row: {
      selected: {
        "&.hover": {
          backgroundColor: "red",
        },
      },
    },
  },
})(DataGrid);

export default CustomizedDataGrid;
