import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { addEnrolment, getEnrolments } from "../api/enrolment.service";
import { getHolidayCourses } from "../api/holidayCourse.service";
import { getPlayers } from "../api/player.service";
import HolidayCourseList from "../components/HolidayCourseList";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import EmptyInfo from "../EmptyInfo";
import {
  EEnrolmentType,
  ERole,
  HolidayCourse,
  Player,
} from "../generated/graphql";
import getPlayerName from "../utils/getPlayerName";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import HolidayEnrolmentList from "./HolidayEnrolmentList";
import PaymentForm from "./PaymentForm";
import { applyPaymentAmount } from "../utils/paymentAmount";
const styles = (theme: Theme) =>
  createStyles({
    container: {},
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

const steps = ["Please select player", "Please select a course", "Enrol now"];

function HolidayCourseEnrolment(props: Props) {
  const { classes } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { userProfile } = useContext(AuthContext);
  const [openEnrolmentDlg, setOpenEnrolmentDlg] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);
  const [loadedData, setLoadedData] = useState(false);
  const handleClose = () => {
    setOpenEnrolmentDlg(false);
  };

  const handleSubmit = async () => {
    // const paymentAmount = `${getTotalPrice(enrolmentData)}`;
    const courseIds = enrolmentData.selectedCourses?.map((c) => c.id);
    const playerIds = enrolmentData.selectedPlayers.map((p) => p.id);

    const addEnrolmentData = {
      type: EEnrolmentType.Holiday,
      userId: userProfile?.id,
      courseIds,
      playerIds,
      paymentAmount: `${
        enrolmentData.selectedCourses[0].price *
        enrolmentData.selectedPlayers?.length
      }`,
    };
    const result = await addEnrolment(addEnrolmentData);
    // showSuccessNotification("Enroled");
    setOpenEnrolmentDlg(false);
    // re-load enrolments
    const enrolments = await getEnrolments(
      userProfile?.role === ERole.Admin
        ? {}
        : { userId: userProfile?.id, type: EEnrolmentType.Holiday }
    );
    setEnrolmentData((draft) => {
      draft.enrolments.length = 0;
      draft.enrolments.push(...enrolments);
    });
    return result;
  };

  const [enrolmentData, setEnrolmentData] = useImmer<{
    courses: HolidayCourse[];
    players: any[];
    enrolments: any[];
    selectedPlayers: any[];
    selectedCourses: HolidayCourse[];
  }>({
    courses: [],
    players: [],
    enrolments: [],
    selectedPlayers: [],
    selectedCourses: [],
  });

  const [selectedPlayerList, setSelectedPlayerList] = useImmer<
    {
      id: string;
      selected: boolean;
    }[]
  >([]);

  const retrieveData = async () => {
    showLoading();
    setLoadedData(false);
    const param =
      userProfile?.role === ERole.Admin
        ? {}
        : { userId: userProfile?.id, type: EEnrolmentType.Holiday };
    const parentId = userProfile?.role === ERole.Admin ? "" : userProfile?.id;
    const [courses, players, enrolments] = await Promise.all([
      getHolidayCourses(),
      getPlayers({ parentId }),
      getEnrolments(param),
    ]);

    setEnrolmentData((draft) => {
      if (players) {
        draft.players = players;
      }
      if (enrolments) {
        draft.enrolments = enrolments;
      }
      draft.courses = courses.courses;
      hideLoading();
      setLoadedData(false);
    });
  };

  useEffect(() => {
    retrieveData();
  }, []);

  const handleSelectedCourse = (course: HolidayCourse) => {
    setEnrolmentData((draft) => {
      draft.selectedCourses = [course];
    });
  };
  const selectCourseStep = () => (
    <>
      <HolidayCourseList
        courses={enrolmentData.courses}
        onSelectedCourse={handleSelectedCourse}
      />
    </>
  );

  const handleSelectdPlayer = (player: Player) => (evt: any) => {
    setSelectedPlayerList((draft) => {
      const foundPlayer = draft.find((p) => p.id === player.id);
      if (foundPlayer) {
        foundPlayer.selected = !foundPlayer.selected;
      } else {
        draft.push({
          id: player.id,
          selected: true,
        });
      }
      setEnrolmentData((x) => {
        x.selectedPlayers = x.players.filter((xx) => {
          const f = draft.find((yy) => yy.id === xx.id && yy.selected);
          return !!f;
        });
      });
    });
  };

  const isPlayerSelected = (player: Player) =>
    // !!selectedPlayerList.find((p) => p.id === player.id && p.selected);
    !!enrolmentData.selectedPlayers.find((p) => p.id === player.id);

  const selectPlayerStep = () => (
    <List>
      {enrolmentData.players.map((player: Player) => (
        <ListItem
          key={player.id}
          role={undefined}
          dense
          button
          onClick={handleSelectdPlayer(player)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={isPlayerSelected(player)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText id={player.id} primary={getPlayerName(player)} />
        </ListItem>
      ))}
    </List>
  );

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return selectPlayerStep();
      case 1:
        return selectCourseStep();
      case 2:
        // return enrolmentDetailStep();
        return <Fragment />;
      default:
        return "Unknown step";
    }
  }

  const handleStepClick = (stepIndex: number) => () => {
    setActiveStep(stepIndex);
  };
  const isPlayersSelected = enrolmentData.selectedPlayers.length > 0;
  const isCourseSelected = enrolmentData.selectedCourses?.length > 0;

  const canBeEnroled = isCourseSelected && isPlayersSelected;
  return (
    <FormContainer>
      <FormTitle title="Holiday course enrolment" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {enrolmentData.enrolments?.length > 0 && (
              <HolidayEnrolmentList enrolmentData={enrolmentData} />
            )}
            {enrolmentData?.enrolments?.length === 0 && (
              <EmptyInfo title="There is no enrolments" />
            )}
          </Grid>
          {userProfile?.role === ERole.Parent && (
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={enrolmentData.courses?.length === 0}
                onClick={() => {
                  // clear all the enrolmentData
                  setEnrolmentData((draft) => {
                    draft.selectedCourses = [];
                    draft.selectedPlayers = [];
                  });
                  setOpenEnrolmentDlg(true);
                }}
              >
                Enrol a holiday course
              </Button>
            </Grid>
          )}
        </FormRow>
      </Grid>
      {
        <Dialog
          open={openEnrolmentDlg}
          onClose={handleClose}
          fullWidth
          aria-labelledby="max-width-dialog-title"
          classes={{ paperWidthSm: classes.paperWidthSm }}
        >
          <DialogTitle onClose={handleClose}>
            <Typography variant="h6">Enrol</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant={"h4"}> Please select players</Typography>
                {selectPlayerStep()}
              </Grid>
              <Grid item>
                <Typography variant={"h4"}>
                  Please select a holiday course you want to enrol
                </Typography>
                {selectCourseStep()}
              </Grid>
              <Grid item xs={12}>
                {canBeEnroled && (
                  <PaymentForm
                    amount={
                      selectedPlayerList.length *
                      enrolmentData.selectedCourses[0].price
                    }
                    totalAmount={applyPaymentAmount(
                      selectedPlayerList.length *
                        enrolmentData.selectedCourses[0].price
                    )}
                    submitForm={() => {
                      return handleSubmit();
                    }}
                    onResult={({ succeed, paymentId }) => {
                      if (succeed && paymentId) {
                      }
                    }}
                  ></PaymentForm>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      }
    </FormContainer>
  );
}

export default withStyles(styles)(HolidayCourseEnrolment);
