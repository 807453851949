import {
  GetUserProfileDocument,
  GetUserProfileQuery,
  SaveUserProfileDocument,
  SaveUserProfileInput,
  SaveUserProfileMutation,
  SendMessageDocument,
  SendMessageInput,
  SendMessageMutation,
  UpdateUserProfileDocument,
  UpdateUserProfileInput,
  UpdateUserProfileMutation,
} from "../generated/graphql";
import { client } from "./client";

export const sendMessage = async (param: SendMessageInput) => {
  return (
    await client.mutate<SendMessageMutation>({
      mutation: SendMessageDocument,
      variables: {
        param,
      },
    })
  ).data?.sendMessage;
};
