import { Button, Grid, TextField } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import {
  addHolidayCourse,
  deleteHolidayCourse,
  setHolidayCourseStatus,
  updateHolidayCourse,
} from "../api/holidayCourse.service";
import {
  addTournament,
  changeTournamentStatus,
  deleteTournament,
  updateTournament,
} from "../api/tournament.service";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import {
  AddHolidayCourseInput,
  AddHolidayCourseResp,
  AddTournamentInput,
  ECourseStatus,
  EStatus,
  HolidayCourse,
  Tournament,
  UpdateHolidayCourseInput,
  UpdateHolidayCourseResp,
  UpdateTournamentInput,
} from "../generated/graphql";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import { DateTime } from "luxon";
const styles = (theme: Theme) => createStyles({});

export interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  onNewTournament: (tourament: Tournament) => void;
  onTournamentUpdated: (tourament: Tournament) => void;
  onTournamentDeleted: (id: string) => void;
  tournament: Tournament | null;
}

function TournamentDetail({
  classes,
  onClose,
  onNewTournament,
  onTournamentUpdated,
  onTournamentDeleted,
  tournament,
}: Props) {
  const isNewTournament = !!!tournament;

  const initialValues = {
    startDate: tournament?.startDate ?? DateTime.now().toISODate(),
    name: tournament?.name ?? "",
    price: tournament?.price ?? 20,
  };
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotificationContext);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Enter your touranment name"),
    price: yup.number().required("Enter price"),
  });
  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (v) => {
      const { startDate, name, price } = v;

      try {
        showLoading();
        if (isNewTournament) {
          const addTournamentParam: AddTournamentInput = {
            name,
            price,
            startDate,
          };
          const createdTournament = await addTournament(addTournamentParam);
          showSuccessNotification("Tournament created");
          if (createdTournament) {
            onNewTournament(createdTournament);
          }
        } else {
          const updateParam: UpdateTournamentInput = {
            id: tournament?.id,
            name,
            price,
            startDate,
          };
          const updatedTournament = await updateTournament(updateParam);
          showSuccessNotification("Tournament updated");
          if (updatedTournament) {
            onTournamentUpdated(updatedTournament);
          }
        }
        onClose();
      } catch (e) {
        showErrorNotification("please try again later");
      } finally {
        hideLoading();
      }
    },
  });

  const handleDisableTournament = (status: EStatus) => async () => {
    await changeTournamentStatus({ id: tournament?.id, status });
    // onTournamentDeleted(tournament?.id);
    onClose();
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
        >
          <FormRow>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                label="Tournament name"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="startDate"
                name="startDate"
                label="Tournament start Date"
                type="date"
                value={values.startDate}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="price"
                name="price"
                label="Tournament price"
                type="number"
                value={values.price}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                {isNewTournament
                  ? "Save a new tournament"
                  : "Update tournament"}
              </Button>
            </Grid>
            {tournament && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleDisableTournament(
                    tournament.status === EStatus.Inactive
                      ? EStatus.Active
                      : EStatus.Inactive
                  )}
                  fullWidth
                >
                  {tournament.status === EStatus.Active
                    ? "Disable this tournament"
                    : "Enable this tournament"}
                </Button>
              </Grid>
            )}
          </FormRow>
        </Grid>
      </form>
    </FormContainer>
  );
}

export default withStyles(styles)(TournamentDetail);
