import { ERole } from "../generated/graphql";
import { IRoute } from "../routes";

const filterByRole = (role: ERole) => (route: IRoute) =>
  route.access.indexOf(role) !== -1;

const filterByNavMenu = (route: IRoute) => route.onNavMenu;

export const onNavMenuRoutes = (allRoutes: IRoute[], role: ERole) =>
  allRoutes.filter(filterByNavMenu).filter(filterByRole(role));
