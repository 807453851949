import { Grid, Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { holidayCampsTimeTableHtml } from "../htmls/holidayCampsTimeTableHtml";

const styles = (theme: Theme) => {
  return createStyles({
    main: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    clsImage: {
      maxWidth: "300px",
    },
    container: {
      marginLeft: "50px",
      marginRight: "50px",
    },
  });
};
interface Props extends WithStyles<typeof styles> {}

const HolidayCampsTimeTable = ({ classes }: Props) => {
  const htmlDoc = { __html: holidayCampsTimeTableHtml };

  return <Paper dangerouslySetInnerHTML={htmlDoc}></Paper>;
};

export default withStyles(styles)(HolidayCampsTimeTable);
