import { createMuiTheme } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#fbc02d",
      main: "#8a84e0",
      dark: "#005db3",
    },
    secondary: {
      light: "#fbc02d",
      main: "#f38220",
      dark: "#005db3",
    },
  },
  typography: {
    h4: {
      fontWeight: 499,
      fontSize: 25,
      letterSpacing: -1.5,
    },
  },
  shape: {
    borderRadius: 7,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 47,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "2rem",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "white",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        fontSize: "16px",
        height: "50px",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(0),
      },
      indicator: {
        height: 2,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "-1 16px",
        minWidth: -1,
        padding: -1,
        [theme.breakpoints.up("md")]: {
          padding: -1,
          minWidth: -1,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(0),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 3,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404853",
      },
    },
    MuiListItem: {
      root: {
        selected: {
          backgroundColor: "#ea4335",
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.h4.fontWeight,
        fontSize: 18,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: -1,
        "& svg": {
          fontSize: 19,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 31,
        height: 32,
      },
    },
  },
};

export default theme;
