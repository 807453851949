import {
  AddTournamentDocument,
  AddTournamentEnrolmentDocument,
  AddTournamentEnrolmentInput,
  AddTournamentEnrolmentMutation,
  AddTournamentInput,
  AddTournamentMutation,
  ChangeTournamentInput,
  ChangeTournamentStatusDocument,
  ChangeTournamentStatusMutation,
  DeleteTournamentDocument,
  DeleteTournamentInput,
  DeleteTournamentMutation,
  GetTournamentEnrolmentsDocument,
  GetTournamentEnrolmentsInput,
  GetTournamentEnrolmentsQuery,
  GetTournamentsDocument,
  GetTournamentsQuery,
  UpdateTournamentDocument,
  UpdateTournamentInput,
  UpdateTournamentMutation,
} from "../generated/graphql";
import { client } from "./client";

export const addTournament = async ({
  price,
  name,
  startDate,
}: AddTournamentInput) =>
  (
    await client.mutate<AddTournamentMutation>({
      mutation: AddTournamentDocument,
      variables: {
        param: {
          startDate,
          name,
          price,
        },
      },
    })
  ).data?.addTournament;

export const updateTournament = async ({
  id,
  price,
  name,
  startDate,
}: UpdateTournamentInput) =>
  (
    await client.mutate<UpdateTournamentMutation>({
      mutation: UpdateTournamentDocument,
      variables: {
        param: {
          id,
          name,
          startDate,
          price,
        },
      },
    })
  ).data?.updateTournament;

export const getTournaments = async () =>
  (
    await client.query<GetTournamentsQuery>({
      query: GetTournamentsDocument,
    })
  ).data?.getTournaments;

export const getTournamentEnrolments = async (
  param: GetTournamentEnrolmentsInput
) =>
  (
    await client.query<GetTournamentEnrolmentsQuery>({
      query: GetTournamentEnrolmentsDocument,
      variables: {
        param,
      },
    })
  ).data?.getTournamentEnrolments;

export const addTournamentEnrolment = async ({
  userId,
  playerIds,
  tournamentIds,
  amount,
  totalAmount,
}: AddTournamentEnrolmentInput) =>
  (
    await client.mutate<AddTournamentEnrolmentMutation>({
      mutation: AddTournamentEnrolmentDocument,
      variables: {
        param: {
          userId,
          playerIds,
          tournamentIds,
          amount,
          totalAmount,
        },
      },
    })
  ).data?.addTournamentEnrolment;

export const deleteTournament = async ({ id }: DeleteTournamentInput) =>
  (
    await client.mutate<DeleteTournamentMutation>({
      mutation: DeleteTournamentDocument,
      variables: {
        param: {
          id,
        },
      },
    })
  ).data?.deleteTournament;

export const changeTournamentStatus = async ({
  id,
  status,
}: ChangeTournamentInput) =>
  (
    await client.mutate<ChangeTournamentStatusMutation>({
      mutation: ChangeTournamentStatusDocument,
      variables: {
        param: {
          id,
          status,
        },
      },
    })
  ).data?.changeTournamentStatus;
