import { Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RowModel, RowParams } from "@material-ui/data-grid";
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { GetTournamentEnrolmentsResp, ERole } from "../generated/graphql";
import CustomizedDataGrid from "./CustomizedDataGrid";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "70vh",
      overflowY: "auto",
      width: "95%",
    },
    root: {
      backgroundColor: "yellow",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  tournamentEnrolments: GetTournamentEnrolmentsResp[];
  onSelectedEnrolment?: (selectedEnrolmnt: GetTournamentEnrolmentsResp) => void;
}

export interface IHolidayCourseRow {
  id: string;
  date: string;
  time: string;
}

function TournamentEnrolmentList(props: Props) {
  const { userProfile } = useContext(AuthContext);
  const { classes, tournamentEnrolments, onSelectedEnrolment } = props;

  const handleRowClick = ({ rowIndex }: RowParams) => {
    if (onSelectedEnrolment) {
      onSelectedEnrolment(tournamentEnrolments[rowIndex]);
    }
  };
  const columns = [
    { field: "name", headerName: "Tournament", width: 170 },
    { field: "players", headerName: "Players", width: 100 },
    { field: "price", headerName: "Price", width: 100 },
    { field: "date", headerName: "Date", width: 170 },
    { field: "status", headerName: "Status", width: 170 },
  ];
  if (userProfile?.role === ERole.Admin) {
    columns.push({
      field: "enrolDate",
      headerName: "EnrolDate",
      width: 170,
    });
  }

  const rows: RowModel[] = [];
  tournamentEnrolments
    .filter((enrolment) => enrolment.tournaments.length > 0)
    .forEach((enrolment, enrolmentIdx) => {
      rows.push(
        ...enrolment.tournaments.map((x, idx) => ({
          id: `${enrolmentIdx}-${enrolment?.tournamentIds?.[idx]}`,
          name: x.name,
          date: x.startDate,
          price: x.price,
          players: enrolment.players
            .map((x) => `${x.firstName} ${x.lastName}`)
            .join(";"),
          enrolDate: enrolment.enrolDate,
          status: enrolment.status,
        }))
      );
    });

  return (
    <Paper className={classes.container}>
      <CustomizedDataGrid
        rows={rows}
        columns={columns}
        autoPageSize
        onRowClick={handleRowClick}
        autoHeight
      />
    </Paper>
  );
}

export default withStyles(styles)(TournamentEnrolmentList);
