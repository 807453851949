import { Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { RowParams } from "@material-ui/data-grid";
import React from "react";
import { HolidayCourse } from "../generated/graphql";
import CustomizedDataGrid from "./CustomizedDataGrid";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "40vh",
      overflowY: "auto",
      width: "95%",
      margin: "8px",
    },
    root: {
      backgroundColor: "yellow",
    },
  });

export interface Props extends WithStyles<typeof styles> {
  courses: HolidayCourse[];
  onSelectedCourse?: (selectedCourse: HolidayCourse) => void;
}

export interface IHolidayCourseRow {
  id: string;
  date: string;
  time: string;
}

function HolidayCourseList(props: Props) {
  const { classes, courses, onSelectedCourse } = props;
  const columns = [
    { field: "date", headerName: "Date", width: 170 },
    { field: "time", headerName: "Time", width: 170 },
    { field: "price", headerName: "Price", width: 100 },
    { field: "capacity", headerName: "Capacity", width: 100 },
    { field: "status", headerName: "Status", width: 80 },
  ];

  const getCourseDate = ({ startDate, endDate }: HolidayCourse) =>
    `${startDate} - ${endDate}`;
  const getCourseTime = ({ startTime, endTime }: HolidayCourse) =>
    `${startTime} - ${endTime}`;

  const rows: IHolidayCourseRow[] = courses.map((course: HolidayCourse) => ({
    id: course.id,
    date: getCourseDate(course),
    time: getCourseTime(course),
    price: `$${course.price}`,
    capacity: course.capacity,
    status: course.status,
  }));

  const handleRowClick = ({ rowIndex }: RowParams) => {
    if (onSelectedCourse) {
      onSelectedCourse(courses[rowIndex]);
    }
  };

  return (
    <Paper className={classes.container}>
      <CustomizedDataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        onRowClick={handleRowClick}
        autoHeight
        classes={{
          root: classes.root,
        }}
      />
    </Paper>
  );
}

export default withStyles(styles)(HolidayCourseList);
