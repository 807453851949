import { createStyles, Paper, Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    container: {
      minHeight: 48,
      margin: spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: palette.info.light,
    },
  });

export interface IProps extends WithStyles<typeof styles> {
  text: string;
}

const InformationBox = ({ classes, text }: IProps) => {
  return (
    <Paper className={classes.container}>
      <Typography> {text} </Typography>
    </Paper>
  );
};
export default withStyles(styles)(InformationBox);
