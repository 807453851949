import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { addEnrolment, getEnrolments } from "../api/enrolment.service";
import { getHolidayCourses } from "../api/holidayCourse.service";
import { getPlayers } from "../api/player.service";
import HolidayCourseList from "./HolidayCourseList";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import EmptyInfo from "../EmptyInfo";
import { Tournament } from "../generated/graphql";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import TournamentDetail from "./TournamentDetail";
import TournamentList from "./TournamentList";
import { getTournaments } from "../api/tournament.service";
const styles = (theme: Theme) =>
  createStyles({
    container: {},
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

const steps = ["Please select player", "Please select a course", "Enrol now"];

function Tournaments(props: Props) {
  const { classes } = props;
  const [tournaments, setTournaments] = useImmer<Tournament[]>([]);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [addNewTournament, setAddNewTournament] = useState(false);
  const [selectedTournament, setSelectedTournament] =
    useState<Tournament | null>(null);

  const handleAddNewTournment = () => {
    setAddNewTournament(true);
  };

  const handleNewTournmentAdded = (newTournment: Tournament) => {
    setTournaments((draft: Tournament[]) => {
      draft.push(newTournment);
    });
  };

  const handleSelectedTournament = (tournament: Tournament) => {
    setSelectedTournament(tournament);
  };

  const handleClose = () => {
    setAddNewTournament(false);
    setSelectedTournament(null);
  };

  const handleUpdated = (updatedTournament: Tournament) => {
    setTournaments((draft: Tournament[]) => {
      const idx = draft.findIndex(
        (t: Tournament) => t.id === updatedTournament.id
      );
      draft[idx] = updatedTournament;
    });
  };

  const handleDeleted = () => {};

  const retrieveTournaments = async () => {
    showLoading();

    const tournaments = (await getTournaments()) as Tournament[];
    setTournaments((draft: Tournament[]) => {
      if (tournaments) {
        draft.push(...tournaments);
      }
    });
    hideLoading();
  };

  useEffect(() => {
    retrieveTournaments();
  }, []);

  const getDialogTitle = () => (
    <Typography>
      {selectedTournament ? "Update tourament" : "Add a new tournament"}
    </Typography>
  );

  return (
    <FormContainer>
      <FormTitle title="Tournaments" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {tournaments.length > 0 && (
              <TournamentList
                tournaments={tournaments}
                onSelectedTournament={handleSelectedTournament}
              />
            )}
            {tournaments.length === 0 && (
              <EmptyInfo title="Please add a new tournment." />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleAddNewTournment}
            >
              Add a new tournment
            </Button>
          </Grid>
        </FormRow>
      </Grid>
      <Dialog
        fullWidth
        open={addNewTournament || selectedTournament !== null}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle onClose={handleClose}>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <TournamentDetail
            onClose={handleClose}
            onNewTournament={handleNewTournmentAdded}
            onTournamentUpdated={handleUpdated}
            onTournamentDeleted={handleDeleted}
            tournament={selectedTournament}
          />
        </DialogContent>
      </Dialog>
    </FormContainer>
  );
}

export default withStyles(styles)(Tournaments);
