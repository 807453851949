import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { DataGrid, RowSelectedParams } from "@material-ui/data-grid";
import React, { useState } from "react";
import { EGender, EnrolmentResult } from "../generated/graphql";
import formatDate from "../utils/formatDate";
import getPlayerName from "../utils/getPlayerName";
import DialogTitle from "./DialogTitle";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "40vh",
      overflowY: "auto",
      width: "95%",
    },
  });

export interface IHolidayEnrolmentRow {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: EGender;
  school: string;
  disability: boolean;
  parentId: string;
}

interface Props extends WithStyles<typeof styles> {
  enrolmentData: any;
}

const getRowsColumns = (enrolmentData: any) => {
  const { enrolments, players, courses } = enrolmentData;
  const rows = enrolments.map((enrolment: EnrolmentResult) => {
    const { id, playerIds, courseIds, enrolDate } = enrolment.enrolment;
    const { status, reference } = enrolment.payment;
    const playersName = players
      .filter((p: any) => playerIds.indexOf(p.id) >= 0)
      .map(getPlayerName)
      .join(",");

    return {
      id,
      playersName,
      enrolDate: formatDate(enrolDate),
      status,
    };
  });
  const columns = [
    { field: "playersName", headerName: "Players", width: 300 },
    { field: "enrolDate", headerName: "Enrol date", width: 140 },
    { field: "status", headerName: "Payment", width: 120 },
  ];
  return {
    rows,
    columns,
  };
};

const HolidayEnrolmentList = ({ classes, enrolmentData }: Props) => {
  const { enrolments } = enrolmentData;
  const { rows, columns } = getRowsColumns(enrolmentData);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedEnrolment, setSelectedEnrolment] = useState<
    EnrolmentResult | undefined
  >(undefined);
  const handleRowSelected = (rowData: RowSelectedParams) => {
    const foundElement = enrolments.find(
      (x: any) => x.enrolment.id === rowData.data.id
    );
    if (foundElement) {
      setSelectedEnrolment(foundElement);
      setShowDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const getSelectedPlayersName = () => {
    return enrolmentData.players
      .filter(
        (p: any) =>
          selectedEnrolment &&
          selectedEnrolment?.enrolment?.playerIds?.indexOf(p.id) >= 0
      )
      .map(getPlayerName)
      .join(",");
  };

  return (
    <Paper className={classes.container}>
      {enrolments.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={80}
          autoHeight
          onRowSelected={handleRowSelected}
        />
      )}
      <Dialog
        open={showDialog}
        fullWidth
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle onClose={handleCloseDialog}>
          <Typography variant="h6">Enrolment detail</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {" "}
                Players: {getSelectedPlayersName()}
              </Typography>
            </Grid>
            <Grid container item xs={12} direction="column">
              {selectedEnrolment?.enrolment.courseIds.map((id) => {
                const foundEnrolmentData = enrolmentData.courses.find(
                  (c: any) => c.id === id
                );
                const detailCourse = enrolmentData?.courses.find(
                  (c: any) => c.id === id
                );
                return (
                  <Grid item container direction="row" spacing={2}>
                    <Grid item>
                      <Typography variant="h5">
                        Holiday course: from {foundEnrolmentData?.startDate} to{" "}
                        {foundEnrolmentData?.endDate} {detailCourse?.startTime}{" "}
                        to {detailCourse?.endTime}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {" "}
                Fee: ${selectedEnrolment?.payment.amount}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Status: {selectedEnrolment?.payment.status}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Phone: {selectedEnrolment?.user?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Email: {selectedEnrolment?.user?.email}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleCloseDialog}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(HolidayEnrolmentList);
