import {
  AddEnrolmentDocument,
  AddEnrolmentInput,
  AddEnrolmentMutation,
  DeclineEnrolmentDocument,
  DeclineEnrolmentInput,
  DeclineEnrolmentMutation,
  GetEnrolmentsDocument,
  GetEnrolmentsInput,
  GetEnrolmentsQuery,
} from "../generated/graphql";
import { client } from "./client";

export const getEnrolments = async ({
  userId,
  playerId,
  ...rest
}: GetEnrolmentsInput) => {
  return (
    await client.query<GetEnrolmentsQuery>({
      query: GetEnrolmentsDocument,
      variables: {
        param: {
          type: rest.type ?? undefined,
          userId,
          playerId,
        },
      },
    })
  ).data.getEnrolments;
};

export const addEnrolment = async ({
  courseIds,
  playerIds,
  type,
  userId,
  paymentAmount,
}: AddEnrolmentInput) =>
  (
    await client.mutate<AddEnrolmentMutation>({
      mutation: AddEnrolmentDocument,
      variables: {
        param: {
          userId,
          type,
          courseIds,
          playerIds,
          paymentAmount,
        },
      },
    })
  ).data?.addEnrolment;

export const declineEnrolment = async ({ id }: DeclineEnrolmentInput) =>
  await client.mutate<DeclineEnrolmentMutation>({
    mutation: DeclineEnrolmentDocument,
    variables: {
      param: {
        id,
      },
    },
  });
