import {
  AddPlayerDocument,
  AddPlayerInput,
  AddPlayerMutation,
  GetPlayersDocument,
  GetPlayersInput,
  GetPlayersQuery,
  SavePlayerDocument,
  SavePlayerInput,
  SavePlayerMutation,
} from "../generated/graphql";
import { client } from "./client";

export const getPlayers = async ({ parentId }: GetPlayersInput) => {
  return (
    await client.query<GetPlayersQuery>({
      query: GetPlayersDocument,
      variables: {
        param: {
          parentId,
        },
      },
    })
  ).data.getPlayers;
};

export const addPlayer = async ({
  firstName,
  lastName,
  school,
  gender,
  dob,
  parentId,
  disability,
  disabilityClass,
  disabilityDescription,
}: AddPlayerInput) =>
  (
    await client.mutate<AddPlayerMutation>({
      mutation: AddPlayerDocument,
      variables: {
        param: {
          firstName,
          lastName,
          school,
          gender,
          dob,
          parentId,
          disability,
          disabilityClass,
          disabilityDescription,
        },
      },
    })
  ).data?.addPlayer;

export const savePlayer = async ({
  playerId,
  firstName,
  lastName,
  school,
  gender,
  dob,
  parentId,
  disability,
  disabilityClass,
  disabilityDescription,
}: SavePlayerInput) =>
  (
    await client.mutate<SavePlayerMutation>({
      mutation: SavePlayerDocument,
      variables: {
        param: {
          playerId,
          firstName,
          lastName,
          school,
          gender,
          dob,
          parentId,
          disability,
          disabilityClass,
          disabilityDescription,
        },
      },
    })
  ).data?.savePlayer;
