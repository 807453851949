import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { addPlayer, savePlayer } from "../api/player.service";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import { EGender, Player, SavePlayerInput } from "../generated/graphql";
import ERoutePath from "../routes/ERoutePath";
import { firstNameValidator, lastNameValidator } from "../utils/validator";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import GenderSelect from "./GenderSelect";

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    paperWidthSm: {
      maxWidth: "80em",
      maxHeight: "30rem",
    },
  });
interface IProfileInput {
  firstName: string;
  lastName: string;
  dob: string;
  gender: EGender;
  school: string;
  hasDisability: string;
  disabilityClass?: string;
  disabilityDescription?: string;
}

export interface Props extends WithStyles<typeof styles> {
  player?: Player;
  isAddingNewPlayer: boolean;
  onClose: (player: Player | undefined) => void;
}

const validationsForm = yup.object().shape({
  firstName: firstNameValidator,
  lastName: lastNameValidator,
});

function PlayerComponent({
  isAddingNewPlayer,
  player,
  onClose,
  classes,
}: Props) {
  const initialValues: IProfileInput = {
    firstName: player?.firstName ?? "",
    lastName: player?.lastName ?? "",
    gender: player?.gender ?? EGender.Male,
    dob: player?.dob ?? "2000-01-01",
    school: player?.school ?? "",
    hasDisability: player?.disability ? "yes" : "no",
    disabilityClass: player?.disabilityClass ?? "",
    disabilityDescription: player?.disabilityDescription ?? "",
  };
  const { showSuccessNotification } = useContext(NotificationContext);
  const history = useHistory();
  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    validationSchema: validationsForm,
    onSubmit: async (values) => {
      const {
        firstName = "",
        lastName = "",
        dob = "",
        gender = EGender.Female,
        disabilityClass = "",
        disabilityDescription = "",
        hasDisability = "no",
        school,
      } = values;
      const disability = hasDisability === "yes";
      let playerResult;
      let msg;
      const playerInfo = {
        firstName,
        lastName,
        dob,
        gender,
        disability,
        disabilityClass,
        disabilityDescription,
        parentId: userProfile?.id,
        school,
      };
      if (isAddingNewPlayer) {
        playerResult = await addPlayer({
          ...playerInfo,
        } as SavePlayerInput);
        msg = "Added a new player, you can enrol a course now.";
      } else {
        playerResult = await savePlayer({
          playerId: player?.id,
          ...playerInfo,
        } as SavePlayerInput);
        msg = "Player updated";
      }
      showSuccessNotification(msg);
      // call parent to close the dialog
      onClose(playerResult);
      history.push(ERoutePath.enrolments);
    },
  });

  const { userProfile, isAdminRole, isParentRole } = useContext(AuthContext);

  return (
    <FormContainer>
      <Dialog
        open
        fullWidth
        aria-labelledby="max-width-dialog-title"
        classes={{
          paperWidthSm: classes.paperWidthSm,
        }}
      >
        <DialogTitle
          onClose={() => {
            onClose(undefined);
          }}
        >
          <Typography variant="h6">
            {isAddingNewPlayer ? "Add new player" : "Update player"}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2} alignItems="center">
              <FormRow>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    fullWidth
                    label="Family Name"
                    value={values.lastName}
                    onChange={handleChange}
                    helperText={touched.firstName ? errors.firstName : ""}
                    error={touched.firstName && Boolean(errors.firstName)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    fullWidth
                    label="Given Name"
                    value={values.firstName}
                    onChange={handleChange}
                    helperText={touched.firstName ? errors.firstName : ""}
                    error={touched.firstName && Boolean(errors.firstName)}
                  ></TextField>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="dob"
                    name="dob"
                    label="Date of birth"
                    type="date"
                    value={values.dob}
                    onChange={handleChange}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <GenderSelect
                    name="gender"
                    fullWidth
                    label="Gender"
                    value={values.gender}
                    onChange={handleChange}
                  />
                </Grid>
              </FormRow>
              <FormRow>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="school"
                    fullWidth
                    label="School"
                    value={values.school}
                    onChange={handleChange}
                    helperText={touched.school ? errors.school : ""}
                    error={touched.school && Boolean(errors.school)}
                  ></TextField>
                </Grid>
              </FormRow>
              <FormRow>
                <Grid item xs={12}>
                  <TextField
                    id="hasDisability"
                    name="hasDisability"
                    select
                    label="Do you have a disability?"
                    fullWidth
                    value={values.hasDisability}
                    onChange={handleChange}
                    variant={"outlined"}
                  >
                    <MenuItem key="yes" value={"yes"}>
                      Yes
                    </MenuItem>
                    <MenuItem key="no" value={"no"}>
                      No
                    </MenuItem>
                  </TextField>
                </Grid>
              </FormRow>
              {values.hasDisability === "yes" && (
                <FormRow>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      name="disabilityClass"
                      fullWidth
                      label="Class"
                      value={values.disabilityClass}
                      onChange={handleChange}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      name="disabilityDescription"
                      fullWidth
                      label="Description"
                      value={values.disabilityDescription}
                      onChange={handleChange}
                    ></TextField>
                  </Grid>
                </FormRow>
              )}
              <FormRow>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    {isAddingNewPlayer ? "Add a new player" : "Update player"}
                  </Button>
                </Grid>
              </FormRow>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </FormContainer>
  );
}

export default withStyles(styles)(PlayerComponent);
