import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { DEFAULT_EMAIL } from "../const";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import ERoutePath from "../routes/ERoutePath";
import { emailValidator } from "../utils/validator";
import DialogTitle from "./DialogTitle";
import EmailTextInput from "./EmailTextInput";
import FormRow from "./FormRow";

const validationsForm = yup.object().shape({
  email: emailValidator,
});

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dlgAction: {
      justifyContent: "center",
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface IForgotPasswordValue {
  email: string;
}

const ForgotPassword = (props: Props) => {
  const history = useHistory();
  const { showSuccessNotification, showErrorNotification } = useContext(
    NotificationContext
  );
  const { isAuthenticated, forgotPassword } = useContext(AuthContext);
  const initialValues: IForgotPasswordValue = {
    email: DEFAULT_EMAIL,
  };
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema: validationsForm,
    onSubmit: async ({ email }) => {
      const result = await forgotPassword(email);
      if (result.success) {
        showSuccessNotification(
          "Please check your email to reset your password"
        );
        history.push(ERoutePath.home);
      } else {
        showErrorNotification(
          result.error ?? "Unable to sign in, please try again later."
        );
      }
    },
  });
  const { classes } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const welcomeMessage = `Reset password message will be sent to below email`;
  if (isAuthenticated) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={handleClose}>
          <Typography> Forgot password </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            spacing={4}
            style={{ minHeight: 300 }}
          >
            <Grid item>
              <Typography align="center">{welcomeMessage}</Typography>
            </Grid>
            <FormRow>
              <Grid item>
                <EmailTextInput
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  fullWidth
                />
              </Grid>
            </FormRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                Forgot password
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(ForgotPassword);
