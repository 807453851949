import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { format } from "date-fns";
import React, { ChangeEvent, Fragment, useState } from "react";
import { useImmer } from "use-immer";
import { EWeekDay } from "../generated/graphql";
import { ICourseLessonDay } from "../utils/termLessonCalculation";
import FormRow from "./FormRow";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    activeCard: {
      background: theme.palette.primary.light,
    },
    inactiveCard: {
      background: theme.palette.common.white,
    },
    notAvailableDay: {
      background: "red",
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  });

export interface ICourseDayData {
  courseDay: EWeekDay;
  lessonStartTime: string;
  lessonEndTime: string;
  capacity: number;
  unitPrice: number;
  numOfLessons: number;
  available: boolean;
  numOfPublicHolidays?: number;
}
export interface ICourseDaysData {
  [name: string]: ICourseDayData;
}

export interface Props extends WithStyles<typeof styles> {
  termDays: ICourseLessonDay[];
  startDate: string;
  courseDaysData: ICourseDaysData;
  onChange: (e: ChangeEvent) => void;
  updateCourseDaysAvailable?: (day: EWeekDay, available: boolean) => void;
}

function CourseDayDetail({
  classes,
  termDays,
  startDate,
  courseDaysData,
  onChange,
  updateCourseDaysAvailable,
}: Props) {
  const [expanded, setExpaned] = useImmer<{ [name: string]: boolean }>({});
  const [availableDays, setAvailableDays] = useImmer(
    termDays.filter((d) => d.available).map((d) => d.day)
  );
  const [activeCard, setActiveCard] = useState(termDays[0].day);
  const handleExpandClick = (day: string) => () => {
    setExpaned((draft) => {
      draft[day] = !draft[day];
    });
  };
  const showLessonDates = (termDay: ICourseLessonDay) => {
    return (
      <Fragment>
        {termDay.dates.map((date: Date, idx: number) => {
          return (
            <Typography key={idx}> {format(date, "yyyy-MM-dd")}</Typography>
          );
        })}
      </Fragment>
    );
  };

  const handleCardClick = (day: EWeekDay) => () => {
    setActiveCard(day);
  };

  return (
    <FormRow>
      {termDays.map((termDay: ICourseLessonDay, index: number) => {
        const actualNumOfLessons =
          termDay.num - (courseDaysData[termDay.day].numOfPublicHolidays ?? 0);

        return (
          <Grid key={index} item xs={12} sm={6}>
            <Card
              onClick={handleCardClick(termDay.day)}
              className={
                availableDays.findIndex((ad) => ad === termDay.day) >= 0
                  ? activeCard === termDay.day
                    ? classes.activeCard
                    : classes.inactiveCard
                  : classes.notAvailableDay
              }
            >
              <CardHeader
                title={termDay.day}
                subheader={`${actualNumOfLessons} lessons`}
              />
              <CardContent>
                <Grid container spacing={2}>
                  <FormRow>
                    <Grid item xs={12}>
                      <TextField
                        id="publicHolidays"
                        name={`courseDaysData[${termDay.day}].numOfPublicHolidays`}
                        label="Num of public holidays"
                        type="number"
                        value={
                          courseDaysData[termDay.day].numOfPublicHolidays ?? 0
                        }
                        onChange={onChange}
                        variant="outlined"
                      />
                    </Grid>
                  </FormRow>
                  <FormRow>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="lessonStartTime"
                        name={`courseDaysData[${termDay.day}].lessonStartTime`}
                        label="Lesson start time"
                        type="time"
                        value={courseDaysData[termDay.day].lessonStartTime}
                        onChange={onChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="lessonEndTime"
                        name={`courseDaysData[${termDay.day}].lessonEndTime`}
                        label="Lesson end time"
                        type="time"
                        value={courseDaysData[termDay.day].lessonEndTime}
                        onChange={onChange}
                        variant="outlined"
                      />
                    </Grid>
                  </FormRow>
                  <FormRow>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="capacity"
                        name={`courseDaysData[${termDay.day}].capacity`}
                        label="Capacity"
                        type="number"
                        onChange={onChange}
                        value={courseDaysData[termDay.day].capacity}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="unitPrice"
                        name={`courseDaysData[${termDay.day}].unitPrice`}
                        label="Price"
                        type="number"
                        onChange={onChange}
                        value={courseDaysData[termDay.day].unitPrice}
                        variant="outlined"
                      />
                    </Grid>
                  </FormRow>
                  <Grid item hidden>
                    <TextField
                      id="lessonStartTime"
                      name={`courseDaysData[${termDay.day}].numOfLessons`}
                      label="Lessons"
                      type="text"
                      value={courseDaysData[termDay.day].numOfLessons}
                      onChange={onChange}
                      variant="outlined"
                    />
                    <TextField
                      id="available"
                      name={`courseDaysData[${termDay.day}].available`}
                      label=""
                      type=""
                      value={courseDaysData[termDay.day].available}
                      onChange={onChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing>
                <Tooltip
                  title={
                    termDay.available
                      ? "Make it unavailable"
                      : "Make it available"
                  }
                >
                  <IconButton aria-label="add to favorites">
                    <CloseIcon
                      onClick={() => {
                        setAvailableDays((draft) => {
                          const found = draft.findIndex(
                            (c) => c === termDay.day
                          );
                          let isAvailable = false;
                          if (found >= 0) {
                            draft.splice(found, 1);
                          } else {
                            draft.push(termDay.day);
                            isAvailable = true;
                          }
                          if (updateCourseDaysAvailable) {
                            updateCourseDaysAvailable(termDay.day, isAvailable);
                          }
                          // courseDaysData[termDay.day].available = isAvailable;
                        });
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded[termDay.day],
                  })}
                  onClick={handleExpandClick(termDay.day)}
                  aria-expanded={expanded[termDay.day]}
                  aria-label="detail"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              {/* <Collapse in={expanded[termDay.day]} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph>Lesson dates:</Typography>
                  {showLessonDates(termDay)}
                </CardContent>
              </Collapse> */}
            </Card>
          </Grid>
        );
      })}
    </FormRow>
  );
}

export default withStyles(styles)(CourseDayDetail);
