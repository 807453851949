import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { fetch } from "./rest";

const httpLink = createHttpLink({
  // uri: "http://localhost:8080/graphql",
  credentials: "same-origin",
  // need to switch to production url
  uri: "https://api.viviantabletennis.com/graphql",
  // uri: "http://localhost:8080/graphql",
  fetch,
});

const authLink = setContext(async () => {
  try {
    const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
    const token = currentUser
      ?.getSignInUserSession()
      ?.getIdToken()
      ?.getJwtToken();
    return {
      headers: {
        authorization: token ?? "",
      },
    };
  } catch {
    Auth.signOut();
  }
});

export const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: any, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export const client = new ApolloClient({
  ssrMode: true,
  link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
    },
  },
});
