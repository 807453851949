import { withStyles } from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import ERoutePath from "../routes/ERoutePath";

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {}

const SignOut = (props: Props) => {
  const { showErrorNotification } = useContext(NotificationContext);
  const { signout } = useContext(AuthContext);
  const history = useHistory();
  const doSignOut = async () => {
    const result = await signout();
    if (result.success) {
      history.replace(ERoutePath.home);
    } else {
      showErrorNotification(
        result.error ?? "unable to signout, please try again later."
      );
    }
  };
  useEffect(() => {
    doSignOut();
  });
  return null;
};

export default withStyles(styles)(SignOut);
