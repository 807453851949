import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { getHolidayCourses } from "../api/holidayCourse.service";
import { LoadingContext } from "../context/LoadingContext";
import EmptyInfo from "../EmptyInfo";
import {
  AddHolidayCourseResp,
  HolidayCourse,
  UpdateHolidayCourseResp,
} from "../generated/graphql";
import DialogTitle from "./DialogTitle";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";
import FormTitle from "./FormTitle";
import HolidayCourseDetail from "./HolidayCourseDetail";
import HolidayCourseList from "./HolidayCourseList";

const styles = (theme: Theme) =>
  createStyles({
    paperWidthSm: {
      maxWidth: "80em",
    },
  });

export interface Props extends WithStyles<typeof styles> {}

function HolidayCourses(props: Props) {
  const { classes } = props;
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [addNewCourse, setAddNewCourse] = useState(false);
  const [courses, setCourses] = useImmer<HolidayCourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<HolidayCourse | null>(
    null
  );

  const handlleNewCourse = (newCourse: AddHolidayCourseResp) => {
    setCourses((draft) => {
      draft.push(...newCourse.courses);
    });
  };

  const handleCourseUpdated = (updatedCourse: UpdateHolidayCourseResp) => {
    setCourses((draft) => {
      updatedCourse.courses?.forEach((c) => {
        const idx = draft.findIndex((dc) => dc.id === c.id);
        if (idx >= 0) {
          draft[idx] = c;
        }
      });
    });
  };

  const handleSetCourseUpdated = (
    updatedCourse: HolidayCourse | undefined | null
  ) => {
    setCourses((draft) => {
      const idx = draft.findIndex((dc) => (dc.id = updatedCourse?.id));
      if (idx >= 0 && updatedCourse) {
        draft[idx] = updatedCourse;
      }
    });
  };

  const handleCourseDeleted = (courseId: string) => {
    setCourses((draft) => {
      const foundIdx = draft.findIndex((c) => c.id === courseId);
      if (foundIdx !== -1) {
        draft.splice(foundIdx, 1);
      }
    });
  };

  const handleClose = () => {
    setAddNewCourse(false);
    setSelectedCourse(null);
  };
  const handleAddNewHolidayCourse = () => {
    setAddNewCourse(true);
  };

  const retrieveHolidayCourses = async () => {
    showLoading();

    try {
      const courses = (await getHolidayCourses())?.courses;
      setCourses((draft) => {
        draft.length = 0;
        if (courses) {
          draft.push(...courses);
        }
      });
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    retrieveHolidayCourses();
  }, []);

  const handleSelectedCourse = (course: HolidayCourse) => {
    setSelectedCourse(course);
  };

  const getDialogTitle = () => (
    <Typography>
      {selectedCourse ? "Update course" : "Add a new Course"}
    </Typography>
  );

  return (
    <FormContainer>
      <FormTitle title="Holiday course" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <FormRow>
          <Grid item xs={12}>
            {courses.length > 0 && (
              <HolidayCourseList
                courses={courses}
                onSelectedCourse={handleSelectedCourse}
              />
            )}
            {courses.length === 0 && (
              <EmptyInfo title="Please add a new holiday course" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleAddNewHolidayCourse}
            >
              Add a new holiday course
            </Button>
          </Grid>
        </FormRow>
      </Grid>
      <Dialog
        fullWidth
        open={addNewCourse || selectedCourse !== null}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle onClose={handleClose}>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          <HolidayCourseDetail
            onClose={handleClose}
            onNewCourse={handlleNewCourse}
            onCourseUpdated={handleCourseUpdated}
            onCourseStatusUpdated={handleSetCourseUpdated}
            onCourseDeleted={handleCourseDeleted}
            course={selectedCourse}
          />
        </DialogContent>
      </Dialog>
    </FormContainer>
  );
}

export default withStyles(styles)(HolidayCourses);
