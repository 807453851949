import {
  Dashboard as DashboardIcon,
  Info as MakeupRulesIcon,
  Payment as PaymentIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  SportsTennis,
} from "@material-ui/icons";
import React, { ComponentType, Fragment } from "react";
import ActiveKIdsVouchers from "../components/ActiveKIdsVouchers";
import BlogActivitiesPage from "../components/BlogActivitiesPage";
import BlogBeginnersPage from "../components/BlogBeginnersPage";
import BlogPage from "../components/BlogPage";
import BlogTechniquesPage from "../components/BlogTechniquesPage";
import BlogThingsPage from "../components/BlogThingsPage";
import BlogWhyPage from "../components/BlogWhyPage";
import ChangePassword from "../components/ChangePassword";
import ClassesTimeTable from "../components/ClassesTimeTable";
import ContactUs from "../components/ContactUs";
import Course from "../components/Course";
import Enrolment from "../components/Enrolment";
import ForgotPassword from "../components/ForgotPassword";
import HolidayCampPage from "../components/HolidayCampPage";
import HolidayCampsTimeTable from "../components/HolidayCampsTimeTable";
import HolidayCourseEnrolment from "../components/HolidayCourseEnrolment";
import HolidayCourses from "../components/HolidayCourses";
import HomePage from "../components/HomePage";
import MakeupRules from "../components/MakeupRules";
import Payments from "../components/Payments";
import Players from "../components/Players";
import Profile from "../components/Profile";
import ResetPassword from "../components/ResetPassword";
import SchoolEnrolment from "../components/SchoolEnrolment";
import SignIn from "../components/SignIn";
import SignOut from "../components/SignOut";
import SignUp from "../components/SignUp";
import TournamentEnrolment from "../components/TournamentEnrolment";
import Tournaments from "../components/Tournaments";
import { ERole } from "../generated/graphql";
import HomePageLayout from "../layouts/HomePageLayout";
import LayoutComponent from "../layouts/SideMenuLayout";
import ERoutePath from "./ERoutePath";
import ERouteTitle from "./ERouteTitle";
import PaymentForm from "../components/PaymentForm";

export interface IRoute {
  path: string;
  title: string;
  component: ComponentType<any>;
  needRemount?: boolean;
  layout: React.ComponentType<any>;
  icon?: any;
  onNavMenu?: boolean;
  onUserMenu?: boolean;
  access: ERole[];
}

export const routes: IRoute[] = [
  {
    path: ERoutePath.home,
    title: ERouteTitle.home,
    component: HomePage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.timeTable,
    title: ERouteTitle.timeTable,
    component: ClassesTimeTable,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.holidayCampsTimeTable,
    title: ERouteTitle.holidayCampsTimeTable,
    component: HolidayCampsTimeTable,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.contactUs,
    title: ERouteTitle.contactUs,
    component: ContactUs,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.holidayCamp,
    title: ERouteTitle.holidayCamp,
    component: HolidayCampPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blog,
    title: ERouteTitle.blog,
    component: BlogPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blogActivities,
    title: ERouteTitle.blogActivities,
    component: BlogActivitiesPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blogForBeginners,
    title: ERouteTitle.blogForBeginners,
    component: BlogBeginnersPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blogTechniques,
    title: ERouteTitle.blogTechniques,
    component: BlogTechniquesPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blogThings,
    title: ERouteTitle.blogThings,
    component: BlogThingsPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.blogWhy,
    title: ERouteTitle.blogWhy,
    component: BlogWhyPage,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.home2,
    title: ERouteTitle.home,
    component: Fragment,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.signup,
    title: ERouteTitle.signup,
    component: SignUp,
    needRemount: true,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.signin,
    title: ERouteTitle.signin,
    component: SignIn,
    needRemount: true,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.forgotPassword,
    title: ERouteTitle.forgotPassword,
    component: ForgotPassword,
    needRemount: true,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.profile,
    title: ERouteTitle.profile,
    component: Profile,
    icon: <PersonIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin, ERole.Coach, ERole.Parent],
  },
  {
    path: ERoutePath.players,
    title: ERouteTitle.players,
    component: Players,
    icon: <PeopleIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin, ERole.Parent],
  },
  {
    path: ERoutePath.courses,
    title: ERouteTitle.courses,
    component: Course,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin],
  },
  {
    path: ERoutePath.activeKidsVoucheres,
    title: ERouteTitle.activeKidsVoucheres,
    component: ActiveKIdsVouchers,
    icon: <SportsTennis />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent, ERole.Admin],
  },
  {
    path: ERoutePath.holidayCourses,
    title: ERouteTitle.holidayCourses,
    component: HolidayCourses,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin],
  },
  {
    path: ERoutePath.holidayCoursesEnrolment,
    title: ERouteTitle.holidayCoursesEnrolment,
    component: HolidayCourseEnrolment,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent],
  },
  {
    path: ERoutePath.tournaments,
    title: ERouteTitle.tournaments,
    component: Tournaments,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin],
  },
  {
    path: ERoutePath.tournamentEnrolment,
    title: ERouteTitle.tournamentEnrolment,
    component: TournamentEnrolment,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent, ERole.Admin],
  },
  {
    path: ERoutePath.schoolEnrolment,
    title: ERouteTitle.schoolEnrolment,
    component: SchoolEnrolment,
    icon: <DashboardIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent],
  },
  {
    path: ERoutePath.enrolments,
    title: ERouteTitle.enrolments,
    component: Enrolment,
    icon: <SettingsIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent, ERole.Admin],
  },
  {
    path: ERoutePath.makeupRules,
    title: ERouteTitle.makeupRules,
    component: MakeupRules,
    icon: <MakeupRulesIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Parent],
  },

  {
    path: ERoutePath.payments,
    title: ERouteTitle.payments,
    component: Payments,
    icon: <PaymentIcon />,
    layout: LayoutComponent,
    onNavMenu: true,
    access: [ERole.Admin],
  },
  {
    path: ERoutePath.changePassword,
    title: ERouteTitle.changePassword,
    component: ChangePassword,
    icon: <SettingsIcon />,
    needRemount: true,
    layout: LayoutComponent,
    onUserMenu: true,
    access: [ERole.Admin, ERole.Parent, ERole.Coach],
  },
  {
    path: ERoutePath.resetPassword,
    title: ERouteTitle.resetPassword,
    component: ResetPassword,
    needRemount: true,
    layout: HomePageLayout,
    access: [ERole.Public],
  },
  {
    path: ERoutePath.signout,
    title: ERouteTitle.signout,
    component: SignOut,
    icon: <SettingsIcon />,
    layout: LayoutComponent,
    onUserMenu: true,
    access: [ERole.Admin, ERole.Parent, ERole.Coach],
  },
];
