import { Grid, Paper } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import ERoutePath from "../routes/ERoutePath";
import { firstPage } from "../htmls/firstPage";

const styles = (theme: Theme) => {
  return createStyles({
    main: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    clsImage: {
      maxWidth: "300px",
    },
    container: {
      marginLeft: "50px",
      marginRight: "50px",
    },
  });
};
interface Props extends WithStyles<typeof styles> {}

const HomePage = ({ classes }: Props) => {
  // const bannerTitle1 = "Vivian Table Tennis Academy";
  // const { isAuthenticated, userProfile } = useContext(AuthContext);
  // const history = useHistory();
  // if (isAuthenticated) {
  //   if (!userProfile) {
  //     history.push(ERoutePath.profile);
  //   } else {
  //     history.push(ERoutePath.enrolments);
  //   }
  // }

  const htmlDoc = { __html: firstPage };

  return <Paper dangerouslySetInnerHTML={htmlDoc}></Paper>;
};

export default withStyles(styles)(HomePage);
