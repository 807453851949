import Auth from "@aws-amplify/auth";

const oauth = {
  // Authorized scopes
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin",
  ],

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: "token",

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: false,
  },
};

export const AuthConfig = Auth.configure({
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_S9cPCCLZu",
    userPoolWebClientId: "5k55ttonmi7hfnrt5airlv1mmu",
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    oauth,
  },
});
