import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import * as yup from "yup";
import { DEFAULT_EMAIL, DEFAULT_PASSWORD } from "../const";
import { AuthContext } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import DialogTitle from "./DialogTitle";
import FormRow from "./FormRow";
import PasswordTextInput from "./PasswordTextInput";

const validationsForm = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
});

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 420,
      marginTop: 50,
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
    },
    dlgAction: {
      justifyContent: "center",
    },
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface IChangePasswordValue {
  email: string;
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const ChangePassword = (props: Props) => {
  const history = useHistory();
  const { showErrorNotification, showSuccessNotification } = useContext(
    NotificationContext
  );
  const initialValues: IChangePasswordValue = {
    email: DEFAULT_EMAIL,
    oldPassword: DEFAULT_PASSWORD,
    newPassword: DEFAULT_PASSWORD,
    repeatNewPassword: DEFAULT_PASSWORD,
  };
  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema: validationsForm,
    onSubmit: async ({ oldPassword, newPassword }) => {
      const result = await changePassword({
        oldPassword,
        newPassword,
      });
      if (result) {
        showSuccessNotification("Password updated");
        history.goBack();
      } else {
        showErrorNotification(`Can't sigin, please try again later`);
      }
    },
  });
  const [open, setOpen] = React.useState(true);
  const { isAuthenticated, changePassword, user } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const isPasswordCorrect = () => {
    return (
      values.repeatNewPassword?.length >= 8 &&
      values.repeatNewPassword === values.newPassword &&
      values.oldPassword.length >= 8
    );
  };
  const changePasswordMessage = ``;
  if (!isAuthenticated) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: 400,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={handleClose}>
          <Typography> Change Password</Typography>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: 300 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography align="left">{changePasswordMessage}</Typography>
            </Grid>
            <FormRow>
              <Grid item>
                <PasswordTextInput
                  label="Old password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.oldPassword ? errors.oldPassword : ""}
                  error={touched.oldPassword && Boolean(errors.oldPassword)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <PasswordTextInput
                  label="New password"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.newPassword ? errors.newPassword : ""}
                  error={touched.newPassword && Boolean(errors.newPassword)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <PasswordTextInput
                  label="Confirm new password"
                  name="repeatNewPassword"
                  value={values.repeatNewPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.repeatNewPassword ? errors.repeatNewPassword : ""
                  }
                  error={
                    touched.repeatNewPassword &&
                    Boolean(errors.repeatNewPassword)
                  }
                  fullWidth
                />
              </Grid>
            </FormRow>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                // disabled={!touched.repeatNewPassword || !!!errors.newPassword}
                disabled={!isPasswordCorrect()}
              >
                Change password
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(ChangePassword);
