import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { addEnrolment } from "../api/enrolment.service";
import { AuthContext } from "../context/AuthContext";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import {
  Course,
  CoursesResp,
  EEnrolmentType,
  EnrolmentResult,
  HolidayCourse,
  Player,
  Term,
} from "../generated/graphql";
import FormContainer from "./FormContainer";
import PaymentForm from "./PaymentForm";
import { applyPaymentAmount } from "../utils/paymentAmount";

const styles = (theme: Theme) =>
  createStyles({
    container: {},
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    card: {
      border: "1px solid green",
      margin: theme.spacing(2),
    },
  });

export interface IEnrolmentData {
  courses: CoursesResp[];
  filteredCourses: CoursesResp[];
  holidayCourses?: HolidayCourse[];
  players: Player[];
  enrolments: EnrolmentResult[];
  selectedPlayers: Player[];
  selectedCourses?: { course: Course; term: Term }[];
  selectedDays?: { [name: string]: { [name: string]: boolean } };
}

export interface Props extends WithStyles<typeof styles> {
  enrolmentData: IEnrolmentData;
  onClose: () => void;
  onEnrolmentAdded: (enrolment: EnrolmentResult) => void;
}

function EnrolmentDetail(props: Props) {
  const { classes, onClose } = props;
  const enrolmentData = props.enrolmentData;
  const onEnrolmentAdded = props.onEnrolmentAdded;
  const { userProfile } = useContext(AuthContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);
  const [enroled, setEnroled] = useState(false);
  const handleSubmit2 = async () => {
    try {
      const { selectedCourses, selectedPlayers, selectedDays } = enrolmentData;
      const courseIds = selectedCourses?.map((c) => c.course.id);
      const playerIds = selectedPlayers.map((p) => p.id);
      if (courseIds && playerIds) {
        showLoading();
        const paymentAmount = `${getTotalPrice(enrolmentData)}`;
        const addEnrolmentData = {
          type: EEnrolmentType.Term,
          userId: userProfile?.id,
          courseIds,
          playerIds,
          paymentAmount,
        };
        const r = await addEnrolment(addEnrolmentData);
        console.log("xxxxx r", r);
        if (onEnrolmentAdded && r) {
          onEnrolmentAdded(r);
        }
        setEnroled(true);
        showSuccessNotification("Enroled");
        return r;
      }
    } catch (e) {
      showErrorNotification("Please retry later");
    } finally {
      hideLoading();
      onClose();
    }
  };

  // const { values, handleSubmit, handleChange, errors, touched } = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {},
  //   // validationSchema: validationsForm,
  //   onSubmit: async (values) => {
  //     try {
  //       const { selectedCourses, selectedPlayers, selectedDays } =
  //         enrolmentData;
  //       const courseIds = selectedCourses?.map((c) => c.course.id);
  //       const playerIds = selectedPlayers.map((p) => p.id);
  //       if (courseIds && playerIds) {
  //         showLoading();
  //         const paymentAmount = `${getTotalPrice(enrolmentData)}`;
  //         const addEnrolmentData = {
  //           type: EEnrolmentType.Term,
  //           userId: userProfile?.id,
  //           courseIds,
  //           playerIds,
  //           paymentAmount,
  //         };
  //         const r = await addEnrolment(addEnrolmentData);
  //         if (onEnrolmentAdded && r) {
  //           onEnrolmentAdded(r);
  //         }
  //         setEnroled(true);
  //         showSuccessNotification("Enroled");
  //       }
  //     } catch (e) {
  //       showErrorNotification("Please retry later");
  //     } finally {
  //       hideLoading();
  //       onClose();
  //     }
  //   },
  // });

  const convertDaysObjToDaysList = (daysObj: { [name: string]: boolean }) => {
    const result = [];
    for (const [day, selected] of Object.entries(daysObj)) {
      if (selected) {
        result.push(day);
      }
    }
    return result;
  };

  const selectedDaysList = (selectedDays: {
    [name: string]: boolean;
  }): string => {
    const result = convertDaysObjToDaysList(selectedDays);
    return result.join(", ");
  };

  const getPlayersName = (players: Player[]) => {
    return players.map((x) => `${x.firstName} ${x.lastName}`).join(", ");
  };

  const getTotalPrice = ({
    selectedDays,
    selectedCourses,
    selectedPlayers,
  }: IEnrolmentData): number => {
    return (
      selectedCourses?.reduce((allPrice, currentCourse) => {
        return (
          allPrice +
          currentCourse.course.unitPrice *
            selectedPlayers.length *
            (currentCourse.course.numOfLessons -
              (currentCourse.course.numOfPublicHolidays ?? 0))
        );
      }, 0) ?? 0
    );
  };

  const selectedCourse = enrolmentData.selectedCourses?.[0];
  const isCourseSelected = !!selectedCourse;
  const isPlayersSelected = enrolmentData.selectedPlayers.length > 0;
  // const isDaysSelected = enrolmentData.selectedDays[selectedCourse?.term.id]; // days are selected
  const canBeEnroled = isCourseSelected && isPlayersSelected;
  const amount = getTotalPrice(enrolmentData);
  const totalAmount = applyPaymentAmount(amount);

  return (
    <FormContainer>
      {!isCourseSelected && (
        <Typography variant="h3"> Please select course </Typography>
      )}
      {!isPlayersSelected && (
        <Typography variant={"h4"}>
          No player selected, please select player first
        </Typography>
      )}
      {/* {!isDaysSelected && <Typography> Please choose lesson day</Typography>} */}
      {
        // <form onSubmit={handleSubmit2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {enrolmentData?.selectedPlayers?.length > 0 && (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant={"h3"}>
                    The following player(s) are selected
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {getPlayersName(enrolmentData.selectedPlayers)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {enrolmentData.selectedCourses?.length === 0 && (
              <Typography variant={"h3"}>
                No course selected, please select one course first
              </Typography>
            )}
            {enrolmentData.selectedCourses &&
              enrolmentData.selectedCourses.length > 0 && (
                <Typography variant={"h4"}>
                  The following course(s) are selected
                </Typography>
              )}
            {enrolmentData.selectedCourses?.map((course) => (
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h5">
                    Level: {`${course?.term?.level}`}
                  </Typography>
                  <Typography variant={"h5"}>
                    Price: ${`${course?.course.unitPrice}`}
                  </Typography>
                  <Typography variant={"h5"}>
                    Start time: {course?.course.startTime} to{" "}
                    {course?.course.endTime}
                  </Typography>
                  <Typography variant={"h5"}>
                    Week day: {`${course?.course.courseDay}`}
                  </Typography>
                  <Typography variant={"h5"}>
                    Term period:
                    {`${course?.term.startDate} to ${course?.term.endDate}`}
                  </Typography>
                  <Typography variant={"h5"}>
                    Total lessons:{" "}
                    {course.course.numOfLessons -
                      course.course.numOfPublicHolidays}
                  </Typography>
                </CardContent>
              </Card>
            ))}

            {(enrolmentData.selectedCourses?.length ?? 0) > 0 && (
              <Typography variant={"h3"}>
                Your spot is secured at the time of paymet. Please bring exact
                amount and pay in person ASAP since the places are limited.
              </Typography>
            )}
            {canBeEnroled && (
              <PaymentForm
                amount={amount}
                totalAmount={totalAmount}
                submitForm={async () => {
                  return await handleSubmit2();
                }}
                onResult={({ succeed, paymentId }) => {}}
              ></PaymentForm>
            )}
            {/* <Typography variant={"h4"}>
                Account Name: Zhenhua Dederko
              </Typography>
              <Typography variant={"h4"}>BSB: 062 140</Typography>
              <Typography variant={"h4"}>Account No. 1161 4669</Typography> */}
          </Grid>
          {/* <Grid item xs={12}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!canBeEnroled}
                fullWidth
              >
                Enrol
              </Button>
            </Grid> */}
        </Grid>
        // </form>
      }
    </FormContainer>
  );
}

export default withStyles(styles)(EnrolmentDetail);
