import Auth from "@aws-amplify/auth";
import { Button, Grid, TextField } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { useFormik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import {
  addActiveKidsVoucher,
  changeVoucherStatus,
  deleteActiveKidsVouchers,
  updateActiveKidsVoucher,
} from "../api/activeKidsVouchers.service";
import { AuthContext } from "../context/AuthContext";

import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import {
  AddActiveKidsVouchersInput,
  ERole,
  EVoucherStatus,
  UpdateActiveKidsVouchersInput,
  VouchersResp,
} from "../generated/graphql";
import FormContainer from "./FormContainer";
import FormRow from "./FormRow";

const styles = (theme: Theme) => createStyles({});

export interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  onNewVoucher: (newVoucher: VouchersResp) => void;
  onVoucherUpdated: (voucherUpdated: VouchersResp) => void;
  onVoucherDeleted: (voucherId: string) => void;
  voucher: VouchersResp | null;
}

function ActiveKidsVoucherDetail({
  classes,
  onClose,
  onNewVoucher,
  voucher,
  onVoucherUpdated,
  onVoucherDeleted,
}: Props) {
  const isNewVoucher = !!!voucher;

  const initialValues = {
    firstName: voucher?.firstName ?? "",
    middleName: voucher?.middleName ?? "",
    lastName: voucher?.lastName ?? "",
    voucherNumber: voucher?.voucherNumber ?? "",
    expiryDate: voucher?.expiryDate ?? "",
    dob: voucher?.dob ?? "",
    status: voucher?.status ?? EVoucherStatus.Submitted,
  };
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { showErrorNotification, showSuccessNotification } =
    useContext(NotificationContext);
  const { userProfile } = useContext(AuthContext);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Enter first name"),
    lastName: yup.string().required("Enter first name"),
    dob: yup.date().required("Enter Date of birth"),
    expiryDate: yup.date().required("Enter voucher expiry date"),
    voucherNumber: yup
      .string()
      .required("Enter voucher number")
      .test({
        name: "Voucher number",
        message: "Invalid voucher number",
        test: (value: string | undefined): boolean =>
          /^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/g.test(value ?? ""),
      }),
  });
  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (v) => {
        const {
          firstName,
          middleName,
          lastName,
          voucherNumber,
          expiryDate,
          dob,
        } = v;

        try {
          showLoading();
          if (isNewVoucher) {
            const addVoucherParam: AddActiveKidsVouchersInput = {
              firstName,
              middleName,
              lastName,
              voucherNumber,
              expiryDate,
              dob,
            };
            const newVoucher = await addActiveKidsVoucher(addVoucherParam);
            showSuccessNotification("Voucher added");
            if (newVoucher) {
              onNewVoucher(newVoucher);
            }
          } else {
            const voucherToBeUpdated: UpdateActiveKidsVouchersInput = {
              voucherId: voucher?.id ?? "",
              firstName,
              middleName,
              lastName,
              voucherNumber,
              expiryDate,
              dob,
            };
            const updatedVoucher = await updateActiveKidsVoucher(
              voucherToBeUpdated
            );

            showSuccessNotification("Voucher updated");
            if (updatedVoucher) {
              onVoucherUpdated(updatedVoucher);
            }
          }
          onClose();
        } catch (e) {
          showErrorNotification("please try again later");
        } finally {
          hideLoading();
        }
      },
    });

  const handleDeleteVoucher = async () => {
    await deleteActiveKidsVouchers({
      voucherIds: [voucher?.id ?? ""],
    });
    onVoucherDeleted(voucher?.id ?? "");
    onClose();
  };

  const handleUpdateStatus = (status: EVoucherStatus) => async () => {
    const updatedVoucher = await changeVoucherStatus({
      voucherId: voucher?.id ?? "",
      status,
    });
    if (updatedVoucher) {
      onVoucherUpdated(updatedVoucher);
    }
    onClose();
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          alignItems={"flex-start"}
        >
          <FormRow>
            <Grid item xs={12} sm={4}>
              <TextField
                id="firstName"
                name="firstName"
                label="First name"
                type="text"
                value={values.firstName}
                fullWidth
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="middleName"
                name="middleName"
                label="Middle name"
                type="text"
                value={values.middleName}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="lastName"
                name="lastName"
                label="Last name"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="dob"
                name="dob"
                label="Date of birth"
                type="date"
                value={values.dob}
                fullWidth
                onChange={handleChange}
                error={touched.dob && Boolean(errors.dob)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="status"
                name="status"
                label="Status"
                type="string"
                value={values.status}
                fullWidth
                disabled
                onChange={handleChange}
                error={touched.status && Boolean(errors.status)}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item xs={12} sm={6}>
              <TextField
                id="voucherNumber"
                name="voucherNumber"
                label="Voucher number"
                type="text"
                fullWidth
                value={values.voucherNumber}
                onChange={handleChange}
                error={touched.voucherNumber && Boolean(errors.voucherNumber)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="expiryDate"
                name="expiryDate"
                label="Expiry date"
                type="date"
                value={values.expiryDate}
                fullWidth
                onChange={handleChange}
                error={touched.expiryDate && Boolean(errors.expiryDate)}
                variant="outlined"
              />
            </Grid>
          </FormRow>
          <FormRow>
            {(!voucher ||
              (voucher && voucher.status !== EVoucherStatus.Claimed)) && (
              <Grid item xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  {isNewVoucher ? "Save a new voucher" : "Update voucher"}
                </Button>
              </Grid>
            )}
            {voucher && voucher.status !== EVoucherStatus.Claimed && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleDeleteVoucher}
                  fullWidth
                >
                  {"Delete voucher"}
                </Button>
              </Grid>
            )}
            {voucher && userProfile?.role === ERole.Admin && (
              <Grid item xs={12}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleUpdateStatus(
                    voucher?.status === EVoucherStatus.Claimed
                      ? EVoucherStatus.Submitted
                      : EVoucherStatus.Claimed
                  )}
                  fullWidth
                >
                  {voucher?.status === EVoucherStatus.Claimed
                    ? "Change voucher status to Submitted"
                    : "Change voucher status to Claimed"}
                </Button>
              </Grid>
            )}
          </FormRow>
        </Grid>
      </form>
    </FormContainer>
  );
}

export default withStyles(styles)(ActiveKidsVoucherDetail);
