import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React from "react";
import { EGender } from "../generated/graphql";
const genders = [
  { label: "Male", value: EGender.Male },
  { label: "Female", value: EGender.Female },
];

const GenderSelect = (props: TextFieldProps) => (
  <TextField
    id={props.id ?? ""}
    label={props.label ?? "Email"}
    name={props.name ?? "email"}
    select
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    helperText={props.helperText}
    error={props.error}
    margin={props.margin ?? "dense"}
    variant={props.variant ?? "outlined"}
    fullWidth
  >
    {genders.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);
export default GenderSelect;
