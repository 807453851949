export enum ERouteTitle {
  home = "Vivian table tennis academy",
  signup = "Sign Up",
  signin = "Sign In",
  enrolments = "Willoughby Enrolments",
  payments = "Payments",
  changePassword = "Change password",
  forgotPassword = "Forgot password",
  resetPassword = "Reset password",
  signout = "Sign out",
  profile = "Profile",
  players = "Players",
  courses = "Courses",
  activeKidsVoucheres = "Active kids vouchers",
  holidayCourses = "Holiday Courses",
  holidayCoursesEnrolment = "Holiday Enrolments",
  schoolEnrolment = "School Enrolments",
  tournaments = "Tournaments",
  tournamentEnrolment = "Tournament enrolment",
  makeupRules = "Makeup Rules",
  timeTable = "Classes time table",
  holidayCampsTimeTable = "Holiday Camps time table",
  contactUs = "Contact us",
  holidayCamp = "Sydney Holiday Camps | Vivian Table Tennis Academy",
  blog = "Blog | Vivian Table Tennis Academy Sydney",
  blogTechniques = "Backhand Techniques in Table Tennis | Vivian Table Tennis Academy",
  blogForBeginners = "5 Tips to Improve your Bat Skills in Table Tennis",
  blogWhy = "Reasons to Send Your Child to Holiday Programs Sydney",
  blogActivities = "Extracurricular Activities in Schools | Activities for Fun| Vivian TT",
  blogThings = "Things to do on the Holidays | Activities for the Holidays | Vivian TT",
}
export default ERouteTitle;
