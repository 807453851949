import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { declineEnrolment } from "../api/enrolment.service";
import { updatePaymentStatus } from "../api/payment.service";
import { LoadingContext } from "../context/LoadingContext";
import { NotificationContext } from "../context/NotificationContext";
import { EPaymentStatus, PaymentInfoResp, User } from "../generated/graphql";
import getPlayerName from "../utils/getPlayerName";
import getUserFullName from "../utils/getUserFullName";
import DialogTitle from "./DialogTitle";
import FormRow from "./FormRow";
const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "75vh",
      overflowY: "auto",
      width: "95%",
    },
    paperWidthSm: {
      maxWidth: "80em",
    },
  });

interface Props extends WithStyles<typeof styles> {
  payments: PaymentInfoResp[];
  onUpdate: () => Promise<void>;
}

const getRowsColumns = (payments: PaymentInfoResp[]) => {
  const rows = payments.map(
    ({
      id,
      status,
      reference,
      enrolmentId,
      paidDate,
      payerInfo,
      enrolmentInfo,
      coursesInfo,
      playersInfo,
      amount,
    }: PaymentInfoResp) => {
      return {
        id,
        status,
        reference,
        payer: getUserFullName(payerInfo),
        paidDate: status === EPaymentStatus.Paid ? paidDate ?? "" : "",
        players: playersInfo.map(getPlayerName).join(","),
        courses: coursesInfo.map((c) => c.courseDay).join(","),
        amount: `$${amount}`,
      };
    }
  );
  const columns = [
    { field: "status", headerName: "Status", width: 120 },
    { field: "payer", headerName: "Payer", width: 120 },
    { field: "amount", headerName: "Amount", width: 120 },
    { field: "paidDate", headerName: "Paid date", width: 120 },
    { field: "players", headerName: "Player", width: 240 },
    { field: "courses", headerName: "Courses", width: 120 },
    // { field: "reference", headerName: "Reference", width: 120 },
  ];
  return {
    rows,
    columns,
  };
};

const PaymentList = ({ classes, payments, onUpdate }: Props) => {
  const { showSuccessNotification, showErrorNotification } =
    useContext(NotificationContext);

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { rows, columns } = getRowsColumns(payments);

  const [showDetailDlg, setShowDetailDlg] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState<
    PaymentInfoResp | undefined
  >(undefined);
  const handleRowClick = ({ row: { id: paymentId, status } }: any) => {
    // if (status !== EPaymentStatus.Paid) {
    setSelectedPaymentInfo(payments.find((p) => p.id === paymentId));
    setPaymentId(paymentId);
    setShowDetailDlg(true);
    // }
  };

  const handleClose = () => {
    setShowDetailDlg(false);
  };
  const { values, handleSubmit, handleChange, errors, touched, handleBlur } =
    useFormik({
      initialValues: {},
      onSubmit: async ({}) => {
        showLoading();
        showSuccessNotification("Payment updated");
        await updatePaymentStatus({
          paymentId: selectedPaymentInfo?.id,
          status: EPaymentStatus.Paid,
        });
        hideLoading();
        setShowDetailDlg(false);
        onUpdate();
      },
    });

  const handleDeclineEnrolment = async () => {
    if (!selectedPaymentInfo?.enrolmentId) {
      return;
    }
    showLoading();
    await declineEnrolment({
      id: selectedPaymentInfo?.enrolmentId,
    });
    onUpdate();
    hideLoading();
    setShowDetailDlg(false);
  };

  const handleConfirmed = async () => {
    showLoading();
    showSuccessNotification("Payment updated");
    await updatePaymentStatus({
      paymentId: selectedPaymentInfo?.id,
      status: EPaymentStatus.Confirmed,
    });
    hideLoading();
    setShowDetailDlg(false);
    onUpdate();
  };

  const canBeDeclined = () => {
    return (
      selectedPaymentInfo?.status !== EPaymentStatus.Declined &&
      selectedPaymentInfo?.status !== EPaymentStatus.Paid
    );
  };

  const canBePaid = () => {
    return selectedPaymentInfo?.status !== EPaymentStatus.Paid;
  };

  const canBeConfirmed = () => {
    return selectedPaymentInfo?.status !== EPaymentStatus.Confirmed;
  };

  return (
    <Paper className={classes.container}>
      {payments.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={80}
          autoHeight
          onRowClick={handleRowClick}
        />
      )}
      <Dialog
        open={showDetailDlg}
        onClose={handleClose}
        fullWidth
        aria-labelledby="max-width-dialog-title"
        classes={{ paperWidthSm: classes.paperWidthSm }}
      >
        <DialogTitle onClose={handleClose}>
          <Typography variant="h6">Payment detail</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <FormRow>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Payer name:
                    {getUserFullName(
                      selectedPaymentInfo?.payerInfo ?? ({} as User)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Players :
                    {selectedPaymentInfo?.playersInfo
                      .map(getPlayerName)
                      .join(", ")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h4"}> Lessons detail: </Typography>
                  {selectedPaymentInfo?.coursesInfo.map((courseInfo) => {
                    const foundTerm = selectedPaymentInfo.termsInfo.find(
                      (t) => t.id === courseInfo.termId
                    );
                    return (
                      <Typography>
                        {courseInfo.courseDay} {foundTerm?.level}{" "}
                        {courseInfo.numOfLessons} lessons
                      </Typography>
                    );
                  })}
                  {/* {selectedPaymentInfo?.coursesInfo
                      .map((c) => c.courseDay)
                      .join(", ")} */}
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item>
                  <Typography variant="h4">
                    Payment amount: ${selectedPaymentInfo?.amount}
                  </Typography>
                </Grid>
              </Grid>
            </FormRow>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!canBePaid()}
                >
                  Mark as paid
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleConfirmed}
                  disabled={!canBeConfirmed()}
                >
                  Mark as confirmed
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleDeclineEnrolment}
                  disabled={!canBeDeclined()}
                >
                  Decline
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(PaymentList);
