import { Button, Hidden, IconButton } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { IUser } from "../model/IUser";
import ERoutePath from "../routes/ERoutePath";
import Navigator from "./Navigator";
import UserMenu from "./UserMenu";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      background: theme.palette.common.white,
    },
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

interface HeaderProps extends WithStyles<typeof styles> {}

function Header(props: HeaderProps) {
  const { classes } = props;
  const { isAuthenticated, user } = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setCurrentUser(user);
  }, []);

  const onClick = (path: string) => () => {
    if (currentUser && path === ERoutePath.home) {
      // if user clicks home button, renavigate to .com.au site
      window.open("https://viviantabletennis.com", "_self");
    } else {
      history.push(path);
    }
  };
  const handleSignUp = onClick(ERoutePath.signup);
  const handleSignIn = onClick(ERoutePath.signin);
  const handleGoHome = onClick(ERoutePath.home);

  const handleDrawer = (open: boolean) => () => setDrawerOpen(open);
  const handleDrawerOpen = handleDrawer(true);
  const handleDrawerClose = handleDrawer(false);

  return (
    <React.Fragment>
      <AppBar
        color="default"
        position="sticky"
        elevation={0}
        className={classes.container}
      >
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden mdUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: 256 } }}
              variant="temporary"
              open={drawerOpen}
              onClose={handleDrawerClose}
            />
          </Hidden>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <img
                src={"/images/logo192.png"}
                style={{ height: "42px", cursor: "pointer" }}
                onClick={handleGoHome}
              />
            </Grid>
            <Grid item xs />
            {!isAuthenticated && (
              <Fragment>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSignIn}
                  >
                    Sign in
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Fragment>
            )}
            {isAuthenticated && (
              <Grid item>
                <UserMenu />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);
