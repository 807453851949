import {
  AddCourseDocument,
  AddCourseInput,
  AddCourseMutation,
  DeleteCourseDocument,
  DeleteCourseInput,
  DeleteCourseMutation,
  DisableTermDocument,
  DisableTermInput,
  DisableTermMutation,
  GetCoursesDocument,
  GetCoursesQuery,
  UpdateCourseDocument,
  UpdateCourseInput,
  UpdateCourseMutation,
  useDisableTermMutation,
} from "../generated/graphql";
import { client } from "./client";

export const addCourse = async ({
  termDescription,
  termStartDate,
  termEndDate,
  courseLevel,
  courseData,
}: AddCourseInput) =>
  (
    await client.mutate<AddCourseMutation>({
      mutation: AddCourseDocument,
      variables: {
        param: {
          termDescription,
          termStartDate,
          termEndDate,
          courseLevel,
          courseData,
        },
      },
    })
  ).data?.addCourse;

export const updateCourse = async ({
  termId,
  termDescription,
  termStartDate,
  termEndDate,
  courseData,
  courseLevel,
}: UpdateCourseInput) =>
  (
    await client.mutate<UpdateCourseMutation>({
      mutation: UpdateCourseDocument,
      variables: {
        param: {
          termId,
          termDescription,
          termStartDate,
          termEndDate,
          courseLevel,
          courseData,
        },
      },
    })
  ).data?.updateCourse;

export const getCourses = async () =>
  (
    await client.query<GetCoursesQuery>({
      query: GetCoursesDocument,
    })
  ).data?.getCourses;

export const deleteCourse = async ({
  termId,
}: DeleteCourseInput): Promise<boolean> => {
  const result =
    (
      await client.mutate<DeleteCourseMutation>({
        mutation: DeleteCourseDocument,
        variables: {
          param: {
            termId,
          },
        },
      })
    ).data?.deleteCourse ?? false;
  return result;
};

export const disableTerm = async ({
  termId,
}: DisableTermInput): Promise<boolean> => {
  const result =
    (
      await client.mutate<DisableTermMutation>({
        mutation: DisableTermDocument,
        variables: {
          param: {
            termId,
          },
        },
      })
    ).data?.disableTerm ?? false;
  return result;
};
