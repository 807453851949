import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ERole } from "../generated/graphql";
import { IRoute, routes } from "../routes";
import ERoutePath from "../routes/ERoutePath";
import LargeLoading from "./LargeLoading";

const Routes = () => {
  const { isAuthenticated, isLoading, userProfile } = useContext(AuthContext);
  return (
    <Switch>
      {routes.map((route: IRoute) => {
        const isProtectedRoute = route.access.indexOf(ERole.Public) === -1;
        let isAllowed = true;
        if (isProtectedRoute) {
          if (!!!userProfile && route.path === ERoutePath.profile) {
            isAllowed = true;
          } else {
            isAllowed = route.access.indexOf(userProfile?.role as ERole) >= 0;
          }
        }
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            render={(props) => {
              if (isProtectedRoute) {
                return isLoading ? (
                  <LargeLoading />
                ) : !isAuthenticated || !isAllowed ? (
                  <Redirect to={`${ERoutePath.home}`} />
                ) : (
                  <route.layout>
                    {route.needRemount ? (
                      <route.component {...props} key={new Date()} />
                    ) : (
                      <route.component {...props} />
                    )}
                  </route.layout>
                );
              }
              return (
                <route.layout>
                  {route.needRemount ? (
                    <route.component {...props} key={new Date()} />
                  ) : (
                    <route.component {...props} />
                  )}
                </route.layout>
              );
            }}
          />
        );
      })}
    </Switch>
  );
};
export default Routes;
