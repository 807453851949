import { Grid, List, ListItem, Typography } from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import FormContainer from "./FormContainer";
import FormTitle from "./FormTitle";
const styles = (theme: Theme) =>
  createStyles({
    container: {},
    paperWidthSm: {
      maxWidth: "80em",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  });

export interface Props extends WithStyles<typeof styles> {}

const steps = ["Please select player", "Please select a course", "Enrol now"];

function MakeupRules(props: Props) {
  const { classes } = props;

  return (
    <FormContainer>
      <FormTitle title="Makeup rules" />
      <Grid container direction="column" spacing={2} alignItems="center">
        <List>
          <ListItem>
            <Typography variant="h4">
              A student may receive one (1) make-up lesson per eligible absence,
              up to a maximum of two (2) make-up lessons per term. To be
              eligible for a make-up lesson, a student is required to:
            </Typography>
          </ListItem>

          <ListItem>
            Notify the Vivian of the absence at least 4 hours prior to the
            scheduled lesson start time by SMS to 0430 127 108.
          </ListItem>
          <ListItem>
            Request a make-up lesson in the same term as the noted absence. i.e
            make-up lessons will not be rolled over to the following term
            Make-up lessons Requests by SMS to 0430 127 108:
          </ListItem>
          <ListItem>
            Are subject to availability. Lack of availability is not a valid
            reason for partial or full refund. • May be requested up to 7 days
            in advance and at any stage during the current term.
          </ListItem>
          <ListItem>
            Requests will be actioned within the Monday to Sunday period and you
            will receive confirmation message once the make-lesson is booked.
            Requests will be rejected if the eligibility conditions are not met.
            Once a make-up lesson is booked and confirmed it cannot be
            rearranged. Failure to attend will result in forfeiting the make-up
            lesson. Please note: Notification of absence forfeits your position
            in the specified class. Please contact Vivian if you want to attend
            your original class after notifying the absence, as another student
            may be booked into your original time-slot for a make-up lesson.
          </ListItem>
        </List>
      </Grid>
    </FormContainer>
  );
}

export default withStyles(styles)(MakeupRules);
